import { environment } from 'src/environments/environment';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Conversation} from '../../shared/models/conversation';
import {ConversationItem} from '../../shared/models/conversationItem';
import {ConversationService} from './conversation.service';
import {ConversationFlowRepositoryService} from '../../shared/services/conversation-flow-repository.service';
import {AvatarMoodService} from './avatar-mood.service';
import {SpeechService} from './speech.service';
import {RINDEX_VALUES} from '../../shared/values/values';
import {CacheConversationItemService} from '../../shared/services/cache-conversation-item.service';
import { md5 } from './../../../assets/md5Script';

@Injectable({providedIn: 'root'})
export class ChatService {
    // Observable that contains the current conversation
    private conversation = new BehaviorSubject<Conversation>(null);
    // Observable that contains the current avatar url
    private avatar = new BehaviorSubject<string>(null);

    // Observable that contains the current available questions to choose
    private questions = new BehaviorSubject<ConversationItem[]>([]);
    // Observable that contains the current selected question
    private currentQuestion = new BehaviorSubject<ConversationItem>(null);
    // Observable that contains the current answer
    private answer = new BehaviorSubject<ConversationItem>(null);
    // Observable that contains the rIndex evolution
    private rIndex = new BehaviorSubject<number[]>([0]);

    audio = new Audio();
    audioMuted: boolean = false;
    gender: string;
    conversationId: string;

    constructor(
        private conversationFlowRepository: ConversationFlowRepositoryService,
        private conversationService: ConversationService,
        private avatarMoodService: AvatarMoodService,
        private speechService: SpeechService,
        private cacheConversationItemService: CacheConversationItemService
    ) {
    }

    /**
     * Set the current conversation
     */
    setConversation(conversation: Conversation): void {
        this.cancelConversation();
        this.conversation.next(conversation);
        this.cacheConversationItemService.checkCacheOfConversation(conversation);

        // set the avatar when a new conversation starts
        if (conversation) {
            this.getInitialConversationFlow(conversation.id, conversation.gender);
        }
    }

    /**
     * Search for the initial conversation flow
     */
    private async getInitialConversationFlow(conversationId: string, gender: string): Promise<void> {
        this.questions.next([]);
        this.currentQuestion.next(null);
        this.answer.next(null);
        const conversationItem = await this.conversationFlowRepository.getInitialConversationFlow(conversationId);
        this.setInitial(conversationItem, gender, conversationId);
    }

    /**
     * Cancel a conversation
     */
    cancelConversation(): void {
        this.conversation.next(null);
        this.avatar.next(null);
        this.questions.next([]);
        this.currentQuestion.next(null);
        this.answer.next(null);
        this.conversationService.clear();
        this.avatarMoodService.reset();
        this.audio.pause();
        // this.speechService.cancel();
        this.rIndex.next([0]);
    }

    /**
     * Set the avatar image
     */
    private setAvatar(avatar: string): void {
        this.avatar.next(avatar);
    }

    /**
     * Subscriber for conversation changes
     */
    onNewConversation(): Observable<Conversation> {
        return this.conversation.asObservable();
    }

    /**
     * Subscriber for avatar changes
     */
    onChangedAvatar(): Observable<string> {
        return this.avatar.asObservable();
    }

    /**
     * CONVERSATION
     */

    /**
     * Get current available questions
     */
    getQuestions(): Observable<ConversationItem[]> {
        return this.questions.asObservable();
    }

    /**
     * Set the question choosed by the user
     */
    sendQuestion(question: ConversationItem, gender: string, conversationId: string): void {
        // Clean the current available questions and the answer
        this.questions.next([]);
        this.answer.next(null);

        // Look for the ConversationItem for the selected question
        this.setCurrentQuestionAndAnswer(question, gender, conversationId);
    }

    /**
     * Get the current answer
     */
    getAnswer(): Observable<ConversationItem> {
        return this.answer.asObservable();
    }

    /**
     * Get the current question
     */
    getCurrentQuestion(): Observable<ConversationItem> {
        return this.currentQuestion.asObservable();
    }

    /**
     * Set the answer for the selected question and the next questions
     */
    private async setCurrentQuestionAndAnswer(questionConversationItem: ConversationItem, gender: string, conversationId: string): Promise<void> {
        // Save the current selected question
        this.conversationService.add(questionConversationItem);
        this.currentQuestion.next(questionConversationItem);
        this.setRIndex(questionConversationItem);

        if (questionConversationItem.nexts.length) {
            // Look for the current answer ConversationItem, save it and set the next questions
            const answerConversationItem = await this.conversationFlowRepository
                .getConversationFlowById(this.conversation.getValue().id, questionConversationItem.nexts[0].id);
            this.conversationService.add(answerConversationItem);
            this.setAnswer(answerConversationItem, gender, conversationId);

            this.setAvatar(this.avatarMoodService.getAvatarByAnswer(this.conversation.getValue(), answerConversationItem));

            if (answerConversationItem.nexts.length) {
                this.setQuestionsForAnswer(answerConversationItem.nexts);
            } else {
                this.setQuestionsForAnswer([this.getFinalQuestion()]);
            }
        } else {
            // If there isn't any 'next' item
            this.setQuestionsForAnswer([this.getFinalQuestion()]);
        }
    }

    private getFinalQuestion(): ConversationItem {
        const finalQuestion = new ConversationItem();
        finalQuestion.text = 'Sie haben das Ende des Trainings erreicht. Klicken Sie hier, um das Ergebnis zu sehen';
        finalQuestion.phase = 'final';

        return finalQuestion;
    }


    private setAnswer(answer: ConversationItem, gender: string, conversationId: string): void {
        this.answer.next(answer);
        this.gender = gender;
        this.conversationId= conversationId;
        this.audio.pause();
        if (answer.text && !this.audioMuted) {
            var url = environment.rootUrl+'audios/'+gender+'/'+conversationId+'/'+md5(answer.text)+'.mp3';
            this.audio.src = url;
            this.audio.load();
            this.audio.play().then(() => {
            }).catch(error => {
                console.log(error);
            });

            // this.speechService.speak(answer.text);
        }
    }

    public audioMutedToggle(muted: boolean): void {
        this.audioMuted = muted;
        if(muted === true){
            this.audio.pause();
        }else{
           this.setAnswer(this.answer.getValue(), this.gender, this.conversationId);
        }
    }

    private setInitial(initialItem: ConversationItem, gender: string, conversationId: string): void {
        this.conversationService.add(initialItem);
        this.setAvatar(this.avatarMoodService.getAvatarByAnswer(this.conversation.getValue(), initialItem));
        this.setAnswer(initialItem, gender, conversationId);
        this.setQuestionsForAnswer(initialItem.nexts);
    }

    /**
     * Set the next questions to choose
     */
    private setQuestionsForAnswer(questions: ConversationItem[]): void {
        this.questions.next(this.shuffleQuestions(questions));
    }

    private shuffleQuestions(questions: ConversationItem[]): ConversationItem[] {
        const shuffledQuestions = questions.slice();
        for (let i = shuffledQuestions.length - 1; i > 0; i--) {
            const rand = Math.floor(Math.random() * (i + 1));
            [shuffledQuestions[i], shuffledQuestions[rand]] = [shuffledQuestions[rand], shuffledQuestions[i]];
        }
        return shuffledQuestions;
    }

    private setRIndex(question: ConversationItem): void {
        const currentRIndex = this.rIndex.getValue();
        if (typeof question.rating !== 'undefined' && (question.rating in RINDEX_VALUES)) {
            currentRIndex.push(currentRIndex[currentRIndex.length - 1] + RINDEX_VALUES[question.rating]);
        } else {
            currentRIndex.push(currentRIndex[currentRIndex.length - 1]);
        }
        this.rIndex.next(currentRIndex);
    }

    getRIndex(): Observable<number[]> {
        return this.rIndex.asObservable();
    }

    /**
     * Save the current conversation flow to data store
     */
    async saveConversation(): Promise<void> {
        await this.conversationService.save(this.conversation.getValue().id);
    }
}
