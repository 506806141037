import { DateTransformService } from './../../../shared/services/dateTransform.service';
import { User } from './../../../shared/models/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService } from './../../../shared/services/loading.service';
import { AuthService } from './../../../core/services/auth.service';
import { ConfigService } from './../../../shared/services/config.service';
import { LANGS, DEFAULT_LANG } from './../../../shared/values';
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { RegistrationRepository } from 'src/app/shared/services/registration-repository.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-expired-session-panel",
  templateUrl: "./expired-session-panel.component.html",
  styleUrls: ["./expired-session-panel.component.scss"],
})
export class ExpiredSessionPanelComponent implements OnInit {
  codeDeleted: boolean = false;
  codeExpirated: boolean = false;
  expirationDate: string = '';
  codeForm: FormGroup;
  user: any;
  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private registrationRepository: RegistrationRepository,
    private router: Router
    ) {
      translate.addLangs(LANGS);
      translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.checkUserExpirationDate();
    this.codeForm = this.createFormGroup();
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group (
      {
        code: ['', [Validators.required]] ,
      }
    );
  }

  async CheckCodeAndSet(){
    const errorMsg = await this.translate.get('ERROR.invalidCodeFormat').toPromise();
    if(this.codeForm.invalid) {
      this.toastr.error(errorMsg);
    }else{
      const infoMsg = await this.translate.get('INFO.codeValid').toPromise();
      const codeValid = await this.configService.checkSignupCode(this.codeForm.value.code);
      if(codeValid){
        let codeId = '';
        this.configService.onChangedCode().subscribe(response => codeId = response);
        this.registrationRepository.setUserToUniqueCode(codeId, this.user.uid);
        this.toastr.info(infoMsg);
        this.router.navigate(['/auth/login']);
      }else{
        // this.toastr.error(errorMsg);
        let error = await this.translate.get('ERROR.invalidCodeFormat').toPromise();
        let specificError = false;
        this.configService.onErrorCode().subscribe(response => {
          if(response === 'code in use'){
            error = 'ERROR.codeInUse';
           }else if(response !== 'code in use' && response !== undefined && response !== null){
             specificError = true;
             const date: Date = DateTransformService.timeStampLongFormatToDate(response);
             const dateTransformed = date.getDate() +'.'+ (date.getMonth()+1) +'.'+ date.getFullYear();
             error = 'Ihrer Registrierungscode wurde am '+dateTransformed+' abgelaufen. Bitte wenden Sie sich an <a href=\'mailto:mi-train@tu-braunschweig.de\'>mi-train@tu-braunschweig.de</a> um einen gültigen Code zu erhalten';
           }
         }).unsubscribe();
        this.configService.restoreErrorCode(null);
        if(specificError){
          this.toastr.error(error);
        }else{
          let errorMsg = await this.translate.get(error).toPromise();
          this.toastr.error(errorMsg);
        }
        return;
      }
    }
  }

  async checkUserExpirationDate(): Promise<void>{
    this.loadingService.showLoading();
    this.user = await this.authService.getCurrentUser();
    this.expirationDate = await this.configService.checkExpirationDateSignupCode(this.user.uid);
    if(this.expirationDate === ''){
      this.codeDeleted = true;
    }else{
      this.codeExpirated = true;
    }
    this.loadingService.hideLoading();
  }
}
