import { Injectable } from '@angular/core';
import {from, Observable} from 'rxjs';

const CACHE_PREFIX = 'cache_';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  save(key: string, data: any, expirationMin?: number): void {
    const cacheData = new CacheData();
    cacheData.key = key;
    cacheData.data = this.encode(data);
    cacheData.cacheDate = new Date().getTime();
    cacheData.expirationDate = expirationMin ? new Date().getTime() + (expirationMin * 60 * 1000) : null;
    localStorage.setItem(CACHE_PREFIX + key, JSON.stringify(cacheData));
  }

  load(key: string, asObservable: boolean = false): any | Observable<any> {
    const item = localStorage.getItem(CACHE_PREFIX + key);
    if (item) {
      try {
        const cacheData = JSON.parse(item) as CacheData;
        const now = new Date().getTime();
        if (!cacheData || (cacheData.expirationDate && cacheData.expirationDate <= now)) {
          this.delete(key);
          return this.returnData(null, asObservable);
        } else {
          return this.returnData(this.decode(cacheData.data), asObservable);
        }
      } catch (e) {
        this.delete(key);
        return this.returnData(null, asObservable);
      }
    }
    return this.returnData(null, asObservable);
  }

  checkCacheDate(key: string, time: number): void {
    const item = localStorage.getItem(CACHE_PREFIX + key);
    if (item) {
      try {
        const cacheData = JSON.parse(item) as CacheData;
        if (cacheData && cacheData.cacheDate < time) {
          this.delete(key);
        }
      } catch (e) {
        this.delete(key);
      }
    }
  }

  delete(key: string): void {
    localStorage.removeItem(CACHE_PREFIX + key);
  }

  returnData(data: any, asObservable: boolean): any | Observable<any> {
    return asObservable ? from(data) : data;
  }

  private encode(data: any): string {
    return window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  }

  decode(data: string ): any {
    return JSON.parse(decodeURIComponent(escape(window.atob(data))));
  }

}

class CacheData {
  key: string;
  data: any;
  cacheDate: number;
  expirationDate: number;
}
