import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import { CodeToStore } from '../../shared/models/codeToStore';
import { CodeRepositoryService } from '../../shared/services/code-repository.service';

@Injectable({
    providedIn: 'root'
})
export class CreateEditCodeService {

    constructor(
        private firestore: AngularFirestore,
        private storage: AngularFireStorage,
        private codeRepository: CodeRepositoryService,
    ) { }

    async createCode(codeToStore: CodeToStore): Promise<void> {

        this.codeRepository.createCode(codeToStore);

    }

    async editCode(codeToStore: CodeToStore, codeId: string): Promise<void> {

        this.codeRepository.editCode(codeToStore, codeId);

    }

    async deleteCode(codeId: string) : Promise<void> {

        this.codeRepository.deleteCode(codeId);
        
    }

}