import {Injectable} from '@angular/core';
import {ConversationItem} from '../../shared/models/conversationItem';

export const RATING_QUESTION_GOOD = ['B', 'A', 'RE+', 'RE-', 'oE+', 'oE-'];
export const RATING_QUESTION_BAD = ['r', 't'];
export const RATING_QUESTION_NEUTRAL = ['H', 'I', 'T', 'c'];
export const RATING_QUESTION_GOOD_VALUE = 'good';
export const RATING_QUESTION_BAD_VALUE = 'bad';
export const RATING_QUESTION_NEUTRAL_VALUE = 'neutral';

export const RATING_QUESTION_B_TEXT = 'Wertschätzen';
export const RATING_QUESTION_A_TEXT = 'Autonomie des Gesprächspartners betonen';
export const RATING_QUESTION_REPLUS_TEXT = 'Reflexionen - Change Talk evozierend';
export const RATING_QUESTION_REMINUS_TEXT = 'Reflexionen - Sustain Talk evozierend';
export const RATING_QUESTION_oEPLUS_TEXT = 'Offene Fragen - Change Talk evozierend';
export const RATING_QUESTION_oEMINUS_TEXT = 'Offene Fragen - Sustain Talk evozierend';
export const RATING_QUESTION_r_TEXT = 'Ratschlag ohne Erlaubnis';
export const RATING_QUESTION_t_TEXT = 'Konfrontation, Anordnen, Besorgnis ansprechen oder Warnen';
export const RATING_QUESTION_H_TEXT = 'Neutral Gesprächsfördernde Äußerung';
export const RATING_QUESTION_I_TEXT = 'Informationen vermitteln';
export const RATING_QUESTION_T_TEXT = 'Strukturierung';
export const RATING_QUESTION_c_TEXT = 'Geschlossene Frage';

@Injectable({providedIn: 'root'})
export class QuestionRatingService {

    /**
     * Given a ConversationItem it returns the rating value and the rating text for it.
     */
    getRatingElectionForQuestion(question: ConversationItem): string[] {
        if (question) {
            return [
                this.getRatingValueForQuestion(question.methods),
                this.getRatingTextForQuestion(question.methods)
            ];
        } else {
            return ['', ''];
        }
    }

    private getRatingValueForQuestion(methods: string[]): string {
        if (methods.some(t => RATING_QUESTION_GOOD.includes(t))) {
            return RATING_QUESTION_GOOD_VALUE;
        } else if (methods.some(t => RATING_QUESTION_BAD.includes(t))) {
            return RATING_QUESTION_BAD_VALUE;
        } else if (methods.some(t => RATING_QUESTION_NEUTRAL.includes(t))) {
            return RATING_QUESTION_NEUTRAL_VALUE;
        } else {
            return '';
        }
    }

    private getRatingTextForQuestion(methods: string[]): string {
        const textMethods = [];
        if (methods.includes('B')) {
            textMethods.push(RATING_QUESTION_B_TEXT);
        }
        if (methods.includes('A')) {
            textMethods.push(RATING_QUESTION_A_TEXT);
        }
        if (methods.includes('RE+')) {
            textMethods.push(RATING_QUESTION_REPLUS_TEXT);
        }
        if (methods.includes('RE-')) {
            textMethods.push(RATING_QUESTION_REMINUS_TEXT);
        }
        if (methods.includes('oE+')) {
            textMethods.push(RATING_QUESTION_oEPLUS_TEXT);
        }
        if (methods.includes('oE-')) {
            textMethods.push(RATING_QUESTION_oEMINUS_TEXT);
        }
        if (methods.includes('r')) {
            textMethods.push(RATING_QUESTION_r_TEXT);
        }
        if (methods.includes('t')) {
            textMethods.push(RATING_QUESTION_t_TEXT);
        }
        if (methods.includes('H')) {
            textMethods.push(RATING_QUESTION_H_TEXT);
        }
        if (methods.includes('I')) {
            textMethods.push(RATING_QUESTION_I_TEXT);
        }
        if (methods.includes('T')) {
            textMethods.push(RATING_QUESTION_T_TEXT);
        }
        if (methods.includes('c')) {
            textMethods.push(RATING_QUESTION_c_TEXT);
        }

        return textMethods.join(' & ');
    }
}
