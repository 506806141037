import { ConversationItemResultsAdapter } from './../../../shared/adapter/conversationItemResultsAdapter';
import { ConversationItem } from './../../../shared/models/conversationItem';
import { Component, OnInit } from '@angular/core';
import {ResultService} from '../../services/result.service';
import {ReportService} from '../../services/report/report.service';
import {ActivatedRoute} from '@angular/router';
import {faFileExport, faPoll} from '@fortawesome/free-solid-svg-icons';
import {ExportService} from '../../services/report/export.service';
import {ToastrService} from 'ngx-toastr';
import {ConversationRepositoryService} from '../../../shared/services/conversation-repository.service';
import {Conversation} from '../../../shared/models/conversation';
import { keyframes } from '@angular/animations';

const COMMENT_CLASS_1 = 'darkGreen';
const COMMENT_CLASS_2 = 'lightGreen';
const COMMENT_CLASS_3 = 'yellow';
const COMMENT_CLASS_4 = 'orange';
const COMMENT_CLASS_5 = 'red';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit {
  exportIcon = faFileExport;
  pollIcon = faPoll;
  selectedTab: number;
  isExport: boolean = false;
  conversation: Conversation;
  historicalItems = new Map <string, string>();
  historicalConfiguration = new Map<string, ConversationItem[]>();
  historicalButtonsConfiguration = new Map<string, string>();
  firstResult: string = '';

  constructor(
      private resultService: ResultService,
      private reportService: ReportService,
      private route: ActivatedRoute,
      private exportService: ExportService,
      private toastrService: ToastrService,
      private conversationRepository: ConversationRepositoryService,
      private conversationItemResultsAdapter: ConversationItemResultsAdapter
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.getResults(params.get('id'));
      this.getHistoricals(params.get('id'));
    });
  }

  async getResults(conversationId: string) {
    this.conversationRepository.getConversationById(conversationId).subscribe((conversation) => {
      this.conversation = conversation;
    });
    const results = await this.resultService.getResultForCurrentUserAndConversationId(conversationId);
    this.reportService.setResults(results);
  }

  async loadResultPage(conversationDate: string) {
    for (var [key, value] of this.historicalConfiguration){
      if (key === conversationDate){
        this.reportService.setResults(value);
      }
    }
  }

  /* Get historical data by user id */

  async getHistoricals(conversationId: string){
    const historicalResults = await this.resultService.getHistoricalResultForCurrentUserAndConversationId(conversationId);
    const historicalkeys = Object.keys(historicalResults).sort((a,b) =>parseInt(b)-parseInt(a));
    this.firstResult = historicalkeys[0];
    for(var i = 0; i <= historicalkeys.length - 1; i++ ){
      if(i < 5 ){
        this.historicalConfiguration.set(historicalkeys[i],this.getHistoricalResults(historicalResults, historicalkeys[i]));
      }else{
        this.resultService.deleteLastHistoricalResult(conversationId, historicalkeys[i]);
      }
    }
    this.historicalItems = this.historicalOverallPreprocessedData(this.historicalConfiguration);
  }

  getHistoricalResults(historicalResults: Array<any>, key: string): ConversationItem[]{
    const result: ConversationItem[] = [];
    historicalResults[key].conversationItems.forEach(element => {
      result.push(this.conversationItemResultsAdapter.adapt(element));
    });
    return result;
  }

  historicalOverallPreprocessedData(historicalData: Map<string, ConversationItem[]>): Map<string, string>{
    const result = new Map<string, string>();
    for (var [key, value] of historicalData) {
      this.reportService.setHistoricalResults(value);
      const totalScoreCalculated = this.getTotalScore();
      result.set(key, totalScoreCalculated);
    }
    const resultMapSorted = new Map([...result.entries()].reverse());
    return resultMapSorted;
  }

  getTotalScore(): string {
    const miKonsistentData = this.reportService.getHistoricalTagStats(['oE+', 'oE-', 'RE+', 'RE-', 'A', 'B']);
    const miInkonsistentData = this.reportService.getHistoricalTagStats(['r', 't']);
    const kommunikationsmethodePctg = Math.floor(miKonsistentData.percentage * 100 / (miInkonsistentData.percentage + miKonsistentData.percentage));
    const gesprachsphasenPctg =  this.reportService.getHistoricalPercentageOfMark();
    const motivationPctg = this.reportService.getHistoricalMotivation();
    const totalScore = Math.floor((kommunikationsmethodePctg + gesprachsphasenPctg + motivationPctg) / 3);
    if (totalScore >= 81) {
      return COMMENT_CLASS_1;
    } else if (totalScore >= 61) {
      return COMMENT_CLASS_2;
    } else if (totalScore >= 41) {
      return COMMENT_CLASS_3;
    } else if (totalScore >= 21) {
      return COMMENT_CLASS_4;
    } else {
      return COMMENT_CLASS_5;
    }
  }

  colorToggle(event){
    for (var [key, value] of this.historicalItems) {
      if(key === event){
      document.getElementById(key).style.backgroundColor = "white";
      document.getElementById(key).style.color = "#09395A";
      }else{
      document.getElementById(key).style.backgroundColor = "#09395A";
      document.getElementById(key).style.color = "white";
      }
    }
  }

  async exportPdf(): Promise<void> {
    this.isExport = true;
    this.selectedTab = 6;

    const today = new Date();
    const fileName = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate() + '-' + this.stringToSnakeCase(this.conversation.name);

    if (!await this.exportService.exportPdf(fileName)) {
      this.toastrService.error('An error has occurred while exporting to pdf. Please try again');
    }

    this.isExport = false;
    this.selectedTab = 0;
  }

  private stringToSnakeCase(text: string): string {
    return text.replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
  }

}
