import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import { ConversationTrainerToStore } from '../../shared/models/conversationTrainerToStore';
import { ConversationTrainerRepositoryService } from 'src/app/shared/services/conversationTrainer-repository.service';
import { ConversationTrainer } from '../../shared/models/conversationTrainer';

@Injectable({
    providedIn: 'root'
})
export class CreateEditConversationTrainerService {

    constructor(
        private firestore: AngularFirestore,
        private storage: AngularFireStorage,
        private conversationTrainerRepositoryService: ConversationTrainerRepositoryService,
    ) { }

    async createConversationsTrainer(conversationTrainerToStore: ConversationTrainerToStore): Promise<void> {

        this.conversationTrainerRepositoryService.createConversationsTrainer(conversationTrainerToStore);

    }

    async deleteConversationTrainer(converationTrainerId: string) : Promise<void> {

        this.conversationTrainerRepositoryService.deleteConversationTrainer(converationTrainerId);
        
    }

}