<form [formGroup]="deleteUserForm" (ngSubmit)="deleteUserSubmit()">
  <h1>{{ 'PROFILE.deleteAccount' | translate }}</h1>
  <div class="dialog-content" mat-dialog-content>
    <p>{{ "COMMON.deleteUserDialogText" | translate }}</p>
      <mat-form-field appearance="fill">
        <mat-label></mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error class="error-message">
          <span *ngIf="deleteUserForm.get('password').hasError('required')">{{ 'FORM.required' | translate }}</span>
        </mat-error>
      </mat-form-field>
  </div>
  <mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close>{{ "COMMON.cancelButton" | translate }}</button>
    <button type="submit "mat-raised-button color="warn" [disabled]="!deleteUserForm.valid">{{ "COMMON.deleteButton" | translate }}</button>
  </mat-dialog-actions>
</form>

