<footer>
    <div class="logos">
        <a href="https://kauffeld-lorenzo.de/" target="_blank"><img id="KL-logo" src="../../../../assets/logos/KL-logo.png"></a>
        <a href="https://www.tu-braunschweig.de/" target="_blank"><img id="Braunschweig-logo" src="../../../../assets/logos/TU_Braunschweig_transparent_logo.png"></a>
    </div>
    <div class="links-container">
        <a id="privacy" target="_blank" routerLink="/impressum">Impressum</a>
        <a id="dataProtection" target="_blank" routerLink="/datenschutz">Datenschutz</a>
    </div>
</footer>
