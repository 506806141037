export class ConversationItem {
    id: string;
    initial?: boolean;
    text: string;
    kind: string;
    phase: string;
    rating: string;
    methods: string[];
    mark: string;
    nexts: ConversationItem[];

    constructor() {
        this.methods = [];
        this.nexts = [];
        this.mark = '';
    }
}
