import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../values';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  titleHeader: string = '';

  constructor(
      private router: Router,
      private translate: TranslateService)
  {
    translate.addLangs(LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.handleRouteChange();

    this.router.events.subscribe( (event) => {
      if (event instanceof NavigationEnd) {
        this.handleRouteChange();
      }
    });
  }

  async handleRouteChange(): Promise<void> {
    if (this.router.url === '/user/chat') {
      const title = await this.translate.get('COMMON.contactPageTitle').toPromise();
      this.setTitle(title);
    } else {
      this.setTitle('');
    }
  }

  setTitle(title: string) {
    this.titleHeader = title;
  }

}
