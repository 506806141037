import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-box',
  templateUrl: './report-box.component.html',
  styleUrls: ['./report-box.component.scss']
})
export class ReportBoxComponent implements OnInit {

  @Input() title: string;
  @Input() comment: string;
  @Input() optionalCommentTitle: string;
  @Input() optionalCommentText: string;
  @Input() optionalCommentClass: string;

  @Input() optional2CommentTitle: string;
  @Input() optional2CommentText: string;
  @Input() optional2CommentClass: string;

  @Input() optional3CommentTitle: string;
  @Input() optional3CommentText: string;
  @Input() optional3CommentClass: string;

  @Input() optional4CommentTitle: string;
  @Input() optional4CommentText: string;
  @Input() optional4CommentClass: string;

  @Input() percentage: number = null;
  @Input() inverseColors: boolean = false;
  percentageClass: string;
  constructor() { }

  ngOnInit(): void {
    if (this.percentage !== null) {
      //this.getClassByPercent(this.percentage);
    }
  }

  getClassByPercent(percentage: number) {
    const digits: number = percentage.toString().length;
    if (digits < 2 ) {
      this.percentageClass = 'text-indent-1-digit';
    } else {
      if (digits === 2 ) {
        this.percentageClass = 'text-indent-2-digit';
      } else {
        this.percentageClass = 'text-indent-3-digit';
      }
    }
  }
}
