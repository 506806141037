import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DeleteUserDialogComponent} from '../../components/delete-user/delete-user-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../values';

@Component({
  selector: 'app-header-user-icon',
  templateUrl: './header-user-icon.component.html',
  styleUrls: ['./header-user-icon.component.scss']
})
export class HeaderUserIconComponent implements OnInit {
  openProfile = false;
  isLogged = false;

  constructor(
      private authService: AuthService,
      private ngZone: NgZone,
      private router: Router,
      private dialog: MatDialog,
      private translate: TranslateService
  ) {
    translate.addLangs(LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe(isLogged => this.isLogged = isLogged);
  }

  logout(): void{
    this.authService.logout();
    this.ngZone.run(() => {
      this.router.navigate(['/auth/login']);
    });
  }

  deleteAccount(): void {
    this.dialog.open(DeleteUserDialogComponent);
  }

}
