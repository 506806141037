<div class="admin-container">
    <app-contact-header></app-contact-header>
    <div class="admin-tabGroup">
        <div class="row" style="margin-bottom: 25px;">
            <div class="col-sm-10">
                <span class="titleText">{{ textTitleSection }}</span>
            </div>
            <div class="col-sm-2">
                <button mat-button (click)="clickMainButton()" class="main-button"> {{ textMainButton }}</button>
            </div>
        </div>
        <mat-tab-group
            [selectedIndex]="selected.value"
            (selectedIndexChange)="tabSelectionChange($event)"
            animationDuration="0ms"
            mat-stretch-tabs
            class="mat-elevation-z8"
        >
            <mat-tab class="admin-tabLabel">
                <ng-template mat-tab-label>
                    <mat-icon class="conversationIcon">chat_bubble</mat-icon>
                    Konversationenverwaltung
                </ng-template>
                <app-conversations #conversationTab></app-conversations>
            </mat-tab>
            <mat-tab class="admin-tabLabel">
                <ng-template mat-tab-label>
                    <mat-icon class="codeIcon">person</mat-icon>
                    Kontenverwaltung
                </ng-template>
                <app-code-list-table #codeTab></app-code-list-table>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
