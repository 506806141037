<div class='container'>
    <div id="miInkonsistenTab">
        <h2 class="tab-title">Kommunikationsmethoden MI-Inkonsistent</h2>

        <app-report-box
                *ngIf="showFirstSection"
                title='Ratschlag ohne Erlaubnis'
                [percentage]="percentages[0]"
                [comment]="comments[0]"
                [inverseColors]="true"></app-report-box>

        <app-report-box
                *ngIf="showSecondSection"
                title='Konfrontation, Anordnen, Warnen, Besorgnis ansprechen'
                [percentage]="percentages[1]"
                [comment]="comments[1]"
                [inverseColors]="true"></app-report-box>

        <app-total-percentage
                *ngIf="showTotalSection"
                [number]="percentages[2]"
                [inverseColors]="true"></app-total-percentage>
    </div>
</div>
