import { Component, OnInit, Input } from '@angular/core';

const CLASS_NAMES = [
  'red',
  'orange',
  'lightGreen',
  'darkGreen',
];

@Component({
  selector: 'app-percentage-mi-inkonsistentes-colour',
  templateUrl: './percentage-colour.component.html',
  styleUrls: ['./percentage-colour.component.scss']
})
export class PercentageMiInkonsistentesColourComponent implements OnInit {

  @Input() number: number;
  @Input() showSymbol: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  get classNumber(): string {
    if (this.number >= 41) {
      return this.getClassName(0);
    } else if (this.number >= 10) {
      return this.getClassName(1);
    } else if (this.number === 10) {
      return this.getClassName(2);
    } else {
      return this.getClassName(3);
    }
  }

  getClassName(level: number): string {
    return CLASS_NAMES[level];
  }
}
