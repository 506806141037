import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo, hasCustomClaim } from '@angular/fire/auth-guard';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './shared/layout/public-layout/public-layout.component';
import { SecureLayoutComponent } from './shared/layout/secure-layout/secure-layout.component';
import {AdminLayoutComponent} from './shared/layout/admin-layout/admin-layout.component';
import {AuthAdminGuard} from './core/auth-guard/auth-admin.guard';
import {AuthUserGuard} from './core/auth-guard/auth-user.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  // {
  //   path: '',
  //   component: PublicLayoutComponent,
  //   children: [
  //   ]
  // },
  {
    path: '',
    redirectTo: 'user/chat',
    pathMatch: 'full'
  },
  {
    path: 'user',
    redirectTo: 'user/chat',
    pathMatch: 'full'
  },
  {
    path: 'user',
    component: SecureLayoutComponent,
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthAdminGuard]
  },
  {
    path: 'auth',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () =>
        import('./core/core.module').then(m => m.CoreModule)
  },
  {
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: true,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
