import { LoadingService } from './../../../shared/services/loading.service';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthService } from './../../../core/services/auth.service';
import { ConversationTrainerRepositoryService } from 'src/app/shared/services/conversationTrainer-repository.service';
import { Component, OnInit } from '@angular/core';
import { ConversationRepositoryService } from '../../../shared/services/conversation-repository.service';
import { Conversation } from '../../../shared/models/conversation';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contacts-panel',
  templateUrl: './contacts-panel.component.html',
  styleUrls: ['./contacts-panel.component.scss']
})
export class ContactsPanelComponent implements OnInit {

  conversations?: Conversation[] = [];
  availableConversations: string[];

  constructor(
    private conversationRepositoryService: ConversationRepositoryService,
    private conversationTrainerRepositoryService: ConversationTrainerRepositoryService,
    private toastr: ToastrService,
    private authService: AuthService,
    private configService: ConfigService,
    private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.getConversationsByCodePermission();
  }

  async getConversationsByCodePermission(){
    this.loadingService.showLoading();
    const user = await this.authService.getCurrentUser();
    const trainerId = await this.configService.getTrainerIdSignupCode(user.uid);
    this.availableConversations = await this.conversationTrainerRepositoryService.getConversationsByTrainer(trainerId)
    this.getConversations();
  }

  getConversations(): void {
    this.conversationRepositoryService.getConversations().subscribe(
        (response) => {
          response.forEach(element =>{
            if(this.availableConversations.includes(element.id)){
              this.conversations.push(element);
            }
          });
          this.loadingService.hideLoading();
        },
        (error) => {
            this.loadingService.hideLoading();
            this.toastr.error(error);
        }
    );
}

}
