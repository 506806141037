<div class="answers">
    <app-chat-current-question
            *ngIf="currentQuestion"
            [questionRatingValue]="questionRatingValue"
            [questionText]="currentQuestion.text"></app-chat-current-question>

    <app-chat-current-question-rating
        *ngIf="questionRatingText"
        [questionRatingValue]="questionRatingValue"
        [questionRatingText]="questionRatingText"></app-chat-current-question-rating>

    <app-chat-current-answer
        *ngIf="answer"
        [answerText]="answer.text"
        [avatarImage]="conversation.image3"></app-chat-current-answer>

</div>
