import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ConversationTrainerToStore } from '../models/conversationTrainerToStore';
import { ConversationTrainer } from '../models/conversationTrainer';
import { ConversationTrainerAdapter } from '../adapter/conversationTrainerAdapter';

const COLLECTION_CONVERSATION_TRAINER = 'conversation-trainer';

@Injectable({
    providedIn: 'root'
})
export class ConversationTrainerRepositoryService {

    constructor(
        private firestore: AngularFirestore,
        private conversationTrainerAdapter: ConversationTrainerAdapter
    ) {
    }

    public getConversationsTrainer(): Observable<ConversationTrainer[]> {
        return this.firestore.collection(COLLECTION_CONVERSATION_TRAINER).snapshotChanges()
            .pipe(
                map((data: any[]) => data.map((item) => this.conversationTrainerAdapter.adapt(item)))
            )
    }

    public createConversationsTrainer(conversationTrainerToStore: ConversationTrainerToStore): void {
        this.firestore.collection(COLLECTION_CONVERSATION_TRAINER).add({
            conversationId: conversationTrainerToStore.conversationId,
            trainerId: conversationTrainerToStore.trainerId,
        });
    }

    public deleteConversationTrainer(converationTrainerId: string) {
        return this.firestore.collection(COLLECTION_CONVERSATION_TRAINER).doc(converationTrainerId).delete();
    }

    async getConversationsByTrainer(trainerId: string): Promise<string[]> {
        let conversationsTrainersRequested: string[] = [];
        const conversationTrainerIds =  await this.firestore.collection(COLLECTION_CONVERSATION_TRAINER)
        .get()
        .pipe(map(data => data.docs.map(item => item.id))).toPromise();

        for(const conversationTrainerId of conversationTrainerIds){
            await this.firestore.collection(COLLECTION_CONVERSATION_TRAINER)
            .doc(conversationTrainerId)
            .get()
            .pipe(map((data: any) => {
                if(data.data().trainerId === trainerId){
                    conversationsTrainersRequested.push(data.data().conversationId);
                }
            })).toPromise()
        }
        return conversationsTrainersRequested;
    }
}