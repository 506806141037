import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminManagementViewComponent } from './admin-management-view/admin-management-view.component';


const routes: Routes = [{ path: '', component: AdminManagementViewComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
