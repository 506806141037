import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../core/services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../../../shared/values';

const ACTION_RESET_PASSWORD = 'resetPassword';
const ACTION_RECOVER_EMAIL = 'recoverEmail';
const ACTION_VERIFY_EMAIL = 'verifyEmail';

@Component({
    selector: 'app-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit, OnDestroy {
    ngUnsubscribe: Subject<any> = new Subject<any>();
    resetPasswordForm: FormGroup;
    code: string;
    loading: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private toastrService: ToastrService,
        private translate: TranslateService) {
        translate.addLangs(LANGS);
        translate.setDefaultLang(DEFAULT_LANG);
    }

    ngOnInit(): void {
        this.resetPasswordForm = this.createFormGroup();

        this.activatedRoute.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                if (!params) {
                    this.router.navigate(['/auth/login']);
                }

                switch (params['mode']) {
                    case ACTION_RESET_PASSWORD:
                        this.authService.verifyPasswordResetCode(params['oobCode'])
                            .then(result => this.code = params['oobCode'])
                            .catch(error => {
                                this.toastrService.error('', error);
                                this.router.navigate(['/auth/login']);
                            });
                        break;
                    default:
                        this.router.navigate(['/auth/login']);
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private createFormGroup(): FormGroup {
        return this.formBuilder.group (
            {
                password: ['', [Validators.required]],
                confirmPassword: ['', [Validators.required]],
            }
        );
    }

    checkPasswords(group: FormGroup) {
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmPassword').value;

        return pass === confirmPass;
    }

    async resetPassword() {
        if (this.resetPasswordForm.invalid) {
            const errorMsg = await this.translate.get('ERROR.invalidEmail').toPromise();
            this.toastrService.error(errorMsg);
            return;
        }

        if (!this.checkPasswords(this.resetPasswordForm)) {
            const errorMsg = await this.translate.get('ERROR.notEqualPassword').toPromise();
            this.toastrService.error(errorMsg);
            return;
        }

        try {
            this.loading = true;
            await this.authService.resetPassword(this.code, this.resetPasswordForm.get('password').value);
            const infoMsg = await this.translate.get('INFO.passwordChanged').toPromise();
            this.toastrService.info(infoMsg);
            this.router.navigate(['/auth/login']);
        } catch (error) {
            const errorKey = 'ERROR.' + error.code;
            let errorMsg = await this.translate.get(errorKey).toPromise();
            if (errorKey === errorMsg) {
                errorMsg = await this.translate.get('ERROR.common').toPromise();
            }
            this.toastrService.error(errorMsg);
        } finally {
            this.loading = false;
        }
    }

}
