import { Injectable } from '@angular/core';

const COMMENT_FIRST_SECTION_DARKGREEN = 'Sie haben sehr häufig offene Fragen genutzt. Bravo! Hierdurch ist es Ihnen gelungen, möglichst viele Informationen über ihre Gesprächsperson zu sammeln und Sie haben sie dazu eingeladen, offen über ihre Gründe für und gegen die Veränderung zu sprechen.';
const COMMENT_FIRST_SECTION_LIGHTGREEN = 'Sie haben in gutem Maße offene Fragen genutzt. Durch die Nutzung offener Fragen konnten Sie Informationen über Ihre Gesprächsperson sammeln und Sie haben sie dazu eingeladen, offen über ihre Gründe für und gegen die Veränderung zu sprechen.';
const COMMENT_FIRST_SECTION_YELLOW = 'Sie haben gelegentlich offene Fragen verwendet. Offene Fragen geben Ihrer Gesprächsperson die Freiheit, auf viele Arten zu antworten, wodurch sie sich selbst intensiver mit der Veränderung auseinandersetzt als bei der Beantwortung geschlossener (d.h. Ja/Nein) Fragen.';
const COMMENT_FIRST_SECTION_ORANGE = 'Sie haben selten offene Fragen genutzt. Versuchen Sie noch häufiger offene Fragen anstelle von geschlossenen Fragen zu nutzen. Mit Hilfe von offenen Fragen geben Sie Ihrer Gesprächsperson die Möglichkeit über die persönlichen Gründe für und gegen eine Veränderung zu reflektieren und zu formulieren. Durch die Nutzung offener Fragen geben Sie Ihrer Gesprächsperson die Freiheit, auf viele Arten zu antworten, wodurch sie sich selbst intensiver mit der Veränderung auseinandersetzt als bei der Beantwortung geschlossener (d.h. Ja/Nein) Fragen.';
const COMMENT_FIRST_SECTION_RED = 'Vergessen Sie nicht offene Fragen zu nutzen. Mit Hilfe von offenen Fragen geben Sie Ihrer Gesprächsperson die Möglichkeit über die persönlichen Gründe für und gegen eine Veränderung zu reflektieren und zu formulieren. Durch die Nutzung offener Fragen geben Sie Ihrer Gesprächsperson die Freiheit, auf viele Arten zu antworten, wodurch sie sich selbst intensiver mit der Veränderung auseinandersetzt als bei der Beantwortung geschlossener (d.h. Ja/Nein) Fragen.';
const COMMENT_FIRST_SECTION_OPTIONAL_OEMINUS_GREATER = 'Durch die offenen Fragen, die Sie im Gespräch genutzt haben, haben Sie die Gesprächsperson dazu eingeladen mehr über die Gründe gegen die Veränderung zu sprechen (Sustain Talk) und weniger über die Gründe, die aus Sicht der Person für die Veränderung sprechen (Change Talk). Versuchen Sie Ihre offenen Fragen so zu formulieren, dass sie überwiegend Change Talk evozieren';
const COMMENT_FIRST_SECTION_OPTIONAL_OEPLUS_GREATER = 'Klasse! Durch die offenen Fragen, die Sie im Gespräch genutzt haben, haben Sie die Gesprächsperson dazu eingeladen mehr über die Gründe für die Veränderung zu sprechen (Change Talk) und weniger über die Gründe, die für die Person gegen die Veränderung sprechen (Sustain Talk).';
const COMMENT_FIRST_SECTION_OPTIONAL_OEMINUS_GREATER_COLOR = 'orange';
const COMMENT_FIRST_SECTION_OPTIONAL_OEPLUS_GREATER_COLOR = 'lightGreen';

const COMMENT_SECOND_SECTION_DARKGREEN = 'Sie haben sehr häufig Reflexionen (aktives Zuhören) genutzt. Diese häufige Verwendung von aktivem Zuhören stellt eine Kernkompetenz in MI dar. Gratulation! Durch aktives Zuhören haben Sie Ihrer Gesprächsperson gezeigt, dass Sie sehr aufmerksam zuhören und empathisch sind. Gleichzeitig haben Sie Ihrer Gesprächsperson ihr Gesagtes noch einmal aufgezeigt und hierdurch persönliche Reflexionsprozesse in Ihrer Gesprächsperson angestoßen.';
const COMMENT_SECOND_SECTION_LIGHTGREEN = 'Sie haben häufig Reflexionen (aktives Zuhören) genutzt, gute Arbeit! Durch aktives Zuhören haben Sie Ihrer Gesprächsperson gezeigt, dass Sie aufmerksam zuhören und empathisch sind. Gleichzeitig haben Sie Ihrer Gesprächsperson ihr Gesagtes noch einmal aufgezeigt und hierdurch persönliche Reflexionsprozesse in Ihrer Gesprächsperson angestoßen.';
const COMMENT_SECOND_SECTION_YELLOW = 'Sie haben gelegentlich Reflexionen (aktives Zuhören) eingesetzt. Durch aktives Zuhören können Sie Ihrer Gesprächsperson zeigen, dass Sie aufmerksam zuhören und empathisch sind. Gleichzeitig geben Sie mit aktivem Zuhören Ihrer Gesprächsperson die Möglichkeit, ihr Gesagtes noch einmal selbst gedanklich zu reflektieren.';
const COMMENT_SECOND_SECTION_ORANGE = 'Sie haben selten Reflexionen (aktives Zuhören) eingesetzt. Versuchen Sie noch häufiger aktives Zuhören zu nutzen. Durch aktives Zuhören können Sie Ihrer Gesprächsperson zeigen, dass Sie aufmerksam zuhören und empathisch sind. Gleichzeitig geben Sie mit aktivem Zuhören Ihrer Gesprächsperson die Möglichkeit, ihr Gesagtes noch einmal selbst gedanklich zu reflektieren.';
const COMMENT_SECOND_SECTION_RED = 'Vergessen Sie nicht Reflexionen zu nutzen. Aktives Zuhören stellt eine Kernkompetenz in MI dar. Durch aktives Zuhören können Sie Ihrer Gesprächsperson zeigen, dass Sie aufmerksam zuhören und empathisch sind. Gleichzeitig geben Sie mit aktivem Zuhören Ihrer Gesprächsperson die Möglichkeit, ihr Gesagtes noch einmal selbst gedanklich zu reflektieren.';
const COMMENT_SECOND_SECTION_OPTIONAL_REMINUS_GREATER = 'Durch die Reflexionen, die Sie im Gespräch genutzt haben, haben Sie vorallem die Gründe Ihrer Gesprächperson, die gegen die Veränderung sprechen (Sustain Talk) betont und die Gründe, die aus Sicht der Person für die Veränderung sprechen (Change Talk) vernachlässigt. Versuchen Sie Ihre Reflexionen so zu formulieren, dass sie überwiegend Change Talk wiedergeben, um die positiven Aspekte der Veränderung präsenter zu machen.';
const COMMENT_SECOND_SECTION_OPTIONAL_REPLUS_GREATER = 'Klasse! Durch die Reflexionen, die Sie im Gespräch genutzt haben, haben Sie vorallem die Gründe Ihrer Gesprächperson, die für die Veränderung sprechen (Change Talk) betont und die Gründe, die aus Sicht der Person gegen die Veränderung sprechen (Sustain Talk) seltener reflektiert. Dadurch haben Sie den Fokus des Gesprächs auf die positiven Aspekte der Veränderung gelenkt.';
const COMMENT_SECOND_SECTION_OPTIONAL_REMINUS_GREATER_COLOR = 'orange';
const COMMENT_SECOND_SECTION_OPTIONAL_REPLUS_GREATER_COLOR = 'lightGreen';

const COMMENT_THIRD_SECTION_DARKGREEN = 'Gratulation! Ihnen ist es sehr gut gelungen, die Autonomie Ihrer Gesprächsperson zu wahren und dies auch Ihr gegenüber zu kommunizieren. Indem Ihre Gesprächsperson Autonomie wahrnimmt, fällt es ihr leichter intrinsische Motivation für eine Veränderung aufzubauen.';
const COMMENT_THIRD_SECTION_LIGHTGREEN = 'Ihnen ist es überwiegend gut gelungen, die Autonomie Ihrer Gesprächsperson zu wahren und dies auch Ihr gegenüber zu kommunizieren. Indem Ihre Gesprächsperson Autonomie wahrnimmt, fällt es ihr leichter intrinsische Motivation für eine Veränderung aufzubauen.';
const COMMENT_THIRD_SECTION_YELLOW = 'Sie haben gelegentlich die Autonomie Ihrer Gesprächsperson betont und dies auch ihr gegenüber kommuniziert. Autonomie als eines der psychologischen Grundbedürfnisse von Menschen ist eine wichtige Voraussetzung zur Evokation intrinsischer Motivation.';
const COMMENT_THIRD_SECTION_ORANGE = 'Sie haben selten die Autonomie Ihrer Gesprächsperson betont. Wenn möglich, versuchen Sie noch häufiger gegenüber Ihrer Gesprächsperson zu kommunizieren, dass sie eine Wahlfreiheit besitzt. Autonomie als eines der psychologischen Grundbedürfnisse von Menschen ist eine wichtige Voraussetzung zur Evokation intrinsischer Motivation.';
const COMMENT_THIRD_SECTION_RED = 'Vergessen Sie nicht die Autonomie Ihrer Gesprächsperson zu betonen, indem Sie ihr gegenüber kommunizieren, dass sie eine Wahlfreiheit besitzt. Autonomie als eines der psychologischen Grundbedürfnisse von Menschen ist eine wichtige Voraussetzung zur Evokation intrinsischer Motivation.';

const COMMENT_FOURTH_SECTION_DARKGREEN = 'Sie haben hervorragende Arbeit darin geleistet, Ihrer Gesprächsperson Wertschätzung entgegenzubringen. Durch die Nutzung von Affirmationen haben Sie Ihren Respekt und Zuversicht in die Fähigkeiten der Person, eine Veränderung umzusetzen, ausgedrückt und sie bestätigt.';
const COMMENT_FOURTH_SECTION_LIGHTGREEN = 'Ihnen ist es gelungen, häufig Affirmationen zu verwenden, gute Arbeit! Indem Sie Ihrer Gesprächsperson Ihre Wertschätzung ausgedrückt haben, haben Sie ihr Respekt und Bestätigung entgegengebracht.';
const COMMENT_FOURTH_SECTION_YELLOW = 'Sie haben gelegentlich Affirmationen verwendet. Affirmationen sind Ausdruck von Respekt und Bestätigung, mit denen Sie eine Beziehung zu Ihrer Gesprächsperson aufbauen können. Indem Sie Ihre Gesprächsperson wertschätzen, drücken Sie Ihre Zuversicht in die Fähigkeiten der Person, eine Veränderung umzusetzen, aus und unterstützen so die Gesprächsperson in ihrer Selbstwirksamkeit.';
const COMMENT_FOURTH_SECTION_ORANGE = 'Sie haben selten Affirmationen verwendet. Affirmationen sind Ausdruck von Respekt und Bestätigung, mit denen Sie eine Beziehung zu Ihrer Gesprächsperson aufbauen können. Indem Sie Ihre Gesprächsperson wertschätzen, drücken Sie Ihre Zuversicht in die Fähigkeiten der Person, eine Veränderung umzusetzen, aus und unterstützen so die Gesprächsperson in ihrer Selbstwirksamkeit.';
const COMMENT_FOURTH_SECTION_RED = 'Vergessen Sie nicht Ihrer Gesprächsperson Ihre Wertschätzung auszudrücken. Affirmationen sind Ausdruck von Respekt und Bestätigung, mit denen Sie eine Beziehung zu Ihrer Gesprächsperson aufbauen können. Indem Sie Ihre Gesprächsperson wertschätzen, drücken Sie Ihre Zuversicht in die Fähigkeiten der Person, eine Veränderung umzusetzen, aus und unterstützen so die Gesprächsperson in ihrer Selbstwirksamkeit.';


export interface CommentOptional {
  text: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportMiKonsistentTabService {

  constructor() { }

  getCommentForFirstSection(percentage: number): string {
    if (percentage >= 81) {
      return COMMENT_FIRST_SECTION_DARKGREEN;
    } else if (percentage >= 61) {
      return COMMENT_FIRST_SECTION_LIGHTGREEN;
    } else if (percentage >= 41) {
      return COMMENT_FIRST_SECTION_YELLOW;
    } else if (percentage >= 21) {
      return COMMENT_FIRST_SECTION_ORANGE;
    } else {
      return COMMENT_FIRST_SECTION_RED;
    }
  }

  getCommentOptionalForFirstSection(oEPlusTimes: number, oEMinusTimes: number): CommentOptional {
    if (oEMinusTimes >= oEPlusTimes) {
      return {
        text: COMMENT_FIRST_SECTION_OPTIONAL_OEMINUS_GREATER,
        color: COMMENT_FIRST_SECTION_OPTIONAL_OEMINUS_GREATER_COLOR
      };
    } else {
      return {
        text: COMMENT_FIRST_SECTION_OPTIONAL_OEPLUS_GREATER,
        color: COMMENT_FIRST_SECTION_OPTIONAL_OEPLUS_GREATER_COLOR
      };
    }
  }

  getCommentForSecondSection(percentage: number): string {
    if (percentage >= 81) {
      return COMMENT_SECOND_SECTION_DARKGREEN;
    } else if (percentage >= 61) {
      return COMMENT_SECOND_SECTION_LIGHTGREEN;
    } else if (percentage >= 41) {
      return COMMENT_SECOND_SECTION_YELLOW;
    } else if (percentage >= 21) {
      return COMMENT_SECOND_SECTION_ORANGE;
    } else {
      return COMMENT_SECOND_SECTION_RED;
    }
  }

  getCommentOptionalForSecondSection(REPlusTimes: number, REMinusTimes: number): CommentOptional {
    if (REMinusTimes >= REPlusTimes) {
      return {
        text: COMMENT_SECOND_SECTION_OPTIONAL_REMINUS_GREATER,
        color: COMMENT_SECOND_SECTION_OPTIONAL_REMINUS_GREATER_COLOR
      };
    } else {
      return {
        text: COMMENT_SECOND_SECTION_OPTIONAL_REPLUS_GREATER,
        color: COMMENT_SECOND_SECTION_OPTIONAL_REPLUS_GREATER_COLOR
      };
    }
  }

  getCommentForThirdSection(percentage: number): string {
    if (percentage >= 81) {
      return COMMENT_THIRD_SECTION_DARKGREEN;
    } else if (percentage >= 61) {
      return COMMENT_THIRD_SECTION_LIGHTGREEN;
    } else if (percentage >= 41) {
      return COMMENT_THIRD_SECTION_YELLOW;
    } else if (percentage >= 21) {
      return COMMENT_THIRD_SECTION_ORANGE;
    } else {
      return COMMENT_THIRD_SECTION_RED;
    }
  }

  getCommentForFourthSection(percentage: number): string {
    if (percentage >= 81) {
      return COMMENT_FOURTH_SECTION_DARKGREEN;
    } else if (percentage >= 61) {
      return COMMENT_FOURTH_SECTION_LIGHTGREEN;
    } else if (percentage >= 41) {
      return COMMENT_FOURTH_SECTION_YELLOW;
    } else if (percentage >= 21) {
      return COMMENT_FOURTH_SECTION_ORANGE;
    } else {
      return COMMENT_FOURTH_SECTION_RED;
    }
  }
}
