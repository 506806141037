<div class="login-form-container">
        <img class="logo" src="../../../../../assets/logos/mi-tool.png" alt="MI Tool"/>
        <div id="title" class="mb-3">Willkommen bei MI-Train</div>
        <form [formGroup]="loginForm" (ngSubmit)=login()>
            <div class="mb-2" id="email-container">
                <input type="email" class="login-input" id="email-id" formControlName="email" placeholder="E-Mail">
            </div>
            <div class="mb-3" id="password-container">
                <input type="password" class="login-input" id="password-id" formControlName="password" placeholder="Passwort">
            </div>
                <button [disabled]="loginForm.controls['email'].errors?.required || loginForm.controls['password'].errors?.required || loading"
                        id="submitLogin" type="submit" class="btn mb-4 login-button"><span>Anmelden</span></button>
        </form>
        <div class="auth-links">
            <a routerLink="/auth/forgot-password">Passwort vergessen?</a>
            <a routerLink="/auth/register" class="register">Neues Nutzendenkonto erstellen</a>
        </div>
        <app-logos-section></app-logos-section>
        <div class="logos mt-3">
            <a href="https://kauffeld-lorenzo.de/" target="_blank"><img id="KL-logo" src="../../../../assets/logos/KL-logo.png"></a>
            <a href="https://www.tu-braunschweig.de/" target="_blank"><img id="Braunschweig-logo" src="../../../../assets/logos/TU_Braunschweig_transparent_logo.png"></a>
        </div>
        <div class="links pt-5">
            <a id="privacy" target="_blank" routerLink="/impressum">Impressum</a>
            <a id="data-protection" target="_blank" routerLink="/datenschutz">Datenschutz</a>
        </div>
</div>
