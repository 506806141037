import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-total-percentage',
  templateUrl: './total-percentage.component.html',
  styleUrls: ['./total-percentage.component.scss']
})
export class TotalPercentageComponent implements OnInit {

  @Input() number: number;
  @Input() inverseColors: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
