import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface MessageDialogData {
  title?: string;
  message: string;
  closeText: string;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
    private dialogRef: MatDialogRef<MessageDialogComponent>
  ) { }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => this.confirm());
  }

  private close(value: boolean): void {
    this.dialogRef.close(value);
  }

  confirm(): void {
    this.close(true);
  }

  @HostListener('window:keyup.esc')
  onEsc(): void {
    this.confirm();
  }

}
