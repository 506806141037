import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Conversation} from '../../shared/models/conversation';

@Component({
    selector: 'app-edit-conversation-dialog',
    templateUrl: 'edit-conversation-dialog.html',
    styleUrls: ['./edit-conversation-dialog.scss']

})
export class EditConversationDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public conversation: Conversation,
        public dialogRef: MatDialogRef<EditConversationDialogComponent>,
    ) {
        dialogRef.disableClose = true;
    }

    onSubmitted(submitted: boolean) {
        this.dialogRef.close();
    }
}
