import { MatSelectModule } from '@angular/material/select';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { SecureLayoutComponent } from './layout/secure-layout/secure-layout.component';
import { GeneralLoaderComponent } from './loaders/general-loader/general-loader.component';
import { HeaderUserIconComponent } from './layout/header-user-icon/header-user-icon.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {UserRoutingModule} from '../user/user-routing.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxEchartsModule} from 'ngx-echarts';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DeleteUserDialogComponent} from './components/delete-user/delete-user-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MessageDialogComponent} from './components/message-dialog/message-dialog.component';
import {MessageDialogService} from './services/message-dialog.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PublicLayoutComponent,
    SecureLayoutComponent,
    GeneralLoaderComponent,
    HeaderUserIconComponent,
    DeleteUserDialogComponent,
    MessageDialogComponent
  ],
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MaterialFileInputModule,
        UserRoutingModule,
        FontAwesomeModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    exports: [
        TranslateModule,
        ReactiveFormsModule,
        FooterComponent,
        HeaderComponent,
        GeneralLoaderComponent,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatPaginatorModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MaterialFileInputModule,
        FontAwesomeModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        NgxEchartsModule,
        MatSelectModule,
    ],
    providers: [
        MessageDialogService
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
