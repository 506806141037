<div class='container'>
    <div id="miKonsistenTab">
        <h2 class="tab-title">Kommunikationsmethoden MI-Konsistent</h2>

        <app-report-box
                *ngIf="showFirstSection"
                title='Offene Fragen'
                [percentage]="percentages[0]"
                [comment]="comments[0]"
                [optionalCommentText]="optionalComments[0].text"
                [optionalCommentClass]="optionalComments[0].color"></app-report-box>

        <app-report-box
                *ngIf="showSecondSection"
                title='Reflexionen'
                [percentage]="percentages[1]"
                [comment]="comments[1]"
                [optionalCommentText]="optionalComments[1].text"
                [optionalCommentClass]="optionalComments[1].color"></app-report-box>

        <app-report-box
                *ngIf="showThirdSection"
                title='Autonomie des Gesprächspartners betonen'
                [percentage]="percentages[2]"
                [comment]="comments[2]"></app-report-box>

        <app-report-box
                *ngIf="showFourthSection"
                title='Wertschätzen'
                [percentage]="percentages[3]"
                [comment]="comments[3]"></app-report-box>

        <app-total-percentage *ngIf="showTotalSection" [number]="percentages[4]"></app-total-percentage>
    </div>
</div>
