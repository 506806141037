import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ConversationItemAdapter} from '../adapter/conversationItemAdapter';
import {ConversationItem} from '../models/conversationItem';
import {CacheConversationItemService} from './cache-conversation-item.service';

const COLLECTION_CONVERSATION_FLOW = 'conversation-flow';
const COLLECTION_CONVERSATION_FLOW_OPTIONS = 'options';
const COLLECTION_CONVERSATION_INITIAL_ID = 'R1A';

@Injectable({
    providedIn: 'root'
})
export class ConversationFlowRepositoryService {

    constructor(
        private cacheService: CacheConversationItemService,
        private firestore: AngularFirestore,
        private conversationItemAdapter: ConversationItemAdapter
    ) {
    }

    public getConversationFlow(conversationId: string): Observable<ConversationItem[]> {
        return this.firestore.collection(COLLECTION_CONVERSATION_FLOW)
            .doc(conversationId)
            .collection(COLLECTION_CONVERSATION_FLOW_OPTIONS)
            .get()
            .pipe(map(data => {
                    return data.docs.map((item) => this.conversationItemAdapter.adapt(item))
                })
            );
    }

    public async getInitialConversationFlow(conversationId: string): Promise<ConversationItem> {
        let conversationItem = this.cacheService.load(conversationId, COLLECTION_CONVERSATION_INITIAL_ID);

        if (!conversationItem) {
            conversationItem = await this.firestore.collection(COLLECTION_CONVERSATION_FLOW)
                .doc(conversationId)
                .collection(COLLECTION_CONVERSATION_FLOW_OPTIONS)
                .doc(COLLECTION_CONVERSATION_INITIAL_ID)
                .get()
                .pipe(map(item => this.conversationItemAdapter.adapt(item)))
                .toPromise();
            this.cacheService.save(conversationId, conversationItem);
        }

        return conversationItem as ConversationItem;
    }

    public async getConversationFlowById(conversationId: string, id: string): Promise<ConversationItem> {
        let conversationItem = this.cacheService.load(conversationId, id);

        if (!conversationItem) {
            conversationItem = await this.firestore.collection(COLLECTION_CONVERSATION_FLOW)
                .doc(conversationId)
                .collection(COLLECTION_CONVERSATION_FLOW_OPTIONS)
                .doc(id)
                .get()
                .pipe(map(item => this.conversationItemAdapter.adapt(item)))
                .toPromise();
            this.cacheService.save(conversationId, conversationItem);
        }

        return conversationItem as ConversationItem;
    }

    public deleteConversationItemOfConversationFlow(conversationId: string, conversationItemId: string): void {
        this.firestore
            .collection(COLLECTION_CONVERSATION_FLOW)
            .doc(conversationId)
            .collection(COLLECTION_CONVERSATION_FLOW_OPTIONS)
            .doc(conversationItemId)
            .delete();
    }

    public uploadConversationItemsToConversationFlow(conversationId: string, conversationItems: ConversationItem[]): void {
        for (const conversationItem of conversationItems) {
            this.firestore.collection(COLLECTION_CONVERSATION_FLOW)
                .doc(conversationId)
                .collection(COLLECTION_CONVERSATION_FLOW_OPTIONS)
                .doc(conversationItem.id)
                .set(JSON.parse(JSON.stringify(conversationItem)));
        }
    }

    async deleteConversationFlow(conversationId: string): Promise<void> {
        const results = await this.getConversationFlow(conversationId).toPromise();

        for (const result of results) {
            this.deleteConversationItemOfConversationFlow(conversationId, result.id);
        }
    }
}
