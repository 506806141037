import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { TrainerAdapter } from '../../shared/adapter/trainerAdapter';
import { Trainer } from '../../shared/models/trainer';
import { TrainerToStore } from '../models/TrainerToStore';


const COLLECTION_TRAINERS = 'trainers';

@Injectable({
    providedIn: 'root'
})
export class TrainerRepositoryService {

    constructor(
        private firestore: AngularFirestore,
        private trainerAdapter: TrainerAdapter
    ) {
    }

    /**
     * Get all trainers ordered by name
     */
    public getTrainers(): Observable<Trainer[]> {
        return this.firestore.collection(COLLECTION_TRAINERS, ref => ref.orderBy('name')).snapshotChanges()
            .pipe(
                map((data: any[]) => data.map((item) => this.trainerAdapter.adapt(item)))
            )
            .pipe(
                // Sorting in Firestore is case sensitive. There is no flag to make the sorting ignore the case
                tap(results => {
                    results.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                })
            );
    }

    public getNewId(): string {
        return this.firestore.createId();
    }

    public createTrainer(data: any, trainerId: string): void {
        this.firestore.collection(COLLECTION_TRAINERS).doc(trainerId).set({name:data.name, description:data.description});
    }

    public editTrainer(data: any, trainerId: string): void {
        this.firestore.collection(COLLECTION_TRAINERS).doc(trainerId).set({name:data.name, description:data.description});
    }

}