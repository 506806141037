import { Gender } from './../../shared/models/gender';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreateEditConversationService} from '../services/create-edit-conversation.service';
import {ToastrService} from 'ngx-toastr';
import {MatInput} from '@angular/material/input';
import {FileValidator} from '../../shared/validators/file-validators';
import {ConversationFileParserService} from '../services/conversation-file-parser.service';

@Component({
    selector: 'app-conversation-new-form',
    templateUrl: './conversation-new-form.component.html',
    styleUrls: ['./conversation-new-form.component.scss']
})
export class ConversationNewFormComponent implements OnInit {
    @Output() submitted = new EventEmitter<boolean>();
    @ViewChild('inputName', {static: true}) inputName: MatInput;
    conversationForm: FormGroup;
    loading = false;
    genderValue: string;

    genders: Gender[] = [
        {code: 'woman', value: 'Weibliche Stimme'},
        {code: 'man', value: 'Männliche Stimme'}
    ];

    constructor(
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private createEditConversationService: CreateEditConversationService,
        private conversationFileParserService: ConversationFileParserService
    ) {
    }

    ngOnInit(): void {
        this.createForm();
    }

    onCancel(): void {
        this.submitted.emit(true);
    }

    createForm(): void {
        const allowedImageExtensions = ['png', 'jpg', 'jpeg'];

        this.conversationForm = this.formBuilder.group({
            name: ['', Validators.required],
            topic: ['', Validators.required],
            image1: [null, [Validators.required, FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image2: [null, [Validators.required, FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image3: [null, [Validators.required, FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image4: [null, [Validators.required, FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image5: [null, [Validators.required, FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            conversation: [null, [Validators.required, FileValidator.fileMinSize(1), FileValidator.fileExtensions(['html', 'htm'])]],
            gender: ['', Validators.required]
        });
    }

    submitForm(): void {
        if (this.conversationForm.invalid) {
            return;
        }

        this.loading = true;

        this.createEditConversationService.uploadConversationFlow(this.conversationForm.value.conversation.files[0],
            conversationItems => {
                this.createEditConversationService.createConversation(this.conversationForm.value, conversationItems)
                    .then(() => {
                        this.submitted.emit(true);
                        this.toastr.info('Conversation created');
                    })
                    .catch(error => this.toastr.error('An error has occurred. Please try again'))
                    .finally(() => this.loading = false);
            },
            error => {
                this.toastr.error(error);
                this.loading = false;
            }
        );

    }


}
