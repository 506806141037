import { FirestoreService } from './../../../core/services/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import {User} from 'firebase';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class LoginRepositoryService {

  constructor(private afAuth: AngularFireAuth, private toastr: ToastrService, private firestoreService: FirestoreService) {}

  async doLogin(email, password){
    const {user}  = await this.afAuth.signInWithEmailAndPassword(email, password);
    return user;
  }

  async signUp(email, password) {
    const {user} = await this.afAuth.createUserWithEmailAndPassword(email, password);
    return user;
  }

  async sendResetPasswordEmail(email: string) {
    await this.afAuth.sendPasswordResetEmail(email);
  }

  async verifyPasswordResetCode(code: string) {
    await this.afAuth.verifyPasswordResetCode(code);
  }

  async resetPassword(code: string, password: string) {
    await this.afAuth.confirmPasswordReset(code, password);
  }

  async deleteCurrentUser(password: string): Promise<boolean> {
    const user = await this.getCurrentUser() as User;
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
      await user.reauthenticateWithCredential(credential);
      await user.delete();
      return true;
    } catch (e) {
      return false;
    }
  }

  async doLogout(){
    try {
      await this.afAuth.signOut();
    } catch (error) {
      this.toastr.error('', error);
    }
  }

  getCurrentUser(){
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  isLogged(){
    return this.afAuth.authState.pipe(
      switchMap( user => {
        if (user){
          return user.uid;
        }else{
          return of(null);
        }
      })
    );
  }
}
