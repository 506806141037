import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import { ConversationTrainer } from '../models/conversationTrainer';


@Injectable({
    providedIn: 'root',
})
export class ConversationTrainerAdapter implements Adapter<ConversationTrainer> {
    adapt(item: any): ConversationTrainer {
        return new ConversationTrainer(
            item.payload.doc ? item.payload.doc.id : item.payload.id,
            item.payload.doc ? item.payload.doc.data() : item.payload.data()
        );
    }
}