import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { TTSItemsToTTSItemsDTOMapper } from '../mappers/ttsItemsToTTSItemsDTOMapper';
import {ConversationItem} from '../models/conversationItem';
import { ConversationTTSRepository } from './conversation-tts-repository.service';

@Injectable({
    providedIn: 'root'
})
export class ConversationTTSService {

    constructor(private conversationTTSRepository: ConversationTTSRepository) {
    }

    public sendConversationTTSItems(conversationId: string, conversationItems: ConversationItem[], gender: string): Observable<any>  {
        let textsToSend = [];
        for (const conversationItem of conversationItems) {
            if(conversationItem.id.indexOf('R') > -1){
                textsToSend.push(conversationItem.text);
            }else{
                textsToSend.push(conversationItem.nexts[0].text);
            }
        }
        const textsUniqueToSend = [...new Set(textsToSend)];
        
        return this.conversationTTSRepository.sendTTSItems(TTSItemsToTTSItemsDTOMapper.map(conversationId, textsUniqueToSend, gender));
    }

}
