import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTransformService {
  constructor() {}

  static dateToString(dateTotransform) {
    let date = new Date(dateTotransform);
    const day = date.getDate().toString().padStart(2,'0');
    const month  = (date.getMonth() + 1).toString().padStart(2,'0');
    const year  = date.getFullYear().toString();
    return day + '.' + month +'.'+ year;
  }

  static dateToMonthString(dateTotransform) {
    let date = new Date(dateTotransform);
    let dateFormat = new Intl.DateTimeFormat('de-DE', {
      month: '2-digit',
    }).format(date);
    return dateFormat;
  }

  static dateToStringMonthAndYear(dateTotransform) {
    let date = new Date(dateTotransform);
    let dateFormat = new Intl.DateTimeFormat('de-DE', {
      month: 'long',
      year: 'numeric'
    }).format(date);
    return dateFormat;
  }

  static dateToStringMonthAndYearFormatted(dateTotransform) {
    let date = new Date(dateTotransform);
    const month = this.dateToMonthString(date);
    let dateFormat = new Intl.DateTimeFormat('de-DE', {
      year: 'numeric'
    }).format(date);
    return month + '/' + dateFormat;
  }

  static dateToStringOnlyYear(dateTotransform) {
    let date = new Date(dateTotransform);
    let dateFormat = new Intl.DateTimeFormat('de-DE', {
      year: 'numeric'
    }).format(date);
    return dateFormat;
  }

  static dateToCustomWeekString(dateTotransform) {
    let date = new Date(dateTotransform);
    const weekday = this.dayToWeekDayString(date);
    let dateFormat = new Intl.DateTimeFormat('de-DE', {
      day: '2-digit',
      month: 'long'
    }).format(date);
    return weekday + ', ' + dateFormat;
  }

  static fullDateToString(dateTotransform) {
    let date = new Date(dateTotransform);
    let dateFormat = new Intl.DateTimeFormat('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
    return dateFormat;
  }

  static timeStampToDate(timestamp) {
    let date = new Date(timestamp * 1000);
    return date;
  }

  static timeStampLongFormatToDate(timestamp) {
    let date = new Date(timestamp);
    return date;
  }

  static stringToDate(dateToTransform) {
    let dateComponents = dateToTransform.split('.');
    let transformedDate = new Date(
      dateComponents[2],
      dateComponents[1] - 1,
      dateComponents[0]
    );
    return transformedDate;
  }

  static dayToWeekDayString(dateTotransform) {
    const weekday = new Array(7);
    weekday[0] = 'So.';
    weekday[1] = 'Mo.';
    weekday[2] = 'Di.';
    weekday[3] = 'Mi.';
    weekday[4] = 'Do.';
    weekday[5] = 'Fr.';
    weekday[6] = 'Sa.';
    return weekday[dateTotransform.getDay()];
  }
}