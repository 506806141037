import { Injectable } from '@angular/core';
import {ConversationItem} from '../../../shared/models/conversationItem';
import {BehaviorSubject, Observable} from 'rxjs';
import {RINDEX_VALUES} from '../../../shared/values/values';

class TagTime {
  tag: string;
  timesSelected: number;
  timesShown: number;
}

class TagStat {
  percentage: number;
  timesSelected: number;
  timesShown: number;
  tagTimes: TagTime[];

  constructor() {
    this.tagTimes = [];
  }
}

interface RatioValue {
  ratio: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private results = new BehaviorSubject<ConversationItem[]>([]);
  private historicalResults = new BehaviorSubject<ConversationItem[]>([]);

  constructor() { }

  /**
   * Store the conversation items of a conversation results to calculate the stats
   */
  setResults(results: ConversationItem[]): void {
    this.results.next(results);
  }

  onReportResults(): Observable<ConversationItem[]> {
    return this.results.asObservable();
  }

  /**
   * Store the conversation items of a historical conversation results to calculate the stats
   */
   setHistoricalResults(results: ConversationItem[]): void {
    this.historicalResults.next(results);
  }

  onReportHistoricalResults(): Observable<ConversationItem[]> {
    return this.historicalResults.asObservable();
  }

  /**
   * Given an array of tags, this method calculates returns the tag stat
   */
  getTagStats(tags: string[]): TagStat {
    const tagStat = new TagStat();

    // Calculate times that a tag is selected and shown
    for (const tag of tags) {
      const tagTime = this.getTagTime(tag);
      tagStat.tagTimes.push(tagTime);
    }

    tagStat.timesSelected = this.getTagTimesSelectedOnQuestions(tags);
    tagStat.timesShown = this.getTagTimesShownOnQuestions(tags);
    tagStat.percentage = tagStat.timesShown ? Math.floor((tagStat.timesSelected * 100) / tagStat.timesShown) : 0;

    return tagStat;
  }

  /**
   * Given an array of historical tags, this method calculates returns the tag stat
   */
   getHistoricalTagStats(tags: string[]): TagStat {
    const tagStat = new TagStat();

    // Calculate times that a tag is selected and shown
    for (const tag of tags) {
      const tagTime = this.getHistoricalTagTime(tag);
      tagStat.tagTimes.push(tagTime);
    }

    tagStat.timesSelected = this.getHistoricalTagTimesSelectedOnQuestions(tags);
    tagStat.timesShown = this.getHistoricalTagTimesShownOnQuestions(tags);
    tagStat.percentage = tagStat.timesShown ? Math.floor((tagStat.timesSelected * 100) / tagStat.timesShown) : 0;

    return tagStat;
  }

  private getTagTime(tag: string): TagTime {
    const tagTime = new TagTime();
    tagTime.tag = tag;
    tagTime.timesSelected = this.getTagTimesSelectedOnQuestions([tag]);
    tagTime.timesShown = this.getTagTimesShownOnQuestions([tag]);

    return tagTime;
  }

  private getHistoricalTagTime(tag: string): TagTime {
    const tagTime = new TagTime();
    tagTime.tag = tag;
    tagTime.timesSelected = this.getHistoricalTagTimesSelectedOnQuestions([tag]);
    tagTime.timesShown = this.getHistoricalTagTimesShownOnQuestions([tag]);

    return tagTime;
  }

  /**
   * Given a mark and a phase, it returns the number of times a question of this phase has this mark.
   */
  getTimesForMarkAndPhase(mark: string, phase: string): number {
    let times = 0;

    for (const conversationItem of this.results.getValue()) {
      if (conversationItem.kind === 'Agent' && conversationItem.phase === phase && conversationItem.mark === mark) {
        times++;
      }
    }

    return times;
  }

  /**
   * It returns the times a question with a mark has been selected from 9 (maximal number of questions with a mark)
   */
  getPercentageOfMark(): number {
    let times = [];

    for (const conversationItem of this.results.getValue()) {
      if (conversationItem.kind === 'Agent' && conversationItem.mark !== '') {
        times.push(conversationItem.phase + conversationItem.mark);
      }
    }

    times = [...new Set(times)];

    return Math.floor((times.length * 100) / 9);
  }

  /**
   * Historical it returns the times a question with a mark has been selected from 9 (maximal number of questions with a mark)
   */
   getHistoricalPercentageOfMark(): number {
    let times = [];

    for (const conversationItem of this.historicalResults.getValue()) {
      if (conversationItem.kind === 'Agent' && conversationItem.mark !== '') {
        times.push(conversationItem.phase + conversationItem.mark);
      }
    }

    times = [...new Set(times)];

    return Math.floor((times.length * 100) / 9);
  }

  /**
   * It returns an array with the evolution of the r-index by each question
   */
  getRIndexValuesFromResults(): number[] {
    const rIndex = [0];

    for (const conversationItem of this.results.getValue()) {
      if (conversationItem.kind === 'Agent') {
        if (typeof conversationItem.rating !== 'undefined' && (conversationItem.rating in RINDEX_VALUES)) {
          rIndex.push(rIndex[rIndex.length - 1] + RINDEX_VALUES[conversationItem.rating]);
        } else {
          rIndex.push(rIndex[rIndex.length - 1]);
        }
      }
    }

    return rIndex;
  }

  /**
   * It returns the final avatar's motivation according the rating of its answers
   */
  getMotivation(): number {
    const motivationPlus = this.getSummeChangeTalk();
    const motivationMinus = this.getSummeSustainTalk();

    return Math.floor((motivationPlus * 100) / (motivationPlus + motivationMinus));
  }

  /**
   * Historical, it returns the final avatar's motivation according the rating of its answers
   */
   getHistoricalMotivation(): number {
    const motivationPlus = this.getHistoricalSummeChangeTalk();
    const motivationMinus = this.getHistoricalSummeSustainTalk();

    return Math.floor((motivationPlus * 100) / (motivationPlus + motivationMinus));
  }

  getSummeChangeTalk(): number {
    let motivationPlus = 0;

    for (const conversationItem of this.results.getValue()) {
      if (conversationItem.kind === 'Rezipient' && conversationItem.rating === '+') {
        motivationPlus++;
      }
    }

    return motivationPlus;
  }


  getHistoricalSummeChangeTalk(): number {
    let motivationPlus = 0;

    for (const conversationItem of this.historicalResults.getValue()) {
      if (conversationItem.kind === 'Rezipient' && conversationItem.rating === '+') {
        motivationPlus++;
      }
    }

    return motivationPlus;
  }

  getSummeSustainTalk(): number {
    let motivationMinus = 0;

    for (const conversationItem of this.results.getValue()) {
      if (conversationItem.kind === 'Rezipient' && conversationItem.rating === '-') {
        motivationMinus++;
      }
    }

    return motivationMinus;
  }

  getHistoricalSummeSustainTalk(): number {
    let motivationMinus = 0;

    for (const conversationItem of this.historicalResults.getValue()) {
      if (conversationItem.kind === 'Rezipient' && conversationItem.rating === '-') {
        motivationMinus++;
      }
    }

    return motivationMinus;
  }

  private getTagTimesSelectedOnQuestions(tags: string[]): number {
    let times = 0;

    for (const conversationItem of this.results.getValue()) {
      if (conversationItem.kind === 'Agent' && conversationItem.methods.some(m => tags.includes(m))) {
        times++;
      }
    }

    return times;
  }


  private getHistoricalTagTimesSelectedOnQuestions(tags: string[]): number {
    let times = 0;

    for (const conversationItem of this.historicalResults.getValue()) {
      if (conversationItem.kind === 'Agent' && conversationItem.methods.some(m => tags.includes(m))) {
        times++;
      }
    }

    return times;
  }

  private getTagTimesShownOnQuestions(tags: string[]): number {
    let times = 0;

    for (const conversationItem of this.results.getValue()) {
      if (conversationItem.kind === 'Rezipient') {
        for (const questionItem of conversationItem.nexts) {
          if (questionItem.methods.some(m => tags.includes(m))) {
            times++;
            break;
          }
        }
      }
    }

    return times;
  }

  private getHistoricalTagTimesShownOnQuestions(tags: string[]): number {
    let times = 0;

    for (const conversationItem of this.historicalResults.getValue()) {
      if (conversationItem.kind === 'Rezipient') {
        for (const questionItem of conversationItem.nexts) {
          if (questionItem.methods.some(m => tags.includes(m))) {
            times++;
            break;
          }
        }
      }
    }

    return times;
  }

  getRatioBetweenValues(value1: number, value2: number): RatioValue {
    if (value1 === value2) {
      return {
        ratio: '1:1',
        color: 'lightGreen'
      };
    } else if (value1 > value2) {
      if (value2 === 0) {
        return {
          ratio: value1 + ':0',
          color: 'darkGreen'
        };
      } else if(Math.floor(value1 / value2) === 1){
        return {
          ratio: Math.floor(value1 / value2) + ':1',
          color: 'lightGreen'
        };
      }else {
        return {
          ratio: Math.floor(value1 / value2) + ':1',
          color: 'darkGreen'
        };
      }
    } else {
      if (value1 === 0) {
        return {
          ratio: '0:' + value2,
          color: 'orange'
        };
      } else if(Math.floor(value2 / value1) === 1){
        return {
          ratio: '1:' + Math.floor(value2 / value1),
          color: 'lightGreen'
        };
      } else {
        return {
          ratio: '1:' + Math.floor(value2 / value1),
          color: 'orange'
        };
      }
    }
  }

}
