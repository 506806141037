import {Component, Input, OnInit} from '@angular/core';
import {ReportService} from '../../../../services/report/report.service';
import {MatTableDataSource} from '@angular/material/table';
import {FinalScoreData, ReportTotalTabService} from '../../../../services/report/report-total-tab.service';
import {AuthService} from '../../../../../core/services/auth.service';
import {MessageDialogService} from '../../../../../shared/services/message-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, FEEDBACK_MESSAGE_KEY, LANGS} from '../../../../../shared/values';

@Component({
  selector: 'app-report-total-tab',
  templateUrl: './total-tab.component.html',
  styleUrls: ['./total-tab.component.scss']
})
export class TotalTabComponent implements OnInit {
  @Input() showPopup: boolean = true;
  displayedColumns: string[] = ['title', 'value'];
  dataSource: MatTableDataSource<any>;
  finalScoreData: FinalScoreData;

  constructor(
    private reportService: ReportService,
    private reportTotalTabService: ReportTotalTabService,
    private authService: AuthService,
    private messageDialogService: MessageDialogService,
    private translateService: TranslateService
  ) {
    translateService.addLangs(LANGS);
    translateService.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.loadReports();
    if (this.showPopup) {
      this.showPopupMessage();
    }
  }

  loadReports(): void {
    this.reportService.onReportResults().subscribe(results => {
      if (results.length) {
        this.setTableResults();
      }
    });
  }

  setTableResults(): void {
    const miKonsistentData = this.reportService.getTagStats(['oE+', 'oE-', 'RE+', 'RE-', 'A', 'B']);
    const miInkonsistentData = this.reportService.getTagStats(['r', 't']);

    const kommunikationsmethodePctg = Math.floor(miKonsistentData.percentage * 100 / (miInkonsistentData.percentage + miKonsistentData.percentage));
    const gesprachsphasenPctg =  this.reportService.getPercentageOfMark();
    const motivationPctg = this.reportService.getMotivation();
    const totalScore = Math.floor((kommunikationsmethodePctg + gesprachsphasenPctg + motivationPctg) / 3);

    this.finalScoreData = this.reportTotalTabService.getCommentTextAndClassForFinalScore(totalScore);

    const data = [
      {title: 'Kommunikationsmethode', value: `${kommunikationsmethodePctg}%`, color: ''},
      {title: 'Gesprächsphasen', value: `${gesprachsphasenPctg}%`, color: ''},
      {title: 'Veränderungsbereitschaft', value: `${motivationPctg}%`, color: ''},
      {title: 'Total Score', value: `${totalScore}%`, color: this.finalScoreData.color},
    ];

    this.dataSource = new MatTableDataSource<any>(data);
  }

  async showPopupMessage(): Promise<void> {
    const user = await this.authService.getCurrentUser();
    const showPopupMessage = JSON.parse(localStorage.getItem(FEEDBACK_MESSAGE_KEY)) || {};

    if (!(user.uid in showPopupMessage)) {
      const message = await this.translateService.get('COMMON.feedbackText').toPromise();
      const closeText = await this.translateService.get('COMMON.closeButton').toPromise();

      this.messageDialogService.open({
        message,
        closeText,
      });
      this.messageDialogService.confirmed().subscribe(value => {
        showPopupMessage[user.uid] = 'true';
        localStorage.setItem(FEEDBACK_MESSAGE_KEY, JSON.stringify(showPopupMessage));
      });
    }
  }

}
