import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpressumComponent } from './components/impressum/impressum.component';
import {CoreRoutingModule} from './core-routing.module';
import { DatenschutzComponent } from './components/datenschutz/datenschutz.component';
import { DatenschutzbestimmungenComponent } from './components/datenschutzbestimmungen/datenschutzbestimmungen.component';



@NgModule({
  declarations: [ImpressumComponent, DatenschutzComponent, DatenschutzbestimmungenComponent],
  imports: [
    CommonModule,
    CoreRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule { }
