import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../../services/report/report.service';
import {CommentOptional, ReportMiKonsistentTabService} from '../../../../services/report/report-mi-konsistent-tab.service';

@Component({
  selector: 'app-report-mi-konsistent-tab',
  templateUrl: './mi-konsistent-tab.component.html',
  styleUrls: ['./mi-konsistent-tab.component.scss']
})
export class MiKonsistentTabComponent implements OnInit {
  showFirstSection: boolean = false;
  showSecondSection: boolean = false;
  showThirdSection: boolean = false;
  showFourthSection: boolean = false;
  showTotalSection: boolean = false;
  percentages: number[];
  comments: string[];
  optionalComments: CommentOptional[];

  constructor(
      private reportService: ReportService,
      private reportMiKonsistentTabService: ReportMiKonsistentTabService
  ) { }

  ngOnInit(): void {
    this.initValues();
    this.loadReports();
  }

  initValues(): void {
    this.percentages = [0, 0, 0, 0];
    this.comments = ['', '', '', ''];
    this.optionalComments = [];
  }

  loadReports(): void {
    this.reportService.onReportResults().subscribe(results => {
      if (results.length) {
        this.setFirstSection();
        this.setSecondSection();
        this.setThirdSection();
        this.setFourthSection();
        this.setTotalSection();
      }
    });
  }

  setFirstSection(): void {
    const tagStat = this.reportService.getTagStats(['oE+', 'oE-']);
    this.percentages[0] = tagStat.percentage;
    this.comments[0] = this.reportMiKonsistentTabService.getCommentForFirstSection(tagStat.percentage);
    this.optionalComments[0] = this.reportMiKonsistentTabService.getCommentOptionalForFirstSection(
        tagStat.tagTimes[0].timesSelected,
        tagStat.tagTimes[1].timesSelected
    );

    this.showFirstSection = true;
  }

  setSecondSection(): void {
    const tagStat = this.reportService.getTagStats(['RE+', 'RE-']);
    this.percentages[1] = tagStat.percentage;
    this.comments[1] = this.reportMiKonsistentTabService.getCommentForSecondSection(tagStat.percentage);
    this.optionalComments[1] = this.reportMiKonsistentTabService.getCommentOptionalForSecondSection(
        tagStat.tagTimes[0].timesSelected,
        tagStat.tagTimes[1].timesSelected
    );

    this.showSecondSection = true;
  }

  setThirdSection(): void {
    const tagStat = this.reportService.getTagStats(['A']);
    this.percentages[2] = tagStat.percentage;
    this.comments[2] = this.reportMiKonsistentTabService.getCommentForThirdSection(tagStat.percentage);

    this.showThirdSection = true;
  }

  setFourthSection(): void {
    const tagStat = this.reportService.getTagStats(['B']);
    this.percentages[3] = tagStat.percentage;
    this.comments[3] = this.reportMiKonsistentTabService.getCommentForFourthSection(tagStat.percentage);

    this.showFourthSection = true;
  }

  setTotalSection(): void {
    const tagStat = this.reportService.getTagStats(['oE+', 'oE-', 'RE+', 'RE-', 'A', 'B']);
    this.percentages[4] = tagStat.percentage;

    this.showTotalSection = true;
  }

}
