<div class='container'>
    <div id="vergleichseiteTab">
        <h2 class="tab-title">Vergleichseite</h2>

        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="verhaltensma">
                <th mat-header-cell *matHeaderCellDef class="header">Verhaltensmaß</th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <ng-container matColumnDef="experte">
                <th mat-header-cell *matHeaderCellDef class="header">Experte</th>
                <td mat-cell *matCellDef="let element"> {{element.experte}} </td>
            </ng-container>

            <ng-container matColumnDef="basis-kompetenz">
                <th mat-header-cell *matHeaderCellDef class="header">Basis-Kompetenz</th>
                <td mat-cell *matCellDef="let element"> {{element['basis-kompetenz']}} </td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef class="header">Ihr erreichter Wert</th>
                <td mat-cell *matCellDef="let element" class="pctg-color">
                    <span *ngIf="element.color" [ngClass]="element.color">{{element.value}}</span>
                    <app-percentage-colour *ngIf="!element.color && !element.miInkonsistentColor" [number]="element.value"></app-percentage-colour>
                    <app-percentage-mi-inkonsistentes-colour *ngIf="!element.color && element.miInkonsistentColor" [number]="element.value"></app-percentage-mi-inkonsistentes-colour>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</div>
