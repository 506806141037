<div>
    <div class="codeArea">
        <span>{{ codeId }}</span>
    </div>
    <div class="calendarArea">
        <form  [formGroup]="dateForm" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-form-field appearance="fill" (click)=openCalendar() style="width:90px;">
                <!-- <mat-label>Enddatum</mat-label> -->
                <input id="calendarSelector" matInput [matDatepicker]="picker"
                    [min]="datepickerStartDate" disabled (dateChange)="calendarChanged($event)" formControlName="date">
                <mat-datepicker #picker disabled="false">
                </mat-datepicker>
            </mat-form-field>
        </form>
    </div>
    <button class="deleteCode" (click)="deleteCode()" mat-icon-button title="Löschen"><mat-icon>delete</mat-icon></button>
</div>
