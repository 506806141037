import { DeleteConversationTTSRepository } from './../repository/delete-conversation-tts-repository.service';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DeleteConversationTTSCache {

    constructor(private deleteConversationTTSRepository: DeleteConversationTTSRepository) {
    }

    public deleteConversationAudioItems(gender: string, conversationId): Observable<any>  {
        return this.deleteConversationTTSRepository.deleteItems(gender, conversationId);
    }

}
