import { Injectable } from '@angular/core';

const FIRST_SECTION_TITLE = 'Zu Beginn ist der Aufbau einer vertrauensvollen Arbeitsbeziehung von grundlegender Bedeutung (Beziehungsaufbau). Die Beziehung bildet die Basis des zukünftigen Miteinanders und stellt einen wichtigen Pfeiler für der Gesprächsperson dar.<br/>Die Phase des Beziehungsaufbaus lässt sich vor allem durch die Vermittlung einer akzeptierenden und partnerschaftlichen Grundhaltung gestalten. Ausdrücken lässt sich diese beispielsweise durch Wertschätzungen der Bemühungen des Patienten, durch die Betonung seiner Autonomie sowie durch die Bitte um Erlaubnis.';
const FIRST_SECTION_FORMEL_1_TITLE = 'Angebot an Ihre Gesprächsperson über ihre Erwartungen und ersten Gedanken gegenüber der Veränderung zu sprechen';
const FIRST_SECTION_FORMEL_1_SELECTED_TEXT = 'Sie haben Ihrer Gesprächsperson die Möglichkeit gegeben Ihnen gegenüber offen ihre ersten Gedanken bezüglich der Veränderung zu äußern. Außerdem sind Sie Ihrer Gesprächsperson mit Akzeptanz für ihre Meinungen und Gefühle bezüglich der Veränderung gegenübergetreten';
const FIRST_SECTION_FORMEL_1_SELECTED_CLASS = 'lightGreen';
const FIRST_SECTION_FORMEL_1_NOSELECTED_TEXT = 'Denken Sie daran, Ihrer Gesprächsperson die Möglichkeit zu geben Ihnen gegenüber offen ihre ersten Gedanken bezüglich der Veränderung äußern zu können. Treten Sie außerdem Ihrer Gesprächsperson mit Akzeptanz für ihre Meinungen und Gefühle bezüglich der Veränderung gegenüber.';
const FIRST_SECTION_FORMEL_1_NOSELECTED_CLASS = 'orange';
const FIRST_SECTION_FORMEL_2_TITLE = 'Sammeln von Informationen über die persönliche Betroffenheit von der Veränderung';
const FIRST_SECTION_FORMEL_2_SELECTED_TEXT = 'Sie haben ausreichend Informationen dazu eingeholt, in welcher Rolle Ihre Gesprächsperson sich persönlich bei der Umsetzung der Veränderung sieht, um zur nächsten Gesprächsphase übergehen zu können.';
const FIRST_SECTION_FORMEL_2_SELECTED_CLASS = 'lightGreen';
const FIRST_SECTION_FORMEL_2_NOSELECTED_TEXT = 'Denken Sie daran, in der Beziehungsphase ausreichend Informationen über Ihre Gesprächsperson einzuholen, wie beispielsweise in welcher Rolle Ihre Gesprächsperson sich persönlich bei der Umsetzung der Veränderung sieht, um zur nächsten Gesprächsphase übergehen zu können.';
const FIRST_SECTION_FORMEL_2_NOSELECTED_CLASS = 'orange';

const SECOND_SECTION_TITLE = 'Im Prozessschritt der Fokussierung sollten die wichtigsten Probleme erkannt werden. Diese Fokussierung sollte mit der Gesprächsperson gemeinsam durchgeführt werden, um sicherzustellen, dass die Dinge thematisiert werden, die auch für die Gesprächsperson von aktueller Bedeutung sind.';
const SECOND_SECTION_FORMEL_1_TITLE = 'Herausarbeiten der Themen, welche aus Sicht der Gesprächsperson für sie persönlich besonders relevant sind.';
const SECOND_SECTION_FORMEL_1_SELECTED_TEXT = 'Sie haben Ihre Gesprächsperson dabei unterstützt zu identifizieren, welche Themen der Veränderung für sie besonders bedeutsam sind. Dadurch haben Sie gemeinsam mit Ihrer Gesprächsperson den Fokus des Gesprächs festgelegt.';
const SECOND_SECTION_FORMEL_1_SELECTED_CLASS = 'lightGreen';
const SECOND_SECTION_FORMEL_1_NOSELECTED_TEXT = 'Vergessen Sie nicht, gemeinsam mit Ihrer Gesprächsperson herauszuarbeiten, welche Themen der Veränderung für die Gesprächsperson besonders bedeutsam sind.Den Fokus des Gesprächs festzulegen, bevor über die Vor- und Nachteile der Veränderung gesprochen werden, ist ein wichtiger Bestandteil des Gesprächsverlaufs im MI.';
const SECOND_SECTION_FORMEL_1_NOSELECTED_CLASS = 'orange';

const THIRD_SECTION_TITLE = 'Wenn es klar ist, welche Richtung des Themas für die Gesprächsperson von Interesse ist, kann sich der Prozess der Evokation (lat. evocatio: Hervor-, Herausrufen) anschließen. Ihre Aufgabe in der Rolle als Change Agent ist es nun, verstärkt die individuellen Gründe der Gesprächsperson für eine Veränderung, also Change Talk, hervorzulocken. Diese Phase sollte eingeleitet werden, nachdem zuvor ein adäquater Beziehungsaufbau sowie die Fokussierung auf eine Richtung des Themas erfolgten.';
const THIRD_SECTION_FORMEL_1_TITLE = 'Explorieren von Gründen für und gegen die Veränderung';
const THIRD_SECTION_FORMEL_1_SELECTED_TEXT = 'Sie haben darauf geachtet Ihrer Gesprächsperson die Möglichkeit zu geben, sowohl die Vor- als auch die Nachteile der Veränderung zu explorieren.';
const THIRD_SECTION_FORMEL_1_SELECTED_CLASS = 'lightGreen';
const THIRD_SECTION_FORMEL_1_NOSELECTED_TEXT = 'Denken Sie daran, dass Sie Ihrer Gesprächsperson die Möglichkeit geben, sowohl die Vor- als auch die Nachteile der Veränderung zu explorieren. Beide Seiten sind wichtig, damit Ihre Gesprächsperson eine gut informierte Entscheidung treffen kann.';
const THIRD_SECTION_FORMEL_1_NOSELECTED_CLASS = 'orange';
const THIRD_SECTION_FORMEL_2_TITLE = 'Identifikation der Notwendigkeit der Veränderung für die Gesprächsperson';
const THIRD_SECTION_FORMEL_2_SELECTED_TEXT = 'Sie haben Ihre Gesprächsperson dabei unterstützt zu erkennen, welche Notwendigkeit die Veränderung für sie persönlich hat und welche potentiellen Probleme eine Nicht-Umsetzung der Veränderung haben könnte.';
const THIRD_SECTION_FORMEL_2_SELECTED_CLASS = 'lightGreen';
const THIRD_SECTION_FORMEL_2_NOSELECTED_TEXT = 'Vergessen Sie nicht gemeinsam mit Ihrer Gesprächsperson zu explorieren, welche Notwendigkeit die Veränderung für die Gesprächsperson persönlich hat und welche potentiellen Probleme eine Nicht-Umsetzung der Veränderung haben könnte.';
const THIRD_SECTION_FORMEL_2_NOSELECTED_CLASS = 'orange';
const THIRD_SECTION_FORMEL_3_TITLE = 'Identifikation persönlicher Motive für die Veränderung';
const THIRD_SECTION_FORMEL_3_SELECTED_TEXT = 'Sie haben Ihrer Gesprächsperson dabei geholfen, bedeutsame, persönliche Werte und Motive, die für die Veränderung sprechen herauszuarbeiten.';
const THIRD_SECTION_FORMEL_3_SELECTED_CLASS = 'lightGreen';
const THIRD_SECTION_FORMEL_3_NOSELECTED_TEXT = 'Denken Sie daran Ihre Gesprächsperson dabei zu unterstützen, bedeutsame, persönliche Werte und Motive, die für die Veränderung sprechen zu explorieren.';
const THIRD_SECTION_FORMEL_3_NOSELECTED_CLASS = 'orange';
const THIRD_SECTION_FORMEL_4_TITLE = 'Zusammenfassung der Evokations-Phase';
const THIRD_SECTION_FORMEL_4_SELECTED_TEXT = 'Es ist Ihnen gelungen durch die Nutzung einer Zusammenfassung, welche Change Talk und Sustain Talk der Gesprächsperson enthält, die Evokationsphase abzuschließen.';
const THIRD_SECTION_FORMEL_4_SELECTED_CLASS = 'lightGreen';
const THIRD_SECTION_FORMEL_4_NOSELECTED_TEXT = 'Vergessen Sie nicht das Gesagte Ihrer Gesprächsperson in einer Zusammenfassung wiederzugegeben, die sowohl Change Talk als auch Sustain Talk enthält. Damit gelingt Ihnen ein gelungener Abschluss für die Evokations-Phase und bildet einen sinnvollen Übergang in die Planungs-Phase.';
const THIRD_SECTION_FORMEL_4_NOSELECTED_CLASS = 'orange';

const FOURTH_SECTION_TITLE = 'Sobald eine solch eindeutige Absicht zur Verhaltensänderung zu erkennen ist, kann die Planung der Veränderung besprochen werden. Dieser letzte Prozess im MI stellt sicher, dass die Gesprächsperson sich nicht nur ändern möchte, sondern auch einen konkreten Vorgehensplan entwickelt, der ihr dabei hilft die Veränderung auch umzusetzen.';
const FOURTH_SECTION_FORMEL_1_TITLE = 'Evokation zur Entwicklung eines Plans';
const FOURTH_SECTION_FORMEL_1_SELECTED_TEXT = 'Sie haben darauf geachtet Ihre Gesprächsperson zur eigenständigen Entwicklung eines Veränderungsplans zu ermuntern und ihr die Initiative überlassen.';
const FOURTH_SECTION_FORMEL_1_SELECTED_CLASS = 'lightGreen';
const FOURTH_SECTION_FORMEL_1_NOSELECTED_TEXT = 'Achten Sie darauf, dass Sie Ihrer Gesprächsperson die Initiative zur eigenständigen Entwicklung eines Veränderungsplans zu überlassen.';
const FOURTH_SECTION_FORMEL_1_NOSELECTED_CLASS = 'orange';
const FOURTH_SECTION_FORMEL_2_TITLE = 'Zusammenfassung der Planungsphase';
const FOURTH_SECTION_FORMEL_2_SELECTED_TEXT = 'Es ist Ihnen gelungen eine gute Zusammenfassung des Veränderungsplans Ihrer Gesprächsperson wiederzugeben. Damit haben Sie die wichtigsten, nächsten Schritte für Ihre Gesprächsperson festgehalten und ihr die Möglichkeit gegeben dies zu bestätigen.';
const FOURTH_SECTION_FORMEL_2_SELECTED_CLASS = 'lightGreen';
const FOURTH_SECTION_FORMEL_2_NOSELECTED_TEXT = 'Denken Sie daran eine gute Zusammenfassung des Veränderungsplans Ihrer Gesprächsperson wiederzugeben. Damit haben Sie die wichtigsten, nächsten Schritte für Ihre Gesprächsperson festgehalten und ihr die Möglichkeit gegeben dies zu bestätigen.';
const FOURTH_SECTION_FORMEL_2_NOSELECTED_CLASS = 'orange';

export interface FirstSectionData {
  title: string;
  firstTitle: string;
  firstText: string;
  firstClassColor: string;
  secondTitle: string;
  secondText: string;
  secondClassColor: string;
}

export interface SecondSectionData {
  title: string;
  firstTitle: string;
  firstText: string;
  firstClassColor: string;
}

export interface ThirdSectionData {
  title: string;
  firstTitle: string;
  firstText: string;
  firstClassColor: string;
  secondTitle: string;
  secondText: string;
  secondClassColor: string;
  thirdTitle: string;
  thirdText: string;
  thirdClassColor: string;
  fourthTitle: string;
  fourthText: string;
  fourthClassColor: string;
}

export interface FourthSectionData {
  title: string;
  firstTitle: string;
  firstText: string;
  firstClassColor: string;
  secondTitle: string;
  secondText: string;
  secondClassColor: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportGesprachsphasenTabService {

  constructor() { }

  getCommentForFirstSection(timesMark1: number, timesMark2: number): FirstSectionData {
    const data = {} as FirstSectionData;

    data.title = FIRST_SECTION_TITLE;
    data.firstTitle = FIRST_SECTION_FORMEL_1_TITLE;
    data.firstText = timesMark1 ? FIRST_SECTION_FORMEL_1_SELECTED_TEXT : FIRST_SECTION_FORMEL_1_NOSELECTED_TEXT;
    data.firstClassColor = timesMark1 ? FIRST_SECTION_FORMEL_1_SELECTED_CLASS : FIRST_SECTION_FORMEL_1_NOSELECTED_CLASS;
    data.secondTitle = FIRST_SECTION_FORMEL_2_TITLE;
    data.secondText = timesMark2 ? FIRST_SECTION_FORMEL_2_SELECTED_TEXT : FIRST_SECTION_FORMEL_2_NOSELECTED_TEXT;
    data.secondClassColor = timesMark2 ? FIRST_SECTION_FORMEL_2_SELECTED_CLASS : FIRST_SECTION_FORMEL_2_NOSELECTED_CLASS;

    return data;
  }

  getCommentForSecondSection(timesMark1: number): SecondSectionData {
    const data = {} as SecondSectionData;

    data.title = SECOND_SECTION_TITLE;
    data.firstTitle = SECOND_SECTION_FORMEL_1_TITLE;
    data.firstText = timesMark1 ? SECOND_SECTION_FORMEL_1_SELECTED_TEXT : SECOND_SECTION_FORMEL_1_NOSELECTED_TEXT;
    data.firstClassColor = timesMark1 ? SECOND_SECTION_FORMEL_1_SELECTED_CLASS : SECOND_SECTION_FORMEL_1_NOSELECTED_CLASS;

    return data;
  }

  getCommentForThirdSection(timesMark1: number, timesMark2: number, timesMark3: number, timesMark4: number): ThirdSectionData {
    const data = {} as ThirdSectionData;

    data.title = THIRD_SECTION_TITLE;
    data.firstTitle = THIRD_SECTION_FORMEL_1_TITLE;
    data.firstText = timesMark1 ? THIRD_SECTION_FORMEL_1_SELECTED_TEXT : THIRD_SECTION_FORMEL_1_NOSELECTED_TEXT;
    data.firstClassColor = timesMark1 ? THIRD_SECTION_FORMEL_1_SELECTED_CLASS : THIRD_SECTION_FORMEL_1_NOSELECTED_CLASS;
    data.secondTitle = THIRD_SECTION_FORMEL_2_TITLE;
    data.secondText = timesMark2 ? THIRD_SECTION_FORMEL_2_SELECTED_TEXT : THIRD_SECTION_FORMEL_2_NOSELECTED_TEXT;
    data.secondClassColor = timesMark2 ? THIRD_SECTION_FORMEL_2_SELECTED_CLASS : THIRD_SECTION_FORMEL_2_NOSELECTED_CLASS;
    data.thirdTitle = THIRD_SECTION_FORMEL_3_TITLE;
    data.thirdText = timesMark3 ? THIRD_SECTION_FORMEL_3_SELECTED_TEXT : THIRD_SECTION_FORMEL_3_NOSELECTED_TEXT;
    data.thirdClassColor = timesMark3 ? THIRD_SECTION_FORMEL_3_SELECTED_CLASS : THIRD_SECTION_FORMEL_3_NOSELECTED_CLASS;
    data.fourthTitle = THIRD_SECTION_FORMEL_4_TITLE;
    data.fourthText = timesMark4 ? THIRD_SECTION_FORMEL_4_SELECTED_TEXT : THIRD_SECTION_FORMEL_4_NOSELECTED_TEXT;
    data.fourthClassColor = timesMark4 ? THIRD_SECTION_FORMEL_4_SELECTED_CLASS : THIRD_SECTION_FORMEL_4_NOSELECTED_CLASS;

    return data;
  }

  getCommentForFourthSection(timesMark1: number, timesMark2: number): FourthSectionData {
    const data = {} as FourthSectionData;

    data.title = FOURTH_SECTION_TITLE;
    data.firstTitle = FOURTH_SECTION_FORMEL_1_TITLE;
    data.firstText = timesMark1 ? FOURTH_SECTION_FORMEL_1_SELECTED_TEXT : FOURTH_SECTION_FORMEL_1_NOSELECTED_TEXT;
    data.firstClassColor = timesMark1 ? FOURTH_SECTION_FORMEL_1_SELECTED_CLASS : FOURTH_SECTION_FORMEL_1_NOSELECTED_CLASS;
    data.secondTitle = FOURTH_SECTION_FORMEL_2_TITLE;
    data.secondText = timesMark2 ? FOURTH_SECTION_FORMEL_2_SELECTED_TEXT : FOURTH_SECTION_FORMEL_2_NOSELECTED_TEXT;
    data.secondClassColor = timesMark2 ? FOURTH_SECTION_FORMEL_2_SELECTED_CLASS : FOURTH_SECTION_FORMEL_2_NOSELECTED_CLASS;

    return data;
  }
}
