import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserCodeDetails } from '../../../../shared/models/userCodeDetails';

@Component({
  selector: 'app-create-code-dialog',
  templateUrl: './create-code-dialog.component.html',
  styleUrls: ['./create-code-dialog.component.scss']
})
export class CreateCodeDialogComponent implements OnInit {

  userCodeDetails: UserCodeDetails = new UserCodeDetails();
  editMode: boolean;
  modalTitle: string;
  saveButtonText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<CreateCodeDialogComponent>,
   ) {
    dialogRef.disableClose = true;
    if (data) {
      this.userCodeDetails = data.userCodeDetails;
      this.editMode = data.editMode;
      this.modalTitle = data.modalTitle;
      this.saveButtonText = data.saveButtonText;
    }
  }

  onSubmitted(submitted: boolean) {
      this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
