import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DeleteConversationTTSRepository {

  constructor(private httpClient: HttpClient) {}

  deleteItems(gender: string, conversationId: string): Observable<any> {
    const url: string = environment.domainUrl + environment.ttsEndpoints.deleteConversation + '/' + gender +'/'+ conversationId;
    return this.httpClient.delete(url);
  }
}
