import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {Conversation} from '../models/conversation';

@Injectable({
    providedIn: 'root',
})
export class ConversationAdapter implements Adapter<Conversation> {
    adapt(item: any): Conversation {
        return new Conversation(
            item.payload.doc ? item.payload.doc.id : item.payload.id,
            item.payload.doc ? item.payload.doc.data() : item.payload.data()
        );
    }
}
