import { AuthService } from './../services/auth.service';
import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router, private ngZone: NgZone){

  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.user$.pipe(
      take(1),
      map(user => user && user.admin ? true : false),
      tap(isAdmin => {
        if (!isAdmin){
          this.toastr.warning('', 'Access denied - Admins only');
          this.ngZone.run(() => {
            this.router.navigate(['/auth/login']);
          });
        }
      })
    );
  }
}
