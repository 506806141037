import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLayoutComponent } from './components/chat-layout/chat-layout.component';
import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { AvatarPanelComponent } from './components/avatar-panel/avatar-panel.component';
import { ChatPanelComponent } from './components/chat-panel/chat-panel.component';
import { ContactsPanelComponent } from './components/contacts-panel/contacts-panel.component';
import {UserRoutingModule} from './user-routing.module';
import { ChatQuestionsComponent } from './components/chat-panel/chat-questions/chat-questions.component';
import { ReportComponent } from './components/report/report.component';
import { ChatAnswerComponent } from './components/chat-panel/chat-answer/chat-answer.component';
import { AvatarFooterComponent } from './components/avatar-panel/avatar-footer/avatar-footer.component';
import { ChatCurrentQuestionComponent } from './components/chat-panel/chat-answer/chat-current-question/chat-current-question.component';
import { ChatCurrentQuestionRatingComponent } from './components/chat-panel/chat-answer/chat-current-question-rating/chat-current-question-rating.component';
import { ChatCurrentAnswerComponent } from './components/chat-panel/chat-answer/chat-current-answer/chat-current-answer.component';
import { MiKonsistentTabComponent } from './components/report/tabs/mi-konsistent-tab/mi-konsistent-tab.component';
import { ReportBoxComponent } from './components/report/report-box/report-box.component';
import { ContactHeaderComponent } from './components/contact-header/contact-header.component';
import { PercentageColourComponent } from './components/report/percentage-colour/percentage-colour.component';
import { TotalPercentageComponent } from './components/report/total-percentage/total-percentage.component';
import {MiInkonsistentTabComponent} from './components/report/tabs/mi-inkonsistent-tab/mi-inkonsistent-tab.component';
import {GesprachsphasenTabComponent} from './components/report/tabs/gesprachsphasen-tab/gesprachsphasen-tab.component';
import {VeranderungsbereitschaftTabComponent} from './components/report/tabs/veranderungsbereitschaft-tab/veranderungsbereitschaft-tab.component';
import {TotalTabComponent} from './components/report/tabs/total-tab/total-tab.component';
import {SharedModule} from '../shared/shared.module';
import { VergleichseiteTabComponent } from './components/report/tabs/vergleichseite-tab/vergleichseite-tab.component';
import { RindexChartComponent } from './components/report/rindex-chart/rindex-chart.component';
import {PercentageMiInkonsistentesColourComponent} from './components/report/percentage-colour/percentage-mi-inkonsistentes-colour.component';
import { ExpiredLayoutComponent } from './components/expired-layout/expired-layout.component';
import { ExpiredSessionPanelComponent } from './components/expired-session-panel/expired-session-panel.component';

@NgModule({
  declarations: [
    ChatLayoutComponent,
    ContactItemComponent,
    AvatarPanelComponent,
    ChatPanelComponent,
    ContactsPanelComponent,
    ChatQuestionsComponent,
    ReportComponent,
    ChatAnswerComponent,
    AvatarFooterComponent,
    ChatCurrentQuestionComponent,
    ChatCurrentQuestionRatingComponent,
    ChatCurrentAnswerComponent,
    MiKonsistentTabComponent,
    MiInkonsistentTabComponent,
    GesprachsphasenTabComponent,
    VeranderungsbereitschaftTabComponent,
    TotalTabComponent,
    ReportBoxComponent,
    ContactHeaderComponent,
    PercentageColourComponent,
    PercentageMiInkonsistentesColourComponent,
    TotalPercentageComponent,
    VergleichseiteTabComponent,
    RindexChartComponent,
    ExpiredLayoutComponent,
    ExpiredSessionPanelComponent
  ],
    imports: [
        CommonModule,
        UserRoutingModule,
        SharedModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule { }
