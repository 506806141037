import { TTSItems } from '../dto/ttsItems';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConversationTTSRepository {

  constructor(private httpClient: HttpClient) {}

  sendTTSItems(ttsItem: TTSItems): Observable<any> {
    const url: string = environment.domainUrl + environment.ttsEndpoints.sendItemsToConvert;
    const body: string = JSON.stringify(ttsItem);
    return this.httpClient.post(url, body);
  }
}
