<div class="container text-page">

    <div class="row">
        <div class="col-md-12">
            <h1>Impressum</h1>

            <p><strong>Angaben gemäß § 5 TMG</strong><br/>
            Technische Universität Braunschweig,<br/>
            Institut für Psychologie, Lehrstuhl für Arbeits-, Organisations- und Sozialpsychologie<br/>
            Spielmannstraße 19<br/>
            38106 Braunschweig<br/>
            Vertreten durch:<br/>
            Univ.-Prof. Dr. Simone Kauffeld<br/>
            </p>

            <p><strong>Kontakt</strong><br/>
            Telefon: +49-531-391-2563<br/>
            Telefax: +49-531-391-8173<br/>
            E-Mail:<br/>
            <a href="mailto:mi-train@tu-braunschweig.de">mi-train@tu-braunschweig.de</a><br/>
            </p>

            <p><strong>Identifikationsnummer</strong><br/>
            Umsatzsteuer-ID: DE 152330858<br/>
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV<br/>
            Simone Kauffeld, Anschrift wie oben<br/>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br/>
            </p>

            <p><strong>Haftung für Inhalte</strong><br/>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
            umgehend entfernen.<br/>
            </p>

            <p><strong>Urheberrecht</strong><br/>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Darüber hinaus gehende Downloads und Kopien dieser Seite sind nicht gestattet.<br/>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br/>
            </p>

        </div>
    </div>
</div>
