import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../../services/report/report.service';
import {ReportMiInkonsistenTabService} from '../../../../services/report/report-mi-inkonsisten-tab.service';

@Component({
  selector: 'app-report-mi-inkonsistent-tab',
  templateUrl: './mi-inkonsistent-tab.component.html',
  styleUrls: ['./mi-inkonsistent-tab.component.scss']
})
export class MiInkonsistentTabComponent implements OnInit {
  showFirstSection: boolean = false;
  showSecondSection: boolean = false;
  showTotalSection: boolean = false;
  percentages: number[];
  comments: string[];

  constructor(
      private reportService: ReportService,
      private reportMiInkonsistenTabService: ReportMiInkonsistenTabService
  ) { }

  ngOnInit(): void {
    this.initValues();
    this.loadReports();
  }

  initValues(): void {
    this.percentages = [0, 0, 0, 0];
    this.comments = ['', '', '', ''];
  }

  loadReports(): void {
    this.reportService.onReportResults().subscribe(results => {
      if (results.length) {
        this.setFirstSection();
        this.setSecondSection();
        this.setTotalSection();
      }
    });
  }

  setFirstSection(): void {
    const tagStat = this.reportService.getTagStats(['r']);
    this.percentages[0] = tagStat.percentage;
    this.comments[0] = this.reportMiInkonsistenTabService.getCommentForFirstSection(tagStat.percentage);

    this.showFirstSection = true;
  }

  setSecondSection(): void {
    const tagStat = this.reportService.getTagStats(['t']);
    this.percentages[1] = tagStat.percentage;
    this.comments[1] = this.reportMiInkonsistenTabService.getCommentForSecondSection(tagStat.percentage);

    this.showSecondSection = true;
  }

  setTotalSection(): void {
    const tagStat = this.reportService.getTagStats(['r', 't']);
    this.percentages[2] = tagStat.percentage;

    this.showTotalSection = true;
  }

}
