import { Injectable } from '@angular/core';
import {Conversation} from '../../shared/models/conversation';
import {ResultRepositoryService} from '../../shared/services/result-repository.service';
import {AuthService} from '../../core/services/auth.service';
import {Result} from '../../shared/models/result';
import {ConversationItem} from '../../shared/models/conversationItem';

@Injectable({
    providedIn: 'root'
})
export class ResultService {

    constructor(
        private authService: AuthService,
        private resultRepository: ResultRepositoryService
    ) { }

    async getResultForCurrentUserAndConversationId(conversationId: string): Promise<ConversationItem[]> {
        const user = await this.authService.getCurrentUser();
        const results = await this.resultRepository.getResultForUserAndConversationId(user.uid, conversationId).toPromise();
        return results;
    }

    /* Get historical results (only the items not the data) */
    async getHistoricalResultForCurrentUserAndConversationId(conversationId: string): Promise<any> {
        const user = await this.authService.getCurrentUser();
        const results = await this.resultRepository.getHistoricalResultForUserAndConversationId(user.uid, conversationId).toPromise();
        return results;
    }

    /**
     * Upload a result with conversationItems
     */
    async uploadConversationResults(conversationId: string, conversationItems: ConversationItem[]): Promise<void> {
        const user = await this.authService.getCurrentUser();
        await this.resultRepository.uploadConversationResult(user.uid, conversationId, conversationItems);
        //save conversation items in the historical collection
        await this.resultRepository.uploadHistoricalConversationResult(user.uid, conversationId, conversationItems);
    }

    async deleteLastHistoricalResult(conversationId: string, fieldToDelete: string){
        const user = await this.authService.getCurrentUser();
        await this.resultRepository.deleteOldHistoricalResult(user.uid, conversationId, fieldToDelete);
    }

}
