import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-current-question',
  templateUrl: './chat-current-question.component.html',
  styleUrls: ['./chat-current-question.component.scss']
})
export class ChatCurrentQuestionComponent implements OnInit {
  @Input() questionText: string;
  @Input() questionRatingValue: string;

  constructor() { }

  ngOnInit(): void {
  }

}
