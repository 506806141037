import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../../services/report/report.service';

@Component({
    selector: 'app-report-veranderungsbereitschaft-tab',
    templateUrl: './veranderungsbereitschaft-tab.component.html',
    styleUrls: ['./veranderungsbereitschaft-tab.component.scss']
})
export class VeranderungsbereitschaftTabComponent implements OnInit {
    rIndex: number;
    rIndexData: number[];
    motivation: number;
    summeChangeTalk: number;
    summeSustainTalk: number;

    constructor(
        private reportService: ReportService
    ) {
    }

    ngOnInit(): void {
        this.loadReports();
    }

    loadReports(): void {
        this.reportService.onReportResults().subscribe(results => {
            if (results.length) {
                this.setRIndexData();
                this.setMotivation();
            }
        });
    }

    setRIndexData(): void {
        this.rIndexData = this.reportService.getRIndexValuesFromResults();
        this.rIndex = this.rIndexData[this.rIndexData.length - 1];
    }

    setMotivation(): void {
        this.summeChangeTalk = this.reportService.getSummeChangeTalk();
        this.summeSustainTalk = this.reportService.getSummeSustainTalk();
        this.motivation = this.reportService.getMotivation();
    }

    get classMotivation(): string {
        if (this.motivation >= 5) {
            return 'darkGreen';
        } else if (this.motivation >= 2) {
            return 'lightGreen';
        } else if (this.motivation >= -1) {
            return 'yellow';
        } else if (this.motivation >= -4) {
            return 'orange';
        } else {
            return 'red';
        }
    }

}
