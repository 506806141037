import { CodeDetails } from '../../shared/models/codeDetails';
import { CodeToStore } from '../../shared/models/codeToStore';

export class CodeDetailsToCodeToStoreMapper {

  static map(
    codeDetails: CodeDetails,
    trainerId: string
  ): CodeToStore {

    const result: CodeToStore = new CodeToStore();
    result.activationCode = codeDetails.activationCode;
    result.expirationDate = Date.UTC(codeDetails.expirationDate.getFullYear(), codeDetails.expirationDate.getMonth(), codeDetails.expirationDate.getDate(),21);
    result.trainerId = trainerId;
    result.userId = '';
    
    return result;
  }
}
