import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading = new BehaviorSubject<boolean>(false);

  constructor() { }

  onLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  showLoading(): void {
    this.loading.next(true);
  }

  hideLoading(): void {
    this.loading.next(false);
  }
}
