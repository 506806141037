import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../../services/report/report.service';
import {
  FirstSectionData, FourthSectionData,
  ReportGesprachsphasenTabService,
  SecondSectionData, ThirdSectionData
} from '../../../../services/report/report-gesprachsphasen-tab.service';

@Component({
  selector: 'app-report-gesprachsphasen-tab',
  templateUrl: './gesprachsphasen-tab.component.html',
  styleUrls: ['./gesprachsphasen-tab.component.scss']
})
export class GesprachsphasenTabComponent implements OnInit {
  firstSectionData: FirstSectionData;
  secondSectionData: SecondSectionData;
  thirdSectionData: ThirdSectionData;
  fourthSectionData: FourthSectionData;
  totalPercentage: number;

  constructor(
      private reportService: ReportService,
      private reportGesprachsphasenTabService: ReportGesprachsphasenTabService
  ) { }

  ngOnInit(): void {
    this.loadReports();
  }

  loadReports(): void {
    this.reportService.onReportResults().subscribe(results => {
      if (results.length) {
        this.setFirstSection();
        this.setSecondSection();
        this.setThirdSection();
        this.setFourthSection();
        this.setTotalSection();
      }
    });
  }

  setFirstSection(): void {
    const timesMark1 = this.reportService.getTimesForMarkAndPhase('*1', 'Beziehungsaufbau');
    const timesMark2 = this.reportService.getTimesForMarkAndPhase('*2', 'Beziehungsaufbau');
    this.firstSectionData = this.reportGesprachsphasenTabService.getCommentForFirstSection(timesMark1, timesMark2);
  }

  setSecondSection(): void {
    const timesMark1 = this.reportService.getTimesForMarkAndPhase('*1', 'Fokussierung');
    this.secondSectionData = this.reportGesprachsphasenTabService.getCommentForSecondSection(timesMark1);
  }

  setThirdSection(): void {
    const timesMark1 = this.reportService.getTimesForMarkAndPhase('*1', 'Evokation');
    const timesMark2 = this.reportService.getTimesForMarkAndPhase('*2', 'Evokation');
    const timesMark3 = this.reportService.getTimesForMarkAndPhase('*3', 'Evokation');
    const timesMark4 = this.reportService.getTimesForMarkAndPhase('*4', 'Evokation');
    this.thirdSectionData = this.reportGesprachsphasenTabService.getCommentForThirdSection(timesMark1, timesMark2, timesMark3, timesMark4);
  }

  setFourthSection(): void {
    const timesMark1 = this.reportService.getTimesForMarkAndPhase('*1', 'Planung');
    const timesMark2 = this.reportService.getTimesForMarkAndPhase('*2', 'Planung');
    this.fourthSectionData = this.reportGesprachsphasenTabService.getCommentForFourthSection(timesMark1, timesMark2);
  }

  setTotalSection(): void {
    this.totalPercentage = this.reportService.getPercentageOfMark();
  }

}
