import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ConversationRepositoryService} from '../../shared/services/conversation-repository.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {Conversation} from '../../shared/models/conversation';
import {ConversationFlowRepositoryService} from '../../shared/services/conversation-flow-repository.service';
import {ResultRepositoryService} from '../../shared/services/result-repository.service';

@Injectable({
    providedIn: 'root'
})
export class DeleteConversationService {

    constructor(
        private firestore: AngularFirestore,
        private storage: AngularFireStorage,
        private conversationRepository: ConversationRepositoryService,
        private conversationFlowRepository: ConversationFlowRepositoryService,
        private resultRepository: ResultRepositoryService
    ) { }

    async deleteConversation(conversation: Conversation) {
        // Delete conversation images
        if (conversation.image1) {
            this.deleteImage(conversation.image1);
        }
        if (conversation.image2) {
            this.deleteImage(conversation.image2);
        }
        if (conversation.image3) {
            this.deleteImage(conversation.image3);
        }
        if (conversation.image4) {
            this.deleteImage(conversation.image4);
        }
        if (conversation.image5) {
            this.deleteImage(conversation.image5);
        }

        // Delete conversation flow
        await this.conversationFlowRepository.deleteConversationFlow(conversation.id);

        // Delete conversation results
        await this.resultRepository.deleteConversationResults(conversation.id);

        // Delete conversation
        await this.conversationRepository.deleteConversation(conversation.id)
            .then(() => {
               return true;
            })
            .catch((error) => {
                return false;
            });
    }

    private deleteImage(url: string): void {
        this.storage.storage.refFromURL(url).delete();
    }

}
