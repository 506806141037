<div class="container text-page">

    <div class="row">
        <div class="col-md-12">
            <h1>Datenschutzerklärung MI-Tool-Nutzer </h1>

            <p><strong>1. Verantwortung für die Datenverarbeitung und Kontaktdaten:</strong><br/>
                Verantwortlich für die Datenverarbeitung bei der Nutzung des MI-Tools ist der Lehrstuhl für Arbeits-, Organisations- und Sozialpsychologie, der TU-Braunschweig, Spielmannstrasse 19, 38106 Braunschweig, Ansprechpartnerin Prof. Dr. Simone Kauffeld. E-Mail: s.kauffeld@tu-braunschweig.de<br/>
                Den betrieblichen Datenschutzbeauftragten erreichen Sie per E-Mail unter datenschutz@tu-braunschweig.de. </p>

            <p><strong>2. Verarbeitungen, Rechtsgrundlage, Empfänger</strong><br/>
                <strong>2.1. Teilnehmenden-Code:</strong> Um das Tool nutzen zu können, müssen Sie über einen Nutzer-Code verfügen. Wenden Sie sich zu diesem Zweck bitte per Mail an mi-train@tu-braunschweig.de. Diese Verarbeitung dieses Codes ist für die Nutzung des Systems notwendig und beruht auf Art.6 Abs.1 (a) DSGVO.<br/>
                <strong>2.2. Registrierungsdaten:</strong> Um das MI-Tool zu nutzen, ist eine Registrierung notwendig. Die dazu erforderlichen Daten, bestehend aus Name, E-Mailadresse und Passwort werden gemeinsam mit Ihrem Nutzer-Code und mit den Ergebnissen ihrer MI-Online Trainingseinheiten gespeichert. Die Nutzung des Systems erfolgt freiwillig und Sie können jederzeit eine Löschung beantragen. Die Speicherung erfolgt auf Ihrer Einwilligung welche Sie bei der Registrierung zur System-Nutzung erteilen (gemäß Art.6 Abs. 1 (a) DSGVO). Ihre Daten können durch die beteiligten Wissenschaftler des Lehrstuhls für Arbeits- und Organisationspsychologie eingesehen werden; darüber hinaus ist möglich, dass im Rahmen der Systembetreuung auch der weisungsgebundene Dienstleister Daten zur Kenntnis nehmen kann. Ihre vom System ermittelten Trainingsergebnisse werden jeweils nur in der aktuellen Version gespeichert. Ältere Trainingsergebnisse werden überschrieben. Auch nach Löschung Ihres Accounts werden ihre letzten Trainingsdaten zum Zweck der Systemoptimierung und der MI-Forschung anonymisiert gespeichert. Die anonymisierten Daten werden im Sinne der guten wissenschaftlichen Praxis (Gollwitzer et al. 2016, DFG-Leitlinie) frühestens nach 10 Jahren gelöscht. Die DFG-Leitlinie sieht es vor, dass Forschungsdaten archiviert und für mindestens 10 Jahre aufbewahrt werden.</p>

            <p><strong>3. Ihre Rechte:</strong> Sie haben das Recht auf Auskunft über die, zu Ihrer Person, gespeicherten Daten und das Recht auf Berichtigung Ihrer Daten. Außerdem haben Sie, unter den gesetzlichen Voraussetzungen, das Recht auf Übertragung und Löschung der Daten sowie auf Einschränkung der Verarbeitung (gemäß Art. 15-21 DSGVO). Sollten Sie Fragen in Zusammenhang mit dem Datenschutz haben oder von Ihren Rechten Gebrauch machen wollen, wenden Sie sich bitte an den Verantwortlichen (siehe 1). </p>

            <p><strong>Widerruf bei Einwilligung in die Verarbeitung und Forschungseinverständnis:</strong> Eine erteilte Einwilligung, gemäß Art. 6 Abs. 1 (a) DSGVO, können Sie jederzeit bis zum Ende der Datenerhebung und somit vor erfolgter Anonymisierung widerrufen, ohne dass hierdurch die Rechtmäßigkeit der Verarbeitung vor dem erfolgten Widerruf beeinträchtigt wird.<br/>
                Bei Beschwerden über die Datenverarbeitung können Sie sich jederzeit an den betrieblichen Datenschutzbeauftragten wenden, darüber hinaus haben Sie jederzeit ein Beschwerderecht bei jeder Datenschutzaufsichtsbehörde; die für den Lehrstuhl für Arbeits-, Organisations- und Sozialpsychologie, der TU-Braunschweig zuständige Aufsichtsbehörde ist die Landesbeauftragte für den Datenschutz Niedersachsen, Prinzenstraße 5, 30159 Hannover, poststelle@lfd.niedersachsen.de. </p>

            <p><strong>4. Aktualität und Änderung dieser Datenschutzerklärung</strong><br/>
                Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Dezember 2020. Aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben oder Erweiterung unseres Angebots kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann immer auf dieser Seite von Ihnen abgerufen und ausgedruckt werden: <a href="/datenschutz">https://demo-mi-train.aundo-braunschweig.de/datenschutz</a>  </p>

        </div>
    </div>
</div>
