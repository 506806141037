<div class="questions">
    <div class="phases">
        <div>
            <span class="phase" [class.active]="currentPhase == 'Beziehungsaufbau'">Beziehungsaufbau</span> -
            <span class="phase" [class.active]="currentPhase == 'Fokussierung'">Fokussierung</span> -
            <span class="phase" [class.active]="currentPhase == 'Evokation'">Evokation</span> -
            <span class="phase" [class.active]="currentPhase == 'Plan'">Planung</span>
        </div>
    </div>
    <div class="questions-list">
        <div class="question" [class.selected]="question == selectedQuestion" *ngFor="let question of questions" (click)="selectQuestion(question)">
            {{ question.text }}
        </div>
    </div>
    <div class="actions">
        <button mat-button class="btn-send" [disabled]="!selectedQuestion" (click)="sendQuestion()">{{ 'CONVERSATION.Send' | translate }}</button>
    </div>
</div>
