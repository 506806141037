<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="header"> Name des Sprechpartners </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="topic">
            <th mat-header-cell *matHeaderCellDef class="header"> Thema </th>
            <td mat-cell *matCellDef="let element"> {{element.topic}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="header"></th>
            <td mat-cell *matCellDef="let element">
                <button (click)="editConversation(element)" mat-icon-button title="Editieren"><mat-icon>create</mat-icon></button>
                <button (click)="deleteConversation(element)" mat-icon-button title="Löschen"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
