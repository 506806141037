import { TrainerDto } from '../dto/trainerDto';

export class Trainer {
    id: string;
    name: string;
    description: string;

    constructor(id: string, data: TrainerDto) {
        this.id = id;
        this.name = data.name;
        this.description = data.description;
    }

}