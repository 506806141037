import { Injectable } from '@angular/core';

const VOICE_LANG = 'de-DE';
const VOICE_PITCH = 1.5;
const VOICE_RATE = 1;
const VOICE_VOLUME = 0.8;

const VOICE_MUTE_KEY = 'mute';

@Injectable({
  providedIn: 'root'
})
export class SpeechService {
  currentText: string;

  constructor() {
    // preload the voices
    if (this.isBrowserSupported()) {
      speechSynthesis.getVoices();
    }
  }

  private getVoice(): SpeechSynthesisVoice {
    return speechSynthesis.getVoices().filter((v) => v.lang === VOICE_LANG)[0];
  }

  /**
   * Get if the browser supports the Web Speech API and there's a voice available
   */
  isBrowserSupported(): boolean {
    return 'speechSynthesis' in window && this.getVoice() !== undefined;
  }

  /**
   * Speak a text
   */
  speak(text: string): void {
    if (!this.isBrowserSupported()) {
      return;
    }

    this.stopSpeech();

    this.currentText = text;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = this.getVoice();
    utterance.pitch = VOICE_PITCH;
    utterance.rate = VOICE_RATE;
    utterance.volume = VOICE_VOLUME;

    if (utterance.voice && !this.isMuted()) {
      speechSynthesis.speak(utterance);
    }
  }

  /**
   * Cancel all the speaking
   */
  cancel(): void {
    this.currentText = null;
    this.stopSpeech();
  }

  /**
   * Stop the current speaking
   */
  stopSpeech(): void {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
    }
  }

  /**
   * Check if it's muted
   */
  isMuted(): boolean {
    return localStorage.getItem(VOICE_MUTE_KEY) === 'true';
  }

  /**
   * Toggle muted
   */
  toggleMute(): boolean {
    const toggledValue = !(localStorage.getItem(VOICE_MUTE_KEY) === 'true');
    localStorage.setItem(VOICE_MUTE_KEY, String(toggledValue));

    if (toggledValue) {
      this.stopSpeech();
    } else {
      this.speak(this.currentText);
    }

    return toggledValue;
  }

}
