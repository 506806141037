import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

const COLLECTION_CODES = 'codes';

@Injectable({
    providedIn: 'root'
})
export class RegistrationRepository {

    constructor(
        private firestore: AngularFirestore
    ) {
    }

    public setUserToUniqueCode(codeId: string, userIdToSet: string): void {
       this.firestore.collection(COLLECTION_CODES)
       .doc(codeId)
       .update({userId: userIdToSet}) 
    }

}