<div class="conversations-list">

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
          <ng-container matColumnDef="name">
            <th width="280px" mat-header-cell *matHeaderCellDef class="header">Name</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
  
          <ng-container matColumnDef="codes">
            <th width="260px" mat-header-cell *matHeaderCellDef class="header">Codes</th>
            <td mat-cell *matCellDef="let element"> {{element.activatedCodes}}/{{element.totalCodes}}</td>
          </ng-container>

          <ng-container matColumnDef="expirationDate">
            <th mat-header-cell *matHeaderCellDef class="header">Gultigkeit</th>
            <td mat-cell *matCellDef="let element"> <span *ngIf="element.showExpirationDate">{{element.codes[0].expirationDate}}</span></td>
          </ng-container>
  
          <ng-container matColumnDef="actions">
            <th width="110px" mat-header-cell *matHeaderCellDef class="header"></th>
            <td mat-cell *matCellDef="let element">
                <button (click)="editTrainer(element)" mat-icon-button title="Editieren"><mat-icon>create</mat-icon></button>
                <button (click)="deleteTrainer(element)" mat-icon-button title="Löschen"><mat-icon>delete</mat-icon></button>
                <button mat-icon-button (click)="expandedElement = expandedElement === element ? null : element">
                  <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
          </ng-container>
          
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-diagram">
                    <div *ngFor="let code of element.codes">
                        <div class="codeContainer">
                          <div><mat-icon *ngIf="code.activated" class="activatedCodeIcon">done</mat-icon></div>
                          <div [ngClass]="(code.activated) ? 'lineThrough' : ''">{{code.activationCode}}</div>
                          <div>{{code.expirationDate}}</div>
                        </div>
                    </div>
                    <button mat-button (click)="copyAllCodes(element)" class="copyCodesButton"><mat-icon>file_copy</mat-icon>Copy all codes</button>
                  </div>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              >
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>

</div>
