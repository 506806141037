<div class='container'>
    <div id="veranderungsbereitschaftTab">
        <h2 class="tab-title">Veränderungsbereitschaft</h2>

        <div class="chart-container">
            <app-rindex-chart *ngIf="rIndexData" [data]="rIndexData"></app-rindex-chart>
        </div>

        <div class="data-container">
            <div class="data">Summe Change Talk<sup>[1]</sup>: <span>{{ summeChangeTalk }}</span></div>
            <div class="data">Summe Sustain Talk<sup>[2]</sup>: <span>{{ summeSustainTalk }}</span></div>
            <div class="data">% Motivation<sup>[3]</sup>: <app-percentage-colour [number]="motivation" [showSymbol]="false"></app-percentage-colour></div>
            <div class="data">R-Index Final Peak<sup>[4]</sup>: <span class="motivation" [ngClass]="classMotivation">{{ rIndex }}</span></div>
        </div>

        <div class="references">
            <p><sup>[1]</sup> Aussagen des Gesprächspartners, die Veränderungsbereitschaft signalisieren (Gründe für eine Veränderung, Wünsche, Fähigkeiten an eine Veränderung etc.)</p>

            <p><sup>[2]</sup> Aussagen des Gesprächspartners, die für die Beibehaltung des Status Quo sprechen oder gegen eine Veränderung sprechen (Gründe gegen eine Veränderung, fehlende Fähigkeiten zu einer Veränderung etc.)</p>

            <p><sup>[3]</sup> [(Change Talk )/(Change Talk+Sustain Talk)]</p>

            <p><sup>[4]</sup> Change Talk + Sustain Talk</p>

            <p>Details zur Berechnung des R-Index finden sich in:</p>

            <p>Klonek, F.E. Paulsen, H. & Kauffeld, S. (2015). They meet, they talk ... but nothing changes: Meetings as a focal context for studying change processes. In J. A. Allen, N. Lehmann-Willenbrock & S. G. Rogelberg (Eds.), The Cambridge Handbook of Meeting Science. New York, NY: Cambridge University Press.</p>
        </div>

    </div>
</div>
