import { ConversationTTSService } from './../../shared/services/conversation-tts-service.service';
import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ConversationRepositoryService} from '../../shared/services/conversation-repository.service';
import {ConversationDto} from '../../shared/dto/conversationDto';
import {AngularFireStorage} from '@angular/fire/storage';
import {Conversation} from '../../shared/models/conversation';
import {ConversationItem} from '../../shared/models/conversationItem';
import {ConversationFileParserService} from './conversation-file-parser.service';
import {ConversationFlowRepositoryService} from '../../shared/services/conversation-flow-repository.service';

@Injectable({
    providedIn: 'root'
})
export class CreateEditConversationService {

    constructor(
        private firestore: AngularFirestore,
        private storage: AngularFireStorage,
        private conversationFileParserService: ConversationFileParserService,
        private conversationRepository: ConversationRepositoryService,
        private conversationFlowRepository: ConversationFlowRepositoryService,
        private conversationTTSService: ConversationTTSService
    ) { }

    /**
     * Create a conversation and upload the image files
     */
    async createConversation(data, conversationItems: ConversationItem[]): Promise<string> {
        const conversationId = this.conversationRepository.getNewId();
        const imagePath = '/conversations/avatars/' + conversationId + '/';

        await this.uploadImage(imagePath + 'image_1', data.image1.files[0])
            .then((url) => data.image1 = url );
        await this.uploadImage(imagePath + 'image_2', data.image2.files[0])
            .then((url) => data.image2 = url );
        await this.uploadImage(imagePath + 'image_3', data.image3.files[0])
            .then((url) => data.image3 = url );
        await this.uploadImage(imagePath + 'image_4', data.image4.files[0])
            .then((url) => data.image4 = url );
        await this.uploadImage(imagePath + 'image_5', data.image5.files[0])
            .then((url) => data.image5 = url );

        this.conversationFlowRepository.uploadConversationItemsToConversationFlow(conversationId, conversationItems);
        this.conversationTTSService.sendConversationTTSItems(conversationId, conversationItems, data.gender).subscribe(response => {
            
        });
        delete data.conversation;

        this.conversationRepository.createConversation(data, conversationId);

        return conversationId;
    }

    /**
     * Edit a conversation and upload the image files changed
     */
    async editConversation(conversation: Conversation, data, conversationItems?: ConversationItem[]) {
        const imagePath = '/conversations/avatars/' + conversation.id + '/';

        // check if there's a new image. If not, then we set the previous value
        if (data.image1) {
            await this.uploadImage(imagePath + 'image_1', data.image1.files[0])
                .then((url) => data.image1 = url );
        } else {
            data.image1 = conversation.image1;
        }

        if (data.image2) {
            await this.uploadImage(imagePath + 'image_2', data.image2.files[0])
                .then((url) => data.image2 = url );
        } else {
            data.image2 = conversation.image2;
        }

        if (data.image3) {
            await this.uploadImage(imagePath + 'image_3', data.image3.files[0])
                .then((url) => data.image3 = url );
        } else {
            data.image3 = conversation.image3;
        }

        if (data.image4) {
            await this.uploadImage(imagePath + 'image_4', data.image4.files[0])
                .then((url) => data.image4 = url );
        } else {
            data.image4 = conversation.image4;
        }

        if (data.image5) {
            await this.uploadImage(imagePath + 'image_5', data.image5.files[0])
                .then((url) => data.image5 = url );
        } else {
            data.image5 = conversation.image5;
        }

        if (conversationItems && conversationItems.length) {
            await this.conversationFlowRepository.deleteConversationFlow(conversation.id);
            this.conversationFlowRepository.uploadConversationItemsToConversationFlow(conversation.id, conversationItems);
            this.conversationTTSService.sendConversationTTSItems(conversation.id, conversationItems, data.gender).subscribe(response => {
                
            });
        }
        delete data.conversation;

        this.conversationRepository.editConversation(data, conversation.id);
    }

    private async uploadImage(path: string, file: any): Promise<string> {
        const imageRef = this.storage.ref(path);
        const imageTask = this.storage.upload(path, file);
        await imageTask;
        const imageURL = await imageRef.getDownloadURL().toPromise();
        return imageURL;
    }

    uploadConversationFlow(file, cbSuccess, cbError) {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            try {
                const conversationItems = this.conversationFileParserService.parse(event.target.result);
                cbSuccess(conversationItems);
            } catch (e) {
                cbError(e);
            }
        };
        fileReader.readAsText(file);
    }
}
