import { UserCodeDetails } from '../../shared/models/userCodeDetails';
import { Trainer } from '../../shared/models/trainer';
import { Code } from '../../shared/models/code';
import { TrainerDetails } from '../../shared/models/trainerDetails';
import { DateTransformService } from '../../shared/services/dateTransform.service';
import { ConversationTrainer } from '../../shared/models/conversationTrainer';

export class TrainerAndCodeToUserCodeDetailsMapper {

  static map(trainers: Array<Trainer>, codes: Array<Code>, conversationsTrainer: Array<ConversationTrainer>): Array<UserCodeDetails> {
    const result: Array<UserCodeDetails> = new Array();
    trainers.forEach( trainer => {
        let userCodeDetails: UserCodeDetails = new UserCodeDetails();
        let activatedCodes: number = 0;
        let totalCodes: number = 0;
        let showExpirationDate: boolean = false;
        userCodeDetails.id = trainer.id;
        userCodeDetails.name = trainer.name;
        userCodeDetails.description = trainer.description;
        let trainerCodes: Array<Code> = codes.filter(element => element.trainerId === trainer.id);
        userCodeDetails.conversationsTrainer = conversationsTrainer.filter(element => element.trainerId === trainer.id);
        trainerCodes.forEach( code => {
            let trainerDetail: TrainerDetails = new TrainerDetails();
            trainerDetail.id = code.id;
            trainerDetail.activationCode = code.activationCode;
            const date: Date = DateTransformService.timeStampLongFormatToDate(code.expirationDate);
            trainerDetail.expirationDate = DateTransformService.dateToString(date);
            trainerDetail.expirationDateTimestamp = code.expirationDate;
            trainerDetail.trainerId = code.trainerId;
            trainerDetail.userId = code.userId;
            userCodeDetails.codes.push(trainerDetail);
            if (code.userId != '') {
                trainerDetail.activated = true;
                activatedCodes++;
            }
            totalCodes++;
        });

        if (trainerCodes.length > 0) {
            if ((trainerCodes.filter(element => element.expirationDate === trainerCodes[0].expirationDate).length === trainerCodes.length)) {
                showExpirationDate = true;
            }
        }

        userCodeDetails.activatedCodes = activatedCodes;
        userCodeDetails.totalCodes = totalCodes;
        userCodeDetails.showExpirationDate = showExpirationDate;

        result.push(userCodeDetails);
    });

    return result;
  }
}
