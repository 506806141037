import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NewConversationDialogComponent} from '../dialog-new-conversation/new-conversation-dialog.component';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {

  constructor(
      private createDialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  newConversation(): void {
    const dialogRef = this.createDialog.open(NewConversationDialogComponent);

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}

