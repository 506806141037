import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import { Trainer } from '../models/trainer';

@Injectable({
    providedIn: 'root',
})
export class TrainerAdapter implements Adapter<Trainer> {
    adapt(item: any): Trainer {
        return new Trainer(
            item.payload.doc ? item.payload.doc.id : item.payload.id,
            item.payload.doc ? item.payload.doc.data() : item.payload.data()
        );
    }
}