import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expired-layout',
  templateUrl: './expired-layout.component.html',
  styleUrls: ['./expired-layout.component.scss']
})
export class ExpiredLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
