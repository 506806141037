<div class="code-form">
    <form (ngSubmit)="submitForm()"  [formGroup]="codeForm"  novalidate>

        <div class="field">
            <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name" #inputName>
                <mat-error class="error-message">
                    <span *ngIf="codeForm.get('name').hasError('required')">Pflichtfeld</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="field">
            <mat-form-field appearance="fill">
                <mat-label>Bezeichnung</mat-label>
                <input matInput type="text" formControlName="description">
                <mat-error class="error-message">
                    <span *ngIf="codeForm.get('description').hasError('required')">Pflichtfeld</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="boxes-area">
            <div class="converstations-area">
                <div class="sectionText">Konversation</div>
                <div class="conversationList">
                    <div *ngFor="let item of conversationList">
                        <app-conversation-list-item
                            (updateCheckbox)="updateCheckbox($event, item)"
                            conversationName='{{ item.name }}'
                            checkedOption="{{ item.checkedOption }}">
                        </app-conversation-list-item>
                    </div>
                </div>
            </div>
            <div class="codes-area">
                <div class="sectionText">Codes</div>
                <div class="createCodeButtonArea">
                    <button type="button" mat-raised-button color="primary" (click)="addNewCode()">Create new code</button>
                </div>
                <div class="conversationList">
                    <div *ngFor="let item of codeList; index as i">
                        <app-code-list-item
                            (optionChanged)="updateExpirationDate($event, i)"
                            (codeToDelete)="deleteCodeFromList(item)"
                            codeId="{{ item.activationCode }}"
                            [editExpirationDate] = "editMode ? item.expirationDate : null">
                        </app-code-list-item>
                    </div>
                </div>
                <mat-error class="error-message-custom">
                    <span *ngIf="showExpirationDateErrorValidation">Alle Codes müssen mit einem Verfallsdatum versehen sein</span>
                </mat-error>
            </div>
        </div>


        <div class="buttons">
            <button type="button" mat-raised-button (click)="onCancel()">Abbrechen</button>
            <button type="submit" [disabled]="loading" mat-raised-button color="primary">{{ saveButtonText }}</button>
        </div>

    </form>
</div>
