export class TrainerDetails {
    id: string;
    activationCode: string;
    expirationDate: string;
    expirationDateTimestamp: number;
    trainerId: string;
    userId: string;
    activated: boolean = false;
    showExpirationDate: boolean;

    constructor() {}
}