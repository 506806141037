import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserCodeDetails } from '../../../shared/models/userCodeDetails';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Code } from '../../../shared/models/code';
import { TrainerRepositoryService } from '../../../shared/services/trainer-repository.service';
import { CodeRepositoryService } from '../../../shared/services/code-repository.service';
import { Trainer } from '../../../shared/models/trainer';
import { TrainerAndCodeToUserCodeDetailsMapper } from '../../mappers/trainerAndCodeToUserCodeDetailsMapper';
import { CreateCodeDialogComponent } from '../codes/create-code-dialog/create-code-dialog.component';
import { TrainerToStore } from '../../../shared/models/TrainerToStore';
import { ConversationTrainerRepositoryService } from '../../../shared/services/conversationTrainer-repository.service';
import { ConversationTrainer } from '../../../shared/models/conversationTrainer';

@Component({
  selector: 'app-code-list-table',
  templateUrl: './code-list-table.component.html',
  styleUrls: ['./code-list-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CodeListTableComponent implements OnInit {

  columnsToDisplay: string[] = ['name', 'codes', 'expirationDate', 'actions'];
  codes: Code[];
  trainers: Trainer[];
  userCodeDetails: Array<UserCodeDetails> = new Array();
  conversationTrainerData: Array<ConversationTrainer> = new Array();
  dataSource: MatTableDataSource<UserCodeDetails>;
  expandedElement: UserCodeDetails | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private toastr: ToastrService,
    private trainerRepositoryService: TrainerRepositoryService,
    private codeRepositoryService: CodeRepositoryService,
    private ConversationTrainerService: ConversationTrainerRepositoryService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadTrainers();
  }

  loadTrainers(): void {
    this.trainerRepositoryService.getTrainers().subscribe(
      (trainers) => {
        this.trainers = trainers;
        this.loadCodes();
      },
      (error) => {
          this.toastr.error(error);
      }
    );
  }

  loadCodes(): void {
    this.codeRepositoryService.getCodes().subscribe(
      (codes) => {
        this.codes = codes;
        this.loadConversationsTrainer();
      },
      (error) => {
          this.toastr.error(error);
      }
    );
  }

  loadConversationsTrainer(): void {
    this.ConversationTrainerService.getConversationsTrainer().subscribe(
        (conversationTrainerData) => {
            this.conversationTrainerData = conversationTrainerData;
            this.loadUserDetails();
        },
        (error) => {
            this.toastr.error(error);
        }
    );
  }

  loadUserDetails(): void {
    this.userCodeDetails = TrainerAndCodeToUserCodeDetailsMapper.map(this.trainers, this.codes, this.conversationTrainerData);
    this.dataSource = new MatTableDataSource<UserCodeDetails>(this.userCodeDetails);
    this.dataSource.paginator = this.paginator;
  }

  createCode(): void {
    const dialogRef = this.dialog.open(CreateCodeDialogComponent, {
      data: {
        editMode: false,
        modalTitle: 'Neue*r Kund*in',
        saveButtonText: 'Erstellen',
        userCodeDetails: new UserCodeDetails()
      },
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  editTrainer(userCodeDetails: UserCodeDetails): void {
    const dialogRef = this.dialog.open(CreateCodeDialogComponent, {
      data: {
        editMode: true,
        modalTitle: 'Edit Kunde',
        saveButtonText: 'Speichern',
        userCodeDetails: userCodeDetails
      },
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  deleteTrainer(userCodeDetails: UserCodeDetails): void {
    // const dialogRef = this.dialog.open(DeleteUserCodeDetailsDialogComponent, {
    //     data: userCodeDetails
    // });
  }

  copyAllCodes(userCodeDetails: UserCodeDetails) : void {
    let allCodesToCopy: string = '';
    userCodeDetails.codes.forEach(element => {
      allCodesToCopy += element.activationCode + '\n';
    });
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    // selBox.style.whiteSpace = 'pre-wrap';
    selBox.value = allCodesToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
