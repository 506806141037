import { Injectable } from '@angular/core';
import {ConversationItem} from '../../shared/models/conversationItem';

@Injectable({
    providedIn: 'root'
})
export class ConversationFileParserService {

    constructor(
    ) { }

    parse(htmlData: any): ConversationItem[] {
        const conversationItems = [];
        const domParser = new DOMParser();
        const html = domParser.parseFromString(htmlData, 'text/html');
        const passagedata = html.getElementsByTagName('tw-passagedata');
        const nexts = {};

        if (!passagedata.length) {
            throw new DOMException('The file does not containt any question');
        }

        // First set the data for each conversation item without the nexts
        for (let i = 0; i < passagedata.length; i++) {
            const data = passagedata[i];
            const conversationItem = new ConversationItem();

            // Set the ID of the conversation item
            if (!data.attributes['name'] || data.attributes['name'].value.trim() == '') {
                throw new DOMException('The attribute name does not exists');
            }

            conversationItem.id = data.attributes['name'].value.trim();
            nexts[conversationItem.id] = [];

            // Set the text
            const contents = data.innerHTML.split('\n').filter(elto => elto.trim() !== '');

            if (!contents.length) {
                throw new DOMException('The content is empty');
            }

            conversationItem.text = contents[0];

            // Save the nexts IDs for search later
            for (let i = 1; i < contents.length; i++) {
                nexts[conversationItem.id].push(contents[i].trim().replace('[[', '').replace(']]', ''));
            }

            // Set the tags
            const tags = data.attributes['tags'].value.split(' ');
            conversationItem.kind = tags[0];
            conversationItem.phase = tags[1];
            conversationItem.rating = tags[2];

            if (tags[3]) {
                conversationItem.methods.push(tags[3]);
            }

            if (tags[4]) {
                if (tags[4].indexOf('*') === 0) {
                    conversationItem.mark = tags[4];
                } else {
                    conversationItem.methods.push(tags[4]);
                }
            }

            if (tags[5]) {
                conversationItem.mark = tags[5];
            }

            conversationItems.push(conversationItem);
        }

        // Assign the nexts conversation item for each conversation item
        for (const conversationItem of conversationItems) {
            for (const nextId of nexts[conversationItem.id]) {
                const nextConversationItem = this.getConversationItemById(conversationItems, nextId);
                if (nextConversationItem) {
                    // Check a Agent item is followed by a Rezipient item and the same in reverse
                    if (conversationItem.kind === nextConversationItem.kind) {
                        throw new DOMException(
                            `Item ${conversationItem.id}. A item ${nextConversationItem.kind} can't follows a item ${conversationItem.kind}`
                        );
                    }

                    const clonedNextConversationItem = new ConversationItem();
                    Object.assign(clonedNextConversationItem, nextConversationItem);
                    if (clonedNextConversationItem.kind === 'Agent') {
                        // add the id of the answer
                        clonedNextConversationItem.nexts = nexts[clonedNextConversationItem.id].map(
                            id => {
                                const next = new ConversationItem();
                                next.id = id;
                                return next;
                            }
                        );
                    } else {
                        clonedNextConversationItem.nexts = [];
                    }
                    conversationItem.nexts.push(clonedNextConversationItem);
                }
            }
        }

        return conversationItems;
    }

    private getConversationItemById(conversationItems: ConversationItem[], conversationId: string): ConversationItem {
        for (const conversationItem of conversationItems) {
            if (conversationItem.id === conversationId) {
                return conversationItem;
            }
        }

        return null;
    }
}

