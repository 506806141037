import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { CodeAdapter } from '../../shared/adapter/codeAdapter';
import { Code } from '../../shared/models/code';
import { CodeToStore } from '../models/codeToStore';

const COLLECTION_CODES = 'codes';

@Injectable({
    providedIn: 'root'
})
export class CodeRepositoryService {

    constructor(
        private firestore: AngularFirestore,
        private codeAdapter: CodeAdapter
    ) {
    }

    public getCodes(): Observable<Code[]> {
        return this.firestore.collection(COLLECTION_CODES).snapshotChanges()
            .pipe(
                map((data: any[]) => data.map((item) => this.codeAdapter.adapt(item)))
            )
    }

    public createCode(codeToStore: CodeToStore): void {
        this.firestore.collection(COLLECTION_CODES).add({
            activationCode: codeToStore.activationCode,
            expirationDate: codeToStore.expirationDate,
            trainerId: codeToStore.trainerId,
            userId: codeToStore.userId
        });
    }

    public editCode(codeToStore: CodeToStore, codeId: string): void {
        this.firestore.collection(COLLECTION_CODES).doc(codeId).update({
            activationCode: codeToStore.activationCode,
            expirationDate: codeToStore.expirationDate,
            trainerId: codeToStore.trainerId,
        });
    }

    public deleteCode(codeId: string) {
        return this.firestore.collection(COLLECTION_CODES).doc(codeId).delete();
    }

}