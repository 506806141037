import {ConversationDto} from '../dto/conversationDto';

export class Conversation {
    id: string;
    image1?: string;
    image2?: string;
    image3?: string;
    image4?: string;
    image5?: string;
    name: string;
    topic: string;
    updateDate: number;
    gender: string;

    items: [];

    constructor(id: string, data: ConversationDto) {
        this.id = id;
        this.image1 = data.image1;
        this.image2 = data.image2;
        this.image3 = data.image3;
        this.image4 = data.image4;
        this.image5 = data.image5;
        this.name = data.name;
        this.topic = data.topic;
        this.updateDate = data.updateDate;
        this.gender = data.gender;
    }

}
