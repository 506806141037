import { Component, OnInit, Input } from '@angular/core';
import { Conversation } from '../../../../shared/models/conversation';
import {ChatService} from '../../../services/chat.service';
import {ConversationItem} from '../../../../shared/models/conversationItem';
import {QuestionRatingService} from '../../../services/question-rating.service';

@Component({
  selector: 'app-chat-answer',
  templateUrl: './chat-answer.component.html',
  styleUrls: ['./chat-answer.component.scss']
})
export class ChatAnswerComponent implements OnInit {

  conversation: Conversation;
  answer: ConversationItem;
  currentQuestion: ConversationItem;

  questionRatingValue: string;
  questionRatingText: string;

  constructor(
      private chatService: ChatService,
      private questionRatingService: QuestionRatingService
  ) { }

  ngOnInit(): void {
    this.loadChat();
  }

  loadChat(): void{
    this.chatService.onNewConversation().subscribe((conversation) => {
      this.conversation = conversation;
    });

    this.chatService.getAnswer().subscribe((answer) => {
      this.answer = answer;
    });

    this.chatService.getCurrentQuestion().subscribe((currentQuestion) => {
      this.currentQuestion = currentQuestion;
      [this.questionRatingValue, this.questionRatingText] = this.questionRatingService.getRatingElectionForQuestion(currentQuestion);
    });
  }

}
