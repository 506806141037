import { DateTransformService } from './../../../../shared/services/dateTransform.service';
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../core/services/auth.service';
import {LoginUser} from '../../models/login-user';
import {Router} from '@angular/router';
import {ConfigService} from '../../../../shared/services/config.service';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../../../shared/values';
import { RegistrationRepository } from 'src/app/shared/services/registration-repository.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  loading: boolean = false;

  constructor(
      private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private authService: AuthService,
      private router: Router,
      private configService: ConfigService,
      private translate: TranslateService,
      private registrationRepository: RegistrationRepository) {
    translate.addLangs(LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.registrationForm = this.createFormGroup();
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group (
        {
          email: ['', [Validators.required]] ,
          password: ['', [Validators.required]],
          confirmPassword: ['', [Validators.required]],
          signupCode: ['', [Validators.required]],
          acceptTerms: [''],
        }
    );
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass;
  }

  async register() {
    if (this.registrationForm.invalid) {
      const errorMsg = await this.translate.get('ERROR.invalidEmailAndPassword').toPromise();
      this.toastr.error(errorMsg);
      return;
    }

    if (!this.registrationForm.get('acceptTerms').value) {
      const errorMsg = await this.translate.get('ERROR.acceptTerms').toPromise();
      this.toastr.error(errorMsg);
      return;
    }

    if (!this.checkPasswords(this.registrationForm)) {
      const errorMsg = await this.translate.get('ERROR.notEqualPassword').toPromise();
      this.toastr.error(errorMsg);
      return;
    }

    if (!await this.configService.checkSignupCode(this.registrationForm.get('signupCode').value)) {
      let error = 'ERROR.signupCode'
      let specificError = false;
      this.configService.onErrorCode().subscribe(response => {
        if(response === 'code in use'){
         error = 'ERROR.codeInUse';
        }else if(response !== 'code in use' && response !== undefined && response !== null){
          specificError = true;
          const date: Date = DateTransformService.timeStampLongFormatToDate(response);
          const dateTransformed = date.getDate() +'.'+ (date.getMonth()+1) +'.'+ date.getFullYear();
          error = 'Ihrer Registrierungscode wurde am '+dateTransformed+' abgelaufen. Bitte wenden Sie sich an <a href=\'mailto:mi-train@tu-braunschweig.de\'>mi-train@tu-braunschweig.de</a> um einen gültigen Code zu erhalten';
        }
      }).unsubscribe();
      this.configService.restoreErrorCode(null);
      if(specificError){
        this.toastr.error(error);
      }else{
        let errorMsg = await this.translate.get(error).toPromise();
        this.toastr.error(errorMsg);
      }
      return;
    }

    this.loading = true;
    const registrationUser = new LoginUser(this.registrationForm.value);
    try {
      const user = await this.authService.signUp(registrationUser);
      if (user.uid) {
        const infoMsg = await this.translate.get('INFO.accountCreated').toPromise();
        let codeId = '';
        this.configService.onChangedCode().subscribe(response => codeId = response);
        await this.registrationRepository.setUserToUniqueCode(codeId, user.uid);
        this.toastr.info(infoMsg);
        this.router.navigate(['/auth/login']);
      } else {
        this.toastr.error('There has been a problem while creating the user. Please try again.');
      }
    } catch (error) {
      const errorKey = 'ERROR.' + error.code;
      let errorMsg = await this.translate.get(errorKey).toPromise();
      if (errorKey === errorMsg) {
        errorMsg = await this.translate.get('ERROR.common').toPromise();
      }
      this.toastr.error(errorMsg);
    } finally {
      this.loading = false;
    }

  }

}
