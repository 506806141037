import { DeleteConversationTTSCache } from './../services/delete-conversation-tts-cache.service';
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DeleteConversationService} from '../services/delete-conversation.service';
import {ConversationDto} from '../../shared/dto/conversationDto';
import {Conversation} from '../../shared/models/conversation';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-delete-conversation-dialog',
    templateUrl: 'delete-conversation-dialog.html',
    styleUrls: ['./delete-conversation-dialog.scss']

})
export class DeleteConversationDialogComponent {
    conversation: Conversation;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Conversation,
        public dialogRef: MatDialogRef<DeleteConversationDialogComponent>,
        public deleteConversationService: DeleteConversationService,
        public deleteConversationTTSCache: DeleteConversationTTSCache,
        public toastr: ToastrService
    ) {
        dialogRef.disableClose = true;
        this.conversation = data;
    }

    onDeleteClick(): void {
        this.deleteConversationService.deleteConversation(this.conversation)
            .then(() => {
                this.deleteConversationTTSCache.deleteConversationAudioItems(this.conversation.gender, this.conversation.id).subscribe(response => {
                    this.toastr.info('Conversation deleted'); 
                    this.dialogRef.close();
                }, error => {
                    this.toastr.info('Conversation deleted but an error has occurred in the cache server');
                    this.dialogRef.close();
                });
            })
            .catch((error) => {
                this.dialogRef.close();
                this.toastr.info('An error has occurred. Please try again.');
            });
    }

}
