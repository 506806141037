import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { Conversation } from '../../../shared/models/conversation';

@Component({
  selector: 'app-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
export class ChatPanelComponent implements OnInit {
  conversation: Conversation;

  constructor(
      private chatService: ChatService
  ) { }

  ngOnInit(): void {
    this.loadChat();
  }

  loadChat(): void{
    this.chatService.onNewConversation().subscribe((conversation) => {
      this.conversation = conversation;
    });
  }
}
