<div class='container'>
    <div id="gesprachsphasenTab">
        <h2 class="tab-title">Gesprächsphasen</h2>

        <app-report-box
                *ngIf="firstSectionData"
                title='Beziehungsaufbau'
                [comment]="firstSectionData.title"
                [optionalCommentTitle]="firstSectionData.firstTitle"
                [optionalCommentText]="firstSectionData.firstText"
                [optionalCommentClass]="firstSectionData.firstClassColor"
                [optional2CommentTitle]="firstSectionData.secondTitle"
                [optional2CommentText]="firstSectionData.secondText"
                [optional2CommentClass]="firstSectionData.secondClassColor"
        ></app-report-box>

        <app-report-box
                *ngIf="secondSectionData"
                title='Fokussierung'
                [comment]="secondSectionData.title"
                [optionalCommentTitle]="secondSectionData.firstTitle"
                [optionalCommentText]="secondSectionData.firstText"
                [optionalCommentClass]="secondSectionData.firstClassColor"
        ></app-report-box>

        <app-report-box
                *ngIf="thirdSectionData"
                title='Evokation'
                [comment]="thirdSectionData.title"
                [optionalCommentTitle]="thirdSectionData.firstTitle"
                [optionalCommentText]="thirdSectionData.firstText"
                [optionalCommentClass]="thirdSectionData.firstClassColor"
                [optional2CommentTitle]="thirdSectionData.secondTitle"
                [optional2CommentText]="thirdSectionData.secondText"
                [optional2CommentClass]="thirdSectionData.secondClassColor"
                [optional3CommentTitle]="thirdSectionData.thirdTitle"
                [optional3CommentText]="thirdSectionData.thirdText"
                [optional3CommentClass]="thirdSectionData.thirdClassColor"
                [optional4CommentTitle]="thirdSectionData.fourthTitle"
                [optional4CommentText]="thirdSectionData.fourthText"
                [optional4CommentClass]="thirdSectionData.fourthClassColor"
        ></app-report-box>

        <app-report-box
                *ngIf="fourthSectionData"
                title='Planung'
                [comment]="fourthSectionData.title"
                [optionalCommentTitle]="fourthSectionData.firstTitle"
                [optionalCommentText]="fourthSectionData.firstText"
                [optionalCommentClass]="fourthSectionData.firstClassColor"
                [optional2CommentTitle]="fourthSectionData.secondTitle"
                [optional2CommentText]="fourthSectionData.secondText"
                [optional2CommentClass]="fourthSectionData.secondClassColor"
        ></app-report-box>

        <app-total-percentage
                *ngIf="totalPercentage != null"
                [number]="totalPercentage"></app-total-percentage>
    </div>
</div>
