<div class="container text-page">

    <div class="row">
        <div class="col-md-12">
            <h1>Datenschutzerklärung für Besucher der MI-Tool Website</h1>

            <p><strong>1. Datenschutz auf einen Blick</strong></p>

            <p>Allgemeine Hinweise<br/>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer -unter diesem Text aufgeführten- Datenschutzerklärung.</p>

            <p><strong>Datenerfassung auf dieser Website </strong></p>

            <p><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong><br/>
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
            </p>

            <p><strong>Wie erfassen wir Ihre Daten?</strong><br/>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um ihre Registrierungsdaten handeln, die Sie eingeben, um das System zu nutzen. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
            </p>

            <p><strong>Wofür nutzen wir Ihre Daten?</strong><br/>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden. Die Ergebnisse, die Sie bei der Nutzung des MI-Tools generieren können im Rahmen von Forschungsarbeiten anonymisiert ausgewertet werden. Informationen zum Datenschutz dieser Daten finden Sie für Nutzer des MI-Tools hier: <a href="/datenschutzbestimmungen">https://demo-mi-train.aundo-braunschweig.de/datenschutzbestimmungen</a>
            </p>

            <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong><br/>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            </p>

            <p><strong>2. Externes Hosting und IT-Betreuung</strong></p>

            <p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster) und betreut. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.<br/>
                Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).<br/>
                Unser Hoster und Dienstleister wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung Ihrer Leistungspflichten und im Rahmen der Systembetreuung erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.<br/>
                Wir setzen folgende Hoster und Dienstleister ein:<br/>
                <strong>Hetzner Online GmbH</strong><br/>
                Industriestr. 25<br/>
                91710 Gunzenhausen<br/>
                Deutschland<br/>
                <strong>GOOGLE</strong><br/>
                Google Ireland Limited<br/>
                Gordon House, Barrow Street<br/>
                Dublin 4<br/>
                Irland<br/>
                <strong>4A-SIDE GmbH</strong> Spielmannstrasse 19<br/>
                38106 Braunschweig<br/>
                Deutschland
            </p>

            <p><strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong><br/>
                Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster und IT-Dienstleister geschlossen.
            </p>

            <p><strong>3. Allgemeine Hinweise und Pflichtinformationen</strong></p>

            <p><strong>Datenschutz</strong><br/>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br/>
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.<br/>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. </p>

            <p><strong>Hinweis zur verantwortlichen Stelle</strong></p>

            <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br/>
            TU-Braunschweig<br/>
            Lehrstuhl für Arbeits-, Organisations- und Sozialpsychologie<br/>
            Spielmannstrasse 19<br/>
            38106 Braunschweig<br/>
            Ansprechpartnerin Prof. Dr. Simone Kauffeld<br/>
            Telefon: +49 (531) 391 - 2547<br/>
                E-Mail: s.kauffeld@tu-braunschweig.de<br/></p>

            <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet. </p>

            <p><strong>Speicherdauer</strong><br/>
                Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe. </p>

            <p><strong>Gesetzlich vorgeschriebener Datenschutzbeauftragter</strong><br/>
                Der Datenschutzbeauftragte der Technischen Universität Braunschweig ist:<br/>
                Dr. Bernd Nörtemann<br/>
                Bienroder Weg 80<br/>
                38106 Braunschweig<br/>
                Telefon: +49 (531) 391 – 7654
                E-Mail: <a href="mailto:datenschutz@tu-braunschweig.de">datenschutz@tu-braunschweig.de</a><br/>
                <a href="https://www.tu-braunschweig.de/datenschutz">https://www.tu-braunschweig.de/datenschutz</a></p>

            <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong><br/>
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.<br/>
                Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)<br/>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).<br/>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO). </p>

            <p><strong>Beschwerderecht bei der zuständigen Aufsichtsbehörde</strong><br/>
                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.<br/>
                Die für die TU-Braunschweig zuständige Datenschutz-Aufsichtsbehörde ist:<br/>
                die Landesbeauftragte für den Datenschutz Niedersachsen<br/>
                Prinzenstraße 5<br/>
                30159 Hannover<br/>
                Telefon: +49 (0)511 / 120-4500<br/>
                Telefax: +49 (0)511 / 120-4599<br/>
                E-Mail: <a href="mailto:poststelle@lfd.niedersachsen.de">poststelle@lfd.niedersachsen.de</a></p>

            <p><strong>Recht auf Datenübertragbarkeit</strong><br/>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist. </p>

            <p><strong>SSL- bzw. TLS-Verschlüsselung</strong><br/>
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br/>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden. </p>

            <p><strong>Auskunft, Löschung und Berichtigung</strong><br/>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.<br/>
                Recht auf Einschränkung der Verarbeitung<br/>
                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:<br/>
            </p>
                <ul>
                <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
                <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                </ul>

            <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden. </p>

            <p><strong>4. Datenerfassung auf dieser Website </strong></p>

            <p><strong>Cookies</strong><br/>
                Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.<br/>
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren würden (z.B. ein Vor- oder Zurück-Blättern in einem Fragenbogen).<br/>
                Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die Blätterfunktion) oder zur Optimierung der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.<br/>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein. </p>

            <p><strong>Server-Log-Dateien</strong><br/>
                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                <ul>
                    <li>Browsertyp und Browserversion</li>
                    <li>verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>Uhrzeit der Serveranfrage</li>
                    <li>IP-Adresse</li>
                </ul>

            <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.<br/>
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden. </p>

            <p><strong>Anfrage per E-Mail, Telefon oder Telefax</strong><br/>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br/>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.<br/>
                Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>

            <p><strong>Registrierung auf dieser Website</strong><br/>
                Sie können sich für diese Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu übermittelten Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.<br/>
                Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der Registrierung angegebenen Kontaktdaten, um Sie auf diesem Wege zu informieren.<br/>
                Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchführung des durch die Registrierung begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6 Abs. 1 lit. b DSGVO).<br/>
                Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf dieser Website registriert sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt. </p>

            <p><strong>5. Plugins und Tools </strong></p>

            <p><strong>Google Web Fonts</strong> (lokales Hosting)<br/>
                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts, die von Google bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet dabei nicht statt. Weitere Informationen zu Google Web Fonts finden Sie unter:<br/>
                <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a><br/>
                und in der Datenschutzerklärung von Google:<br/>
                <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a><br/>
                <strong>Google Firebase</strong><br/>
                Zur Funktion dieser Seite wird Google Firebase eingesetzt. Bei der Nutzung des MI-Tools kommt es daher zu einem Austausch mit Servern von Google. Ihre Daten werden dabei anonymisiert und ein Rückschluss auf Ihre Person ist nicht möglich. Weitere Informationen finden Sie unter:<br/>
                <a href="https://firebase.google.com/">https://firebase.google.com/</a><br/>
                und in der Firebase-Datenschutzerklärung von Google:<br/>
                <a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a></p>

            <p><strong>6. Aktualität und Änderung dieser Datenschutzerklärung </strong></p>

            <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Dezember 2020. Aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben oder Erweiterung unseres Angebots kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann immer auf dieser Seite von Ihnen abgerufen und ausgedruckt werden: <a href="/datenschutzbestimmungen">https://demo-mi-train.aundo-braunschweig.de/datenschutzbestimmungen</a>  </p>

            <h2>Datenschutzerklärung MI-Tool-Nutzer </h2>

            <p><strong>1. Verantwortung für die Datenverarbeitung und Kontaktdaten:</strong><br/>
                Verantwortlich für die Datenverarbeitung bei der Nutzung des MI-Tools ist der Lehrstuhl für Arbeits-, Organisations- und Sozialpsychologie, der TU-Braunschweig, Spielmannstrasse 19, 38106 Braunschweig, Ansprechpartnerin Prof. Dr. Simone Kauffeld. E-Mail: s.kauffeld@tu-braunschweig.de<br/>
                Den betrieblichen Datenschutzbeauftragten erreichen Sie per E-Mail unter datenschutz@tu-braunschweig.de. </p>

            <p><strong>2. Verarbeitungen, Rechtsgrundlage, Empfänger</strong><br/>
                <strong>2.1. Teilnehmenden-Code:</strong> Um das Tool nutzen zu können, müssen Sie über einen Nutzer-Code verfügen. Wenden Sie sich zu diesem Zweck bitte per Mail an mi-train@tu-braunschweig.de. Diese Verarbeitung dieses Codes ist für die Nutzung des Systems notwendig und beruht auf Art.6 Abs.1 (a) DSGVO.<br/>
                <strong>2.2. Registrierungsdaten:</strong> Um das MI-Tool zu nutzen, ist eine Registrierung notwendig. Die dazu erforderlichen Daten, bestehend aus Name, E-Mailadresse und Passwort werden gemeinsam mit Ihrem Nutzer-Code und mit den Ergebnissen ihrer MI-Online Trainingseinheiten gespeichert. Die Nutzung des Systems erfolgt freiwillig und Sie können jederzeit eine Löschung beantragen. Die Speicherung erfolgt auf Ihrer Einwilligung welche Sie bei der Registrierung zur System-Nutzung erteilen (gemäß Art.6 Abs. 1 (a) DSGVO). Ihre Daten können durch die beteiligten Wissenschaftler des Lehrstuhls für Arbeits- und Organisationspsychologie eingesehen werden; darüber hinaus ist möglich, dass im Rahmen der Systembetreuung auch der weisungsgebundene Dienstleister Daten zur Kenntnis nehmen kann. Ihre vom System ermittelten Trainingsergebnisse werden jeweils nur in der aktuellen Version gespeichert. Ältere Trainingsergebnisse werden überschrieben. Auch nach Löschung Ihres Accounts werden ihre letzten Trainingsdaten zum Zweck der Systemoptimierung und der MI-Forschung anonymisiert gespeichert. Die anonymisierten Daten werden im Sinne der guten wissenschaftlichen Praxis (Gollwitzer et al. 2016, DFG-Leitlinie) frühestens nach 10 Jahren gelöscht. Die DFG-Leitlinie sieht es vor, dass Forschungsdaten archiviert und für mindestens 10 Jahre aufbewahrt werden.</p>

            <p><strong>3. Ihre Rechte:</strong> Sie haben das Recht auf Auskunft über die, zu Ihrer Person, gespeicherten Daten und das Recht auf Berichtigung Ihrer Daten. Außerdem haben Sie, unter den gesetzlichen Voraussetzungen, das Recht auf Übertragung und Löschung der Daten sowie auf Einschränkung der Verarbeitung (gemäß Art. 15-21 DSGVO). Sollten Sie Fragen in Zusammenhang mit dem Datenschutz haben oder von Ihren Rechten Gebrauch machen wollen, wenden Sie sich bitte an den Verantwortlichen (siehe 1). </p>

            <p><strong>Widerruf bei Einwilligung in die Verarbeitung und Forschungseinverständnis:</strong> Eine erteilte Einwilligung, gemäß Art. 6 Abs. 1 (a) DSGVO, können Sie jederzeit bis zum Ende der Datenerhebung und somit vor erfolgter Anonymisierung widerrufen, ohne dass hierdurch die Rechtmäßigkeit der Verarbeitung vor dem erfolgten Widerruf beeinträchtigt wird.<br/>
                Bei Beschwerden über die Datenverarbeitung können Sie sich jederzeit an den betrieblichen Datenschutzbeauftragten wenden, darüber hinaus haben Sie jederzeit ein Beschwerderecht bei jeder Datenschutzaufsichtsbehörde; die für den Lehrstuhl für Arbeits-, Organisations- und Sozialpsychologie, der TU-Braunschweig zuständige Aufsichtsbehörde ist die Landesbeauftragte für den Datenschutz Niedersachsen, Prinzenstraße 5, 30159 Hannover, poststelle@lfd.niedersachsen.de. </p>

            <p><strong>4. Aktualität und Änderung dieser Datenschutzerklärung</strong><br/>
                Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Dezember 2020. Aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben oder Erweiterung unseres Angebots kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann immer auf dieser Seite von Ihnen abgerufen und ausgedruckt werden: <a href="/datenschutz">https://demo-mi-train.aundo-braunschweig.de/datenschutz</a>  </p>
        </div>
    </div>
</div>
