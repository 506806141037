<div class="title"><span>{{ title }}</span></div>
<div class="text-area">
    <div class="textComment">
        <p [ngClass]="(percentage !== null)? percentageClass : ''">
            <span *ngIf="percentage !== null" class="percentage"><app-percentage-colour [number]="percentage" [inverseColors]="inverseColors"></app-percentage-colour></span>
            <span [innerHTML]="comment"></span>
        </p>
    </div>

    <div class="optionalTextComment" *ngIf="optionalCommentText">
        <h4 *ngIf="optionalCommentTitle">{{ optionalCommentTitle }}</h4>
        <span class="{{ optionalCommentClass }}">{{ optionalCommentText }}</span>
    </div>

    <div class="optionalTextComment" *ngIf="optional2CommentText">
        <h4 *ngIf="optional2CommentTitle">{{ optional2CommentTitle }}</h4>
        <span class="{{ optional2CommentClass }}">{{ optional2CommentText }}</span>
    </div>

    <div class="optionalTextComment" *ngIf="optional3CommentText">
        <h4 *ngIf="optional3CommentTitle">{{ optional3CommentTitle }}</h4>
        <span class="{{ optional3CommentClass }}">{{ optional3CommentText }}</span>
    </div>

    <div class="optionalTextComment" *ngIf="optional4CommentText">
        <h4 *ngIf="optional4CommentTitle">{{ optional4CommentTitle }}</h4>
        <span class="{{ optional4CommentClass }}">{{ optional4CommentText }}</span>
    </div>
</div>
