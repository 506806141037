import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../core/services/auth.service';
import {UserService} from '../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../values';
import {Router} from '@angular/router';

@Component({
    selector: 'app-delete-user-dialog',
    templateUrl: 'delete-user-dialog.html',
    styleUrls: ['./delete-user-dialog.scss']

})
export class DeleteUserDialogComponent {
    deleteUserForm: FormGroup = this.formBuilder.group({
        password: [null, [Validators.required]]
    });

    constructor(
        public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
        public formBuilder: FormBuilder,
        public toastr: ToastrService,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private translate: TranslateService
    ) {
        translate.addLangs(LANGS);
        translate.setDefaultLang(DEFAULT_LANG);
        dialogRef.disableClose = true;
    }

    async deleteUserSubmit(): Promise<void> {
        if (this.deleteUserForm.valid) {
            const user = await this.authService.getCurrentUser();
            if (await this.userService.deleteUser(user.uid, this.deleteUserForm.value.password)) {
                this.dialogRef.close();
                await this.authService.logout();
                await this.router.navigate(['/auth/login']);
            } else {
                const errorMsg = await this.translate.get('ERROR.invalidPassword').toPromise();
                this.toastr.error(errorMsg);
            }
        }
    }
}
