import { Component, OnInit } from '@angular/core';
import { Conversation } from '../../../../shared/models/conversation';
import { ChatService } from '../../../services/chat.service';
import {faPhoneAlt, faVolumeOff, faVolumeUp} from '@fortawesome/free-solid-svg-icons';
import {SpeechService} from '../../../services/speech.service';

@Component({
  selector: 'app-avatar-footer',
  templateUrl: './avatar-footer.component.html',
  styleUrls: ['./avatar-footer.component.scss']
})
export class AvatarFooterComponent implements OnInit {

  showAvatarFooter: boolean = false;
  showSpeechMute: boolean = true;
  conversation: Conversation;
  faPhoneAlt = faPhoneAlt;
  faVolume: any;

  constructor(
      private chatService: ChatService,
      private speechService: SpeechService
  ) { }

  ngOnInit(): void {
    this.getConversation();
    this.loadMute();
  }

  getConversation(): void {
    this.chatService.onNewConversation().subscribe((conversation) => {
      this.conversation = conversation;
    });
  }

  cancelConversation(): void {
    this.chatService.cancelConversation();
  }

  loadMute(): void {
    this.showSpeechMute = this.speechService.isBrowserSupported();
    this.faVolume = this.speechService.isMuted() ? faVolumeOff : faVolumeUp;
    this.toogleMuteGcloudSpeech();
  }

  toggleMute(): void {
    const currentMuteValue = this.speechService.toggleMute();
    this.faVolume = currentMuteValue ? faVolumeOff : faVolumeUp;
    this.toogleMuteGcloudSpeech();
  }

  toogleMuteGcloudSpeech(): void {
    if(this.faVolume.iconName === 'volume-off'){
      this.chatService.audioMutedToggle(true);
    }else{
      this.chatService.audioMutedToggle(false);
    }
  }

}
