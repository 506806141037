import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ToastrService} from 'ngx-toastr';
import {Conversation} from '../../../shared/models/conversation';
import {ConversationRepositoryService} from '../../../shared/services/conversation-repository.service';
import {MatDialog} from '@angular/material/dialog';
import {DeleteConversationDialogComponent} from '../../delete-conversation/delete-conversation-dialog.component';
import {EditConversationDialogComponent} from '../../dialog-edit-conversation/edit-conversation-dialog.component';

@Component({
    selector: 'app-conversations-list-table',
    templateUrl: './conversations-list-table.component.html',
    styleUrls: ['./conversations-list-table.component.scss']
})
export class ConversationsListTableComponent implements OnInit {
    displayedColumns: string[] = ['name', 'topic', 'actions'];
    conversations?: Conversation[];
    dataSource: MatTableDataSource<Conversation>;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private toastr: ToastrService,
        private conversationService: ConversationRepositoryService,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.loadConversations();
    }

    loadConversations(): void {
        this.conversationService.getConversations().subscribe(
            (conversations) => {
                this.conversations = conversations;
                this.dataSource = new MatTableDataSource<Conversation>(this.conversations);
                this.dataSource.paginator = this.paginator;
            },
            (error) => {
                this.toastr.error(error);
            }
        );
    }

    editConversation(conversation: Conversation): void {
        const dialogRef = this.dialog.open(EditConversationDialogComponent, {
            data: conversation
        });
    }

    deleteConversation(conversation: Conversation): void {
        const dialogRef = this.dialog.open(DeleteConversationDialogComponent, {
            data: conversation
        });
    }

}
