import { User } from './../../shared/models/user';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private afAuth: AngularFireAuth, private firestore: AngularFirestore) { }

  getUser(userId: string){
    return this.firestore.doc<User>(`users/${userId}`).get();
  }

  getUsers(){
    return this.firestore.collection('users').valueChanges();
  }

  getUserByUid(){
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.firestore.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
}
