import { Component, OnInit } from '@angular/core';
import { Conversation } from '../../../shared/models/conversation';
import { ActivatedRoute } from '@angular/router';
import { ConversationRepositoryService } from '../../../shared/services/conversation-repository.service';

@Component({
  selector: 'app-contact-header',
  templateUrl: './contact-header.component.html',
  styleUrls: ['./contact-header.component.scss']
})
export class ContactHeaderComponent implements OnInit {

  conversation: Conversation;
  conversationId: string;

  constructor(
    private route: ActivatedRoute,
    private conversationRepositoryService: ConversationRepositoryService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.conversationId = params.get('id');
    });
    this.getConversationContact();
  }

  getConversationContact() {

    this.conversationRepositoryService.getConversationById(this.conversationId).subscribe((conversation) => {
      this.conversation = conversation;
    });
  }

}
