import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {ConversationItem} from '../models/conversationItem';

@Injectable({
    providedIn: 'root',
})
export class ConversationItemAdapter implements Adapter<ConversationItem> {
    adapt(item: any): ConversationItem {
        const data = item.payload ? item.payload.data() : item.data();

        return this.parse(data);
    }

    protected parse(data: any): ConversationItem {
        const nexts = [];

        if (data.nexts) {
            data.nexts.forEach(itemNext => {
                const next = this.parse(itemNext);
                nexts.push(next);
            });
        }

        const conversationItem = new ConversationItem();
        conversationItem.id = data.id;
        conversationItem.kind = data.kind;
        conversationItem.phase = data.phase;
        conversationItem.rating = data.rating;
        conversationItem.methods = data.methods;
        conversationItem.mark = data.mark;
        conversationItem.text = data.text;
        conversationItem.nexts = nexts;

        return conversationItem;
    }
}
