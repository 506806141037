import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ConversationItem} from '../models/conversationItem';
import {Conversation} from '../models/conversation';
import {CacheService} from './cache.service';

// default cache expiration time is 30 days
const CACHE_DEFAULT_EXPIRETIME = 30 * 24 * 60;

@Injectable({
  providedIn: 'root'
})
export class CacheConversationItemService {
  constructor(
      private cacheService: CacheService
  ) {
  }

  save(conversationId: string, conversationItem: ConversationItem, expirationMin: number = CACHE_DEFAULT_EXPIRETIME): void {
    let data = this.cacheService.load(conversationId) as {[key: string]: ConversationItem};

    if (!data) {
      data = {};
    }

    data[conversationItem.id] = conversationItem;

    this.cacheService.save(conversationId, data, expirationMin);
  }

  load(conversationId: string, conversationItemId: string, asObservable: boolean = false): any | Observable<any> {
    const data = this.cacheService.load(conversationId) as {[key: string]: ConversationItem};
    if (data) {
      if (conversationItemId in data) {
        return this.cacheService.returnData(data[conversationItemId], asObservable);
      } else {
        return this.cacheService.returnData(null, asObservable);
      }
    }
    return this.cacheService.returnData(null, asObservable);
  }

  checkCacheOfConversation(conversation: Conversation): void {
    this.cacheService.checkCacheDate(conversation.id, conversation.updateDate);
  }

}

