import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {MessageDialogComponent, MessageDialogData} from '../components/message-dialog/message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {
  dialogRef: MatDialogRef<MessageDialogComponent>;

  constructor(private dialog: MatDialog) { }

  open(options: MessageDialogData): void {
    this.dialogRef = this.dialog.open(MessageDialogComponent, {data: options});
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1));
  }

}
