<nav id="mi-header" class="navbar navbar-dark header">
    <div id="nav-header">
        <div class="logo-section">
            <a routerLink="/"><img id='main-logo' src='../../../../assets/logos/mi-tool.png'></a>
        </div>
        <div id="headerTitle">
            <span>{{ titleHeader }}</span>
        </div>
        <div class="header-user-icon-section">
            <app-header-user-icon></app-header-user-icon>
        </div>
    </div>
</nav>
