import { TrainerDetails } from './trainerDetails';
import { ConversationTrainer } from './conversationTrainer';

export class UserCodeDetails {
    id: string;
    name: string = '';
    description: string = '';
    activatedCodes: number;
    totalCodes: number;
    showExpirationDate: boolean;
    codes: Array<TrainerDetails> = new Array();
    conversationsTrainer: Array<ConversationTrainer> = new Array();

    constructor() {}
}