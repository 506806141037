import { Component, OnInit } from '@angular/core';
import {ChatService} from '../../../services/chat.service';
import {ConversationItem} from '../../../../shared/models/conversationItem';
import {Router} from '@angular/router';
import {Conversation} from '../../../../shared/models/conversation';
import {LoadingService} from '../../../../shared/services/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../../../shared/values';

@Component({
  selector: 'app-chat-questions',
  templateUrl: './chat-questions.component.html',
  styleUrls: ['./chat-questions.component.scss']
})
export class ChatQuestionsComponent implements OnInit {
  currentPhase: string;
  conversation: Conversation;
  questions: ConversationItem[];
  selectedQuestion: ConversationItem;
  isFinal: boolean = false;

  constructor(
    private chatService: ChatService,
    private router: Router,
    private loadingService: LoadingService,
    private translate: TranslateService
  ) {
    translate.addLangs(LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.loadChat();
  }

  loadChat(): void{
    this.chatService.onNewConversation().subscribe((conversation) => this.conversation = conversation);
    this.chatService.getQuestions().subscribe((questions) => {
      this.questions = questions;

      if (questions.length) {
        this.currentPhase = questions[0].phase;
        this.isFinal = this.currentPhase === 'final';
      }

      this.selectedQuestion = null;
    });
  }

  selectQuestion(question: ConversationItem): void {
    this.selectedQuestion = question;
  }

  async sendQuestion(): Promise<void> {
    if (this.selectedQuestion) {
      if (this.isFinal) {
        this.loadingService.showLoading();
        await this.chatService.saveConversation();
        this.loadingService.hideLoading();
        this.router.navigate(['/user/report', this.conversation.id]);
        this.chatService.cancelConversation();
      } else {
        this.chatService.sendQuestion(this.selectedQuestion, this.conversation.gender, this.conversation.id);
      }
    }
  }

}
