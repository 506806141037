import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConversationsComponent } from '../conversations/conversations.component';
import { CodeListTableComponent } from './code-list-table/code-list-table.component';

@Component({
  selector: 'app-admin-management-view',
  templateUrl: './admin-management-view.component.html',
  styleUrls: ['./admin-management-view.component.scss']
})
export class AdminManagementViewComponent implements OnInit {

  selected: FormControl = new FormControl(0);
  textMainButton: string = 'Neue Konversation';
  textTitleSection: string = 'Konversationenverwaltung';
  @ViewChild('conversationTab') conversationTab: ConversationsComponent;
  @ViewChild('codeTab') codeTab: CodeListTableComponent;

  constructor() { }

  ngOnInit(): void {
  }

  tabSelectionChange(event: any) : void {
    this.selected.setValue(event);
    if (this.selected.value) {
      this.textMainButton = 'Neue*r Kund*in';
      this.textTitleSection = 'Kontenverwaltung';
    } else {
      this.textMainButton = 'Neue Konversation';
      this.textTitleSection = 'Konversationenverwaltung';
    }

 }

 clickMainButton() : void {
  if (this.selected.value) {
    this.codeTab.createCode();
  } else {
    this.conversationTab.newConversation();
  }
 }

}
