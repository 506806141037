import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import { TrainerRepositoryService } from '../../shared/services/trainer-repository.service';
import { TrainerToStore } from '../../shared/models/TrainerToStore';

@Injectable({
    providedIn: 'root'
})
export class CreateEditTrainerervice {

    constructor(
        private firestore: AngularFirestore,
        private storage: AngularFireStorage,
        private trainerRepository: TrainerRepositoryService,
    ) { }

    async createTrainer(data: any): Promise<string> {
        const trainerId = this.trainerRepository.getNewId();

        this.trainerRepository.createTrainer(data, trainerId);

        return trainerId;
    }

    async editTrainer(data: any, trainerId: string): Promise<void> {

        this.trainerRepository.editTrainer(data, trainerId);
    }

}