<div class="expiration-container">
    <div class="expiration-info">
        <p *ngIf="codeExpirated">
            Ihrer Registrierungscode wurde am {{expirationDate | date: "dd.MM.yyyy" }} abgelaufen. Bitte wenden Sie sich an <a href="mailto:mi-train@tu-braunschweig.de">mi-train@tu-braunschweig.de</a> um einen gültigen Code zu erhalten
        </p>
        <p *ngIf="codeDeleted">
            Ihrer Registrierungscode wurde gelöscht. Bitte wenden Sie sich an <a href="mailto:mi-train@tu-braunschweig.de">mi-train@tu-braunschweig.de</a> um einen gültigen Code zu erhalten
        </p>
    </div>
    <form [formGroup]="codeForm" (ngSubmit)=CheckCodeAndSet()>
        <div class="mb-2 mt-5" id="code-container">
            <input type="text" class="code-input" id="code-id" formControlName="code" placeholder="Einen neuen Code eingeben">
        </div>
            <button [disabled]="codeForm.controls['code'].errors?.required"
                    id="submitCode" type="submit" class="btn mb-4 code-button"><span>Annehmen</span></button>
    </form>
</div>
