import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-general-loader',
  templateUrl: './general-loader.component.html',
  styleUrls: ['./general-loader.component.scss']
})
export class GeneralLoaderComponent implements OnInit {
  show = false;

  constructor(
      private router: Router,
      private loadingService: LoadingService
  ) { }

  ngOnInit(): void {

    this.loadingService.onLoading().subscribe(show => this.show = show);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart){
        this.loadingService.showLoading();
      } else if ( event instanceof NavigationEnd){
        this.loadingService.hideLoading();
      }
    });
  }



}
