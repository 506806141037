import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-conversation-list-item',
  templateUrl: './conversation-list-item.component.html',
  styleUrls: ['./conversation-list-item.component.scss']
})
export class ConversationListItemComponent implements OnInit {

  @Input() conversationName: string;
  @Input() checkedOption: string = 'false';
  @Output() updateCheckbox: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clickCheckbox(event) {
    this.updateCheckbox.emit(event);
  }

}
