import { LoadingService } from './../../../shared/services/loading.service';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthService } from './../../../core/services/auth.service';
import { Component, OnInit, Input, NgZone } from '@angular/core';
import { faVideo, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import {Conversation} from '../../../shared/models/conversation';
import {ChatService} from '../../services/chat.service';
import {ResultService} from '../../services/result.service';
import {Router} from '@angular/router';

const CODE_EXPIRED = 'code expired';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss']
})
export class ContactItemComponent implements OnInit {

  faVideo = faVideo;
  faFileAlt = faFileAlt;

  @Input() conversation: Conversation;
  @Input() showCameraIcon: boolean = true;
  @Input() showDocumentIcon: boolean = false;
  @Input() showIcons: boolean = true;

  constructor(
      private router: Router,
      private ngZone: NgZone,
      private chatService: ChatService,
      private resultService: ResultService,
      private authService: AuthService,
      private configService: ConfigService,
      private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.checkResults();
  }

  async checkResults() {
    const result = await this.resultService.getResultForCurrentUserAndConversationId(this.conversation.id);
    this.showDocumentIcon = result.length ? true : false;
  }

  async newConversation() {
    this.loadingService.showLoading();
    const user = await this.authService.getCurrentUser();
    const codeStatus = await this.configService.checkUserSignupCode(user.uid);
    if(codeStatus === CODE_EXPIRED || codeStatus === ''){
      this.loadingService.hideLoading();
      this.authService.logout();
    }else{
      this.chatService.setConversation(this.conversation);
      this.loadingService.hideLoading();
    }
  }

  viewResults() {
    this.chatService.cancelConversation();
    this.router.navigate(['/user/report', this.conversation.id]);
  }

}
