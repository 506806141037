import { ConfigService } from './../../../../shared/services/config.service';
import { AuthService } from './../../../../core/services/auth.service';
import { LoginUser } from './../../models/login-user';
import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {FirestoreService} from '../../../../core/services/firestore.service';
import {User} from '../../../../shared/models/user';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../../../shared/values';

const USER_VALID = 'user valid';
const CODE_EXPIRED = 'code expired';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  private loginUser: LoginUser;
  loginForm: FormGroup;
  public user: any;
  loading: boolean = false;

  constructor(
      private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private authService: AuthService,
      private router: Router,
      private ngZone: NgZone,
      private firestoreService: FirestoreService,
      private translate: TranslateService,
      private configService: ConfigService) {
    translate.addLangs(LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.loginForm = this.createFormGroup();
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group (
      {
        email: ['', [Validators.required, Validators.email]] ,
        password: ['', [Validators.required]]
      }
    );
  }

  async login() {
    if (this.loginForm.invalid) {
      if (this.loginForm.controls['email'].errors.email) {
        const errorMsg = await this.translate.get('ERROR.auth/invalid-email').toPromise();
        this.toastr.error(errorMsg);
      } else {
        const errorMsg = await this.translate.get('ERROR.invalidEmailAndPassword').toPromise();
        this.toastr.error(errorMsg);
      }
    } else {
      this.loading = true;
      this.toastr.clear();
      this.loginUser = new LoginUser(this.loginForm.value);
      try {
        const user = await this.authService.login(this.loginUser);
        const userData = await this.firestoreService.getUser(user.uid).toPromise();
        const userLogged = userData.data() as User;
        const userCodeStatus = await this.configService.checkUserSignupCode(user.uid);

        if (userLogged && userLogged.admin){
          this.ngZone.run(() => {
            this.router.navigate(['/admin']);
          });
        }else if(userCodeStatus === USER_VALID){
          this.ngZone.run(() => {
            this.router.navigate(['/user/chat']);
          });
        }else if(userCodeStatus === CODE_EXPIRED || userCodeStatus === ''){
          this.ngZone.run(() => {
            this.router.navigate(['/user/expiredsession']);
          });
        }
      } catch (error) {
        const errorMsg = await this.translate.get('ERROR.failLogin').toPromise();
        this.toastr.error(errorMsg);
      } finally {
        this.loading = false;
      }
    }
  }

}
