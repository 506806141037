import { CodeData } from '../../shared/models/codeData';
import { CodeDetails } from '../../shared/models/codeDetails';
import { ConversationDetails } from '../../shared/models/conversationDetails';
import { ConversationTrainerToStore } from '../../shared/models/conversationTrainerToStore';
import { CodeDetailsToCodeToStoreMapper } from './codeDetailsToCodeToStoreMapper';

export class CodeManagementFormToCodeDataMapper {

  static map(
    trainerId: string,
    codeList: Array<CodeDetails>,
    conversationsClicked: Array<ConversationDetails>
  ): CodeData {

    const result: CodeData = new CodeData();

    codeList.forEach(code => {
      result.codes.push(CodeDetailsToCodeToStoreMapper.map(code, trainerId));
    });

    conversationsClicked.forEach(conversation => {
      let conversationTrainerToStore: ConversationTrainerToStore = new ConversationTrainerToStore();
      conversationTrainerToStore.trainerId = trainerId;
      conversationTrainerToStore.conversationId = conversation.id;
      result.conversationsTrainer.push(conversationTrainerToStore);
    });

    return result;
  }
}