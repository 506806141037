<div class="conversation-form">
    <form (ngSubmit)="submitForm()"  [formGroup]="conversationForm"  novalidate>

        <div class="field">
            <mat-form-field appearance="fill">
                <mat-label>Name des Sprechpartners</mat-label>
                <input matInput type="text" formControlName="name" #inputName>
                <mat-error class="error-message">
                    <span *ngIf="conversationForm.get('name').hasError('required')">Pflichtfeld</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="field">
            <mat-form-field appearance="fill">
                <mat-label>Thema</mat-label>
                <input matInput type="text" formControlName="topic">
                <mat-error class="error-message">
                    <span *ngIf="conversationForm.get('topic').hasError('required')">Pflichtfeld</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="image-fields">
            <div class="field">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Stimmungsbild ++</mat-label>
                    <ngx-mat-file-input [accept]="'image/png, image/jpeg'" formControlName="image1" placeholder="Image 1">
                    </ngx-mat-file-input>
                    <mat-error class="error-message">
                        <span *ngIf="conversationForm.get('image1').hasError('required')">Pflichtfeld</span>
                        <span *ngIf="conversationForm.get('image1').hasError('fileExtension')">Only accept png and jpg</span>
                        <span *ngIf="conversationForm.get('image1').hasError('fileMinSize')">It's not a valid image</span>
                    </mat-error>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </div>

            <div class="field">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Stimmungsbild +</mat-label>
                    <ngx-mat-file-input [accept]="'image/png, image/jpeg'" formControlName="image2" placeholder="Image 2">
                    </ngx-mat-file-input>
                    <mat-error class="error-message">
                        <span *ngIf="conversationForm.get('image2').hasError('required')">Pflichtfeld</span>
                        <span *ngIf="conversationForm.get('image2').hasError('fileExtension')">Only accept png and jpg</span>
                        <span *ngIf="conversationForm.get('image2').hasError('fileMinSize')">It's not a valid image</span>
                    </mat-error>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </div>

            <div class="field">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Stimmungsbild 0</mat-label>
                    <ngx-mat-file-input [accept]="'image/png, image/jpeg'" formControlName="image3" placeholder="Image 3">
                    </ngx-mat-file-input>
                    <mat-error class="error-message">
                        <span *ngIf="conversationForm.get('image3').hasError('required')">Pflichtfeld</span>
                        <span *ngIf="conversationForm.get('image3').hasError('fileExtension')">Only accept png and jpg</span>
                        <span *ngIf="conversationForm.get('image3').hasError('fileMinSize')">It's not a valid image</span>
                    </mat-error>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </div>

            <div class="field">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Stimmungsbild -</mat-label>
                    <ngx-mat-file-input [accept]="'image/png, image/jpeg'" formControlName="image4" placeholder="Image 4">
                    </ngx-mat-file-input>
                    <mat-error class="error-message">
                        <span *ngIf="conversationForm.get('image4').hasError('required')">Pflichtfeld</span>
                        <span *ngIf="conversationForm.get('image4').hasError('fileExtension')">Only accept png and jpg</span>
                        <span *ngIf="conversationForm.get('image4').hasError('fileMinSize')">It's not a valid image</span>
                    </mat-error>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </div>

            <div class="field">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Stimmungsbild --</mat-label>
                    <ngx-mat-file-input [accept]="'image/png, image/jpeg'" formControlName="image5" placeholder="Image 5">
                    </ngx-mat-file-input>
                    <mat-error class="error-message">
                        <span *ngIf="conversationForm.get('image5').hasError('required')">Pflichtfeld</span>
                        <span *ngIf="conversationForm.get('image5').hasError('fileExtension')">Only accept png and jpg</span>
                        <span *ngIf="conversationForm.get('image5').hasError('fileMinSize')">It's not a valid image</span>
                    </mat-error>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="field">
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Konversation-Datei</mat-label>
                <ngx-mat-file-input [accept]="'text/html'" formControlName="conversation" placeholder="Conversation file">
                </ngx-mat-file-input>
                <mat-error class="error-message">
                    <span *ngIf="conversationForm.get('conversation').hasError('required')">Pflichtfeld</span>
                    <span *ngIf="conversationForm.get('conversation').hasError('fileExtension')">Only accept png and jpg</span>
                    <span *ngIf="conversationForm.get('conversation').hasError('fileMinSize')">It's not a valid image</span>
                </mat-error>
                <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
        </div>

        <div class="field">
            <mat-form-field appearance="fill" class="full-width mb-2">
                <mat-label>Genre des Gesprächs</mat-label>
                <mat-select formControlName="gender" name="gender">
                    <mat-option *ngFor="let gender of genders" [value]="gender.code">
                        {{gender.value}}
                    </mat-option>
                </mat-select>
                <mat-error class="error-message">
                    <span *ngIf="conversationForm.get('gender').hasError('required')">Pflichtfeld</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="buttons">
            <button type="button" mat-raised-button (click)="onCancel()">Abbrechen</button>
            <button type="submit" [disabled]="loading" mat-raised-button color="primary">Erstellen</button>
        </div>

    </form>
</div>
