import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-code-list-item',
  templateUrl: './code-list-item.component.html',
  styleUrls: ['./code-list-item.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'}
  ],
})
export class CodeListItemComponent implements OnInit {

  @Input() codeId: string;
  @Input() editExpirationDate: Date;
  @Output() optionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() codeToDelete: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('picker') datepicker: MatDatepicker<Date>;
  datepickerStartDate: Date = new Date();
  dateForm : FormGroup;

  constructor(
    private _adapter: DateAdapter<any>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.datepickerConfiguration();
    this.dateForm = new FormGroup({
      date:new FormControl(this.editExpirationDate),
    })
  }

  datepickerConfiguration() : void {
    this._adapter.setLocale('de');
    this._adapter.getDayOfWeekNames = (style: 'long' | 'short' | 'narrow') => {return ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];}
    this._adapter.getFirstDayOfWeek = () => {return 1;}
    this.datepickerStartDate.setDate(this.datepickerStartDate.getDate());
  }

  openCalendar() : void {
    // this.datepicker.open();
  }

  calendarChanged(event): void{
    if (event){
      this.optionChanged.emit(event.value);
    }
  }

  deleteCode() : void {
    this.codeToDelete.emit(true);
  }

}
