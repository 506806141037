import { Injectable } from '@angular/core';

const COMMENT_TEXT_1 = 'Herzlichen Glückwunsch - Sie haben exzellente Kommunikationskompetenzen in MI bewiesen! Die von Ihnen gewählte Kommunikation war (fast) ausschließlich MI-konform. Sie haben es geschafft ihrer Gesprächsperson das Gefühl von Autonomie und Partnerschaftlichkeit zu vermitteln und Empathie auszudrücken. Die Kommunikation, die Sie genutzt haben, hat dazu beitragen, dass ihre Gesprächsperson dazu angeregt wurde lösungsorientiert und zugunsten der Veränderung zu kommunizieren. Behalten Sie sich diese Art der Kommunikation weiter bei und ziehen Sie gegebenenfalls in Betracht noch weiter zu üben, um sich zu verbessern.';
const COMMENT_CLASS_1 = 'darkGreen';
const COMMENT_TEXT_2 = 'Bravo, Sie haben gute Kommunikationskompetenzen in MI bewiesen! Ihnen ist es gelungen, vorwiegend MI-konsistent zu kommunizieren. Versuchen Sie in zukünftigen Gesprächen noch stärker darauf zu achten vor allem den Change Talk (d.h. Aussagen zugunsten der Veränderung) ihrer Gesprächsperson zu reflektieren und zu evozieren. Auch die Betonung der Autonomie spielt eine wichtige Rolle, um die Veränderungsbereitschaft Ihrer Gesprächsperson zu stärken. Übung macht den Meister!';
const COMMENT_CLASS_2 = 'lightGreen';
const COMMENT_TEXT_3 = 'Sie haben häufig eine Kommunikation im Sinne von MI gewählt, es besteht aber noch Verbesserungspotenzial. Fokussieren Sie noch stärker darauf, die Autonomie Ihrer Gesprächsperson zu betonen und sie zur Verbalisierung ihrer Eigenmotivation (Change Talk) anzuregen. Machen Sie sich auch bewusst, in welchen Momenten Sie mit MI-inkonsistenten Verhalten (z.B. Konfrontation, Warnung) reagieren. Bleiben Sie weiter am Ball und üben Sie, um Ihre MI-Kompetenzen zu verbessern.';
const COMMENT_CLASS_3 = 'yellow';
const COMMENT_TEXT_4 = 'Ihnen ist es eher selten gelungen eine MI-konforme Kommunikation zu zeigen. Sie haben noch viel Potenzial darin, vor allem den Change Talk (d.h. Aussagen zugunsten der Veränderung) ihrer Gesprächsperson zu stärken und gleichzeitig die Nutzung von Autonomie-restriktiven Verhaltensweisen zu reduzieren. Versuchen Sie es weiterhin bis Sie mehr Sicherheit im Umgang mit MI haben.';
const COMMENT_CLASS_4 = 'orange';
const COMMENT_TEXT_5 = 'Ihnen ist es nur sehr selten gelungen eine im Sinne von MI effektive Kommunikation zu wählen. Stattdessen sind Sie häufig dem Reflex erlegen, Autonomie-restriktive Verhaltensweisen (z.B. Konfrontation, ungefragte Ratschläge) gegenüber Ihrer Gesprächsperson zu zeigen. Dieses Verhalten hat bedingt, dass Ihre Gesprächsperson sich überwiegend mit Sustain Talk (d.h. Aussagen gegen die Veränderung) geäußert hat und so in den Widerstand gegangen ist. Versuchen Sie es noch einmal und zeigen Sie sich empathisch und partnerschaftlich.';
const COMMENT_CLASS_5 = 'red';

export interface FinalScoreData {
  text: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportTotalTabService {

  constructor() { }

  getCommentTextAndClassForFinalScore(score: number): FinalScoreData {
    if (score >= 81) {
      return {text: COMMENT_TEXT_1, color: COMMENT_CLASS_1};
    } else if (score >= 61) {
      return {text: COMMENT_TEXT_2, color: COMMENT_CLASS_2};
    } else if (score >= 41) {
      return {text: COMMENT_TEXT_3, color: COMMENT_CLASS_3};
    } else if (score >= 21) {
      return {text: COMMENT_TEXT_4, color: COMMENT_CLASS_4};
    } else {
      return {text: COMMENT_TEXT_5, color: COMMENT_CLASS_5};
    }
  }
}
