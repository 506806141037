import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../core/services/auth.service';
import {LoginUser} from '../../models/login-user';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../../../shared/values';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loading: boolean = false;

  constructor(
      private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private authService: AuthService,
      private router: Router,
      private translate: TranslateService) {
    translate.addLangs(LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.createFormGroup();
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group (
        {
          email: ['', [Validators.required, Validators.email]]
        }
    );
  }

  async resetPassword() {
    if (this.resetPasswordForm.invalid) {
      const errorMsg = await this.translate.get('ERROR.invalidEmail').toPromise();
      this.toastr.error(errorMsg);
      return;
    }

    try {
      this.loading = true;
      await this.authService.sendResetPasswordEmail(this.resetPasswordForm.get('email').value);
    } catch (error) {
    } finally {
      const infoMsg = await this.translate.get('INFO.resetPasswordEmail').toPromise();
      this.toastr.info(infoMsg);
      this.router.navigate(['/auth/login']);
      this.loading = false;
    }

  }

}
