import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {ResultAdapter} from '../adapter/resultAdapter';
import {Observable} from 'rxjs';
import {Result} from '../models/result';
import {ConversationItem} from '../models/conversationItem';
import {ConversationItemAdapter} from '../adapter/conversationItemAdapter';
import {ConversationItemResultsAdapter} from '../adapter/conversationItemResultsAdapter';
import firebase from 'firebase';

const COLLECTION_RESULTS = 'results';
const COLLECTION_CONVERSATIONS = 'conversations';
const COLLECTION_CONVERSATION_ITEMS = 'conversationItems';
const COLLECTION_HISTORICAL = 'historical';

@Injectable({
    providedIn: 'root'
})
export class ResultRepositoryService {

    constructor(
        private firestore: AngularFirestore,
        private resultAdapter: ResultAdapter,
        private conversationItemResultsAdapter: ConversationItemResultsAdapter
    ) {
    }

    public getResultForUserAndConversationId(uid: string, conversationId: string): Observable<ConversationItem[]> {
        return this.firestore.collection(COLLECTION_RESULTS)
            .doc(uid)
            .collection(COLLECTION_CONVERSATIONS)
            .doc(conversationId)
            .get()
            .pipe(map(data => {
                    try {
                        return data.data()[COLLECTION_CONVERSATION_ITEMS].map((item) => this.conversationItemResultsAdapter.adapt(item));
                    } catch (e) {
                        return [];
                    }
                })
            );
    }

    public async uploadConversationResult(uid: string, conversationId: string, conversationItems: ConversationItem[]): Promise<void> {
        await this.firestore.collection(COLLECTION_RESULTS).doc(uid).set({uid});
        await this.firestore.collection(COLLECTION_RESULTS)
            .doc(uid)
            .collection(COLLECTION_CONVERSATIONS)
            .doc(conversationId)
            .set(JSON.parse(JSON.stringify({[COLLECTION_CONVERSATION_ITEMS]: conversationItems})));
    }

    public async deleteConversationResults(conversationId: string): Promise<void> {
        const uids = await this.firestore.collection(COLLECTION_RESULTS)
            .get()
            .pipe(map(data => data.docs.map(item => item.id))).toPromise();

        for (const uid of uids) {
            await this.firestore.collection(COLLECTION_RESULTS)
                .doc(uid)
                .collection(COLLECTION_CONVERSATIONS)
                .doc(conversationId)
                .delete();
        }
    }

    /*Historical*/

    public async uploadHistoricalConversationResult(uid: string, conversationId: string, conversationItems: ConversationItem[]): Promise<void> {
        const TODAY = Date.now();
        await this.firestore.collection(COLLECTION_HISTORICAL).doc(uid).set({uid});
        await this.firestore.collection(COLLECTION_HISTORICAL)
            .doc(uid)
            .collection(COLLECTION_CONVERSATIONS)
            .doc(conversationId)
            .set(JSON.parse(JSON.stringify(
                {
                    [TODAY]:{[COLLECTION_CONVERSATION_ITEMS]: conversationItems}
                }
            )), {merge: true});
    }

    public getHistoricalResultForUserAndConversationId(uid: string, conversationId: string): Observable<any> {
        return this.firestore.collection(COLLECTION_HISTORICAL)
            .doc(uid)
            .collection(COLLECTION_CONVERSATIONS)
            .doc(conversationId)
            .get()
            .pipe(map(data => {
                    try {
                        return data.data();
                    } catch (e) {
                        return [];
                    }
                })
            );
    }

    public async deleteOldHistoricalResult(uid: string, conversationId: string, fieldToDelete: string): Promise<void> {
        await this.firestore.collection(COLLECTION_HISTORICAL)
        .doc(uid)
        .collection(COLLECTION_CONVERSATIONS)
        .doc(conversationId)
        .update({[fieldToDelete]: firebase.firestore.FieldValue.delete()});
    }
}



