<div class="chat-layout">
    <div id="contacts-panel">
        <app-contacts-panel></app-contacts-panel>
    </div>
    <div id="avatar-panel">
        <app-avatar-panel></app-avatar-panel>
    </div>
    <div id="chat-panel">
        <app-chat-panel></app-chat-panel>
    </div>
</div>
