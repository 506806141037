import { FirestoreService } from './firestore.service';
import { User } from './../../shared/models/user';
import { LoginRepositoryService } from './../../auth/login/services/login-repository.service';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private userLoggued;
  user$: Observable<User>;

  constructor(
    private loginRepository: LoginRepositoryService,
    private router: Router,
    private firestore: FirestoreService,
    private ngZone: NgZone
  ) {
    this.user$ = firestore.getUserByUid();
  }

  login(loginUser) {
    return this.loginRepository.doLogin(loginUser.email, loginUser.password);
  }

  signUp(loginUser) {
    return this.loginRepository.signUp(loginUser.email, loginUser.password);
  }

  sendResetPasswordEmail(email: string) {
    return this.loginRepository.sendResetPasswordEmail(email);
  }

  verifyPasswordResetCode(code: string) {
    return this.loginRepository.verifyPasswordResetCode(code);
  }

  resetPassword(code: string, password: string) {
    return this.loginRepository.resetPassword(code, password);
  }

  async getCurrentUser() {
    return await this.loginRepository.getCurrentUser();
  }

  isLoggedIn(){
    return this.loginRepository.isLogged();
  }

  async logout() {
    this.loginRepository.doLogout();
    this.ngZone.run(() => {
      this.router.navigate(['/auth/login']);
    });
  }
}
