import { Injectable } from '@angular/core';

const COMMENT_FIRST_SECTION_RED = 'Sie haben Ihrer Gesprächsperson sehr häufig ungefragte Ratschläge gegeben. Auch wenn diese gut gemeint waren: Diese Art der Kommunikation wird in den meisten Fällen als sehr direktiv und Autonomie-einschränkend wahrgenommen und kann Widerstand in der Gesprächsperson hervorrrufen. Wenn Sie zwingend einen Rat loswerden möchten, ist die vorherige Nachfrage, ob dies von Ihrer Gesprächsperson erwünscht ist, sinnvoll.';
const COMMENT_FIRST_SECTION_ORANGE = 'Sie haben Ihrer Gesprächsperson häufig ungefragte Ratschläge gegeben. Auch wenn diese gut gemeint waren: Diese Art der Kommunikation wird in den meisten Fällen als sehr direktiv und Autonomie-einschränkend wahrgenommen und kann Widerstand in der Gesprächsperson hervorrrufen. Wenn Sie zwingend einen Rat loswerden möchten, ist die vorherige Nachfrage, ob dies von Ihrer Gesprächsperson erwünscht ist, sinnvoll.';
const COMMENT_FIRST_SECTION_YELLOW = 'Sie haben Ihrer Gesprächsperson gelegentlich Ratschläge ohne gegeben, ohne zuvor nach ihrer Erlaubnis zu fragen. Ungefragte Ratschläge werden in meisten Fällen als sehr direktiv und Autonomie-einschränkend wahrgenommen und können Widerstand in der Gesprächsperson hervorrrufen. Wenn Sie zwingend einen Rat loswerden möchten, ist die vorherige Nachfrage, ob dies von Ihrer Gesprächsperson erwünscht ist, sinnvoll.';
const COMMENT_FIRST_SECTION_LIGHTGREEN = 'Sie haben Ihrer Gesprächsperson selten Ratschläge ohne Erlaubnis gegeben. Versuchen Sie zukünftig dem Reflex (gut gemeinte) Ratschläge zu geben noch stärker zu widerstehen und konzentrieren Sie sich stattdessen auf Aktives Zuhören.';
const COMMENT_FIRST_SECTION_DARKGREEN = 'Sie haben Ihrer Gesprächsperson nie oder nur sehr selten Ratschläge ohne Erlaubnis gegeben, gute Arbeit! Behalten Sie sich diese Art der Kommunikation weiter bei und versuchen Sie auch zukünftig auf ungefragte Ratschläge zu verzichten, da diese Widerstand in Ihrer Gesprächsperson erzeugen können.';

const COMMENT_SECOND_SECTION_RED = 'Sie haben Ihre Gesprächsperson sehr häufig konfrontiert, gewarnt, einen Befehl bzw. eine Anweisung erteilt oder Besorgnis angesprochen. Diese Art der Kommunikation wird in den meisten Fällen als sehr direktiv und Autonomie-einschränkend wahrgenommen und kann Widerstand in der Gesprächsperson hervorrrufen. Denken Sie daran, auf diese Verhaltensweisen zu verzichten.';
const COMMENT_SECOND_SECTION_ORANGE = 'Sie haben Ihre Gesprächsperson häufig konfrontiert, gewarnt, einen Befehl bzw. eine Anweisung erteilt oder Besorgnis angesprochen. Diese Art der Kommunikation wird in den meisten Fällen als sehr direktiv und Autonomie-einschränkend wahrgenommen und kann Widerstand in der Gesprächsperson hervorrrufen. Denken Sie daran, auf diese Verhaltensweisen zu verzichten.';
const COMMENT_SECOND_SECTION_YELLOW = 'Ihre Kommunikation war gelegentlich geprägt von Konfrontationen, Warnungen, Befehlen und Anweisungen oder dem Ansprechen von Besorgnis. Denken Sie daran auf diese Art der Kommunikation noch stärker zu verzichten, da sie in meisten Fällen als sehr direktiv und Autonomie-einschränkend wahrgenommen wird und Widerstand in der Gesprächsperson hervorrrufen kann.';
const COMMENT_SECOND_SECTION_LIGHTGREEN = 'Sie haben Ihre Gesprächsperson selten konfrontiert, gewarnt, einen Befehl bzw. eine Anweisung erteilt oder Besorgnis angesprochen. Der Verzicht auf diese negativen Verhaltensweisen ist wichtig, um Ihrer Gesprächsperson das Gefühl von Respekt und Autonomie zu geben.';
const COMMENT_SECOND_SECTION_DARKGREEN = 'Sie haben Ihre Gesprächsperson nie oder nur sehr selten Konfrontationen, Warnungen, Befehle und Anweisungen genutzt oder Besorgnis angesprochen. Indem Sie auf diese Verhaltensweisen (weitestgehend) verzichtet haben, ist es Ihnen gelungen die Autonomie Ihrer Gesprächsperson zu wahren und die Wahrscheinlichkeit zur Evokation intrinischer Motivation für eine Veränderung erhöht.';

@Injectable({
  providedIn: 'root'
})
export class ReportMiInkonsistenTabService {

  constructor() { }

  getCommentForFirstSection(percentage: number): string {
    if (percentage >= 81) {
      return COMMENT_FIRST_SECTION_RED;
    } else if (percentage >= 61) {
      return COMMENT_FIRST_SECTION_ORANGE;
    } else if (percentage >= 41) {
      return COMMENT_FIRST_SECTION_YELLOW;
    } else if (percentage >= 21) {
      return COMMENT_FIRST_SECTION_LIGHTGREEN;
    } else {
      return COMMENT_FIRST_SECTION_DARKGREEN;
    }
  }

  getCommentForSecondSection(percentage: number): string {
    if (percentage >= 81) {
      return COMMENT_SECOND_SECTION_RED;
    } else if (percentage >= 61) {
      return COMMENT_SECOND_SECTION_ORANGE;
    } else if (percentage >= 41) {
      return COMMENT_SECOND_SECTION_YELLOW;
    } else if (percentage >= 21) {
      return COMMENT_SECOND_SECTION_LIGHTGREEN;
    } else {
      return COMMENT_SECOND_SECTION_DARKGREEN;
    }
  }
}
