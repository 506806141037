import { ExpiredLayoutComponent } from './components/expired-layout/expired-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ChatLayoutComponent} from './components/chat-layout/chat-layout.component';
import {ReportComponent} from './components/report/report.component';


const routes: Routes = [
    { path: 'chat', component: ChatLayoutComponent },
    { path: 'report/:id', component: ReportComponent },
    { path: 'expiredsession', component:  ExpiredLayoutComponent}
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
