// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domainUrl: 'http://localhost:8082/',
  rootUrl: 'http://localhost:8082/',
  // domainUrl: 'https://mi-tool-be.4a-side.zone/backend/public/index.php/',
  // rootUrl: 'https://mi-tool-be.4a-side.zone/backend/public/',
  firebaseConfig: {
    apiKey: 'AIzaSyDf0UA_cZ808LbaTFUsFNu-B_khuh4eVic',
    authDomain: 'mi-tool-dev.firebaseapp.com',
    databaseURL: 'https://mi-tool-dev.firebaseio.com',
    projectId: 'mi-tool-dev',
    storageBucket: 'mi-tool-dev.appspot.com',
    messagingSenderId: '731348366155',
    appId: '1:731348366155:web:a67f00b5df33f38acf6ece',
    measurementId: 'G-51YMM19QK2'
  },
  ttsEndpoints:{
    sendItemsToConvert: 'requestTTS',
    deleteConversation: 'deleteTTS'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
