<div id="contact-item">
    <div class="image-area" [style.background-image]="'url(' + conversation.image3 + ')'"></div>
    <div class="info-area">
            <span class="contactName">{{ conversation.name }}</span>
            <span class="contactPosition">{{ conversation.topic }}</span>
    </div>
    <div class="icons-area" *ngIf="showIcons">
        <div *ngIf="showCameraIcon" class="videoIcon">
            <button mat-icon-button (click)="newConversation()"><fa-icon [icon]="faVideo"></fa-icon></button>
        </div>
        <div *ngIf="showDocumentIcon" class="documentIcon">
            <button mat-icon-button (click)="viewResults()"><fa-icon [icon]="faFileAlt"></fa-icon></button>
        </div>
    </div>
</div>
