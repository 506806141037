import {Injectable} from '@angular/core';
import {ConversationItem} from '../../shared/models/conversationItem';
import {ResultService} from './result.service';

@Injectable({providedIn: 'root'})
export class ConversationService {
    private conversationItems: ConversationItem[];

    constructor(
        private resultService: ResultService
    ) {
    }

    /**
     * Add a ConversationItem to the current stack. It can be a question or an answer item
     */
    add(conversationItem: ConversationItem): void {
        this.conversationItems.push(conversationItem);
    }

    /**
     * Empty the current stack. This is usefull when there is a new conversation
     */
    clear(): void {
        this.conversationItems = [];
    }

    /**
     * It returns all the ConversationItems of the current stack
     */
    getConversation(): ConversationItem[] {
        return this.conversationItems;
    }

    /**
     * Given a conversationId, this method saves the ConversationItems of the current stack to the results data
     * store of this conversationId.
     */
    async save(conversationId: string): Promise<void> {
        await this.resultService.uploadConversationResults(conversationId, this.conversationItems);
    }

}
