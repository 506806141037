import {Component, Input, OnInit} from '@angular/core';
import { faSmile, faFrown, faMeh } from '@fortawesome/free-solid-svg-icons';
import {
  RATING_QUESTION_BAD_VALUE,
  RATING_QUESTION_GOOD_VALUE,
  RATING_QUESTION_NEUTRAL_VALUE
} from '../../../../services/question-rating.service';

@Component({
  selector: 'app-chat-current-question-rating',
  templateUrl: './chat-current-question-rating.component.html',
  styleUrls: ['./chat-current-question-rating.component.scss']
})
export class ChatCurrentQuestionRatingComponent implements OnInit {
  @Input() questionRatingText: string;
  @Input() questionRatingValue: string;

  constructor() { }

  ngOnInit(): void {
  }

  getIcon() {
    switch (this.questionRatingValue) {
      case RATING_QUESTION_GOOD_VALUE:
        return faSmile;
      case RATING_QUESTION_BAD_VALUE:
        return faFrown;
      case RATING_QUESTION_NEUTRAL_VALUE:
        return faMeh;
    }
  }

}
