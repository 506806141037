import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ReportService} from '../../../../services/report/report.service';

interface Data {
  title: string;
  experte: string;
  'basis-kompetenz': string;
  value: string;
  color: string;
  miInkonsistentColor: boolean;
}

@Component({
  selector: 'app-report-vergleichseite-tab',
  templateUrl: './vergleichseite-tab.component.html',
  styleUrls: ['./vergleichseite-tab.component.scss']
})
export class VergleichseiteTabComponent implements OnInit {
  displayedColumns: string[] = ['verhaltensma', 'experte', 'basis-kompetenz', 'value'];
  dataSource: MatTableDataSource<any>;

  constructor(
      private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.loadReports();
  }

  loadReports(): void {
    this.reportService.onReportResults().subscribe(results => {
      if (results.length) {
        this.setTableResults();
      }
    });
  }

  setTableResults(): void {
    const offeneFrageStat = this.reportService.getTagStats(['oE+', 'oE-']);
    const reflexionenStat = this.reportService.getTagStats(['RE+', 'RE-']);
    const miInkonsistenStat = this.reportService.getTagStats(['r', 't']);
    const ratio = this.reportService.getRatioBetweenValues(offeneFrageStat.percentage, reflexionenStat.percentage);

    const data = new Array<Data>(
        {
          title: '% offene Frage',
          experte: '>70%',
          'basis-kompetenz': '>50%',
          value: `${offeneFrageStat.percentage}`,
          color: '',
          miInkonsistentColor: false
        },
        {
          title: '% Komplexe Reflexionen',
          experte: '>50%',
          'basis-kompetenz': '>40%',
          value: `${reflexionenStat.percentage}`,
          color: '',
          miInkonsistentColor: false
        },
        {
          title: 'Verhältnis: ,,Aktives Zuhören" zu ,,Fragen"',
          experte: '>2:1',
          'basis-kompetenz': '>1:1',
          value: `${ratio.ratio}`,
          color: ratio.color,
          miInkonsistentColor: false
        },
        {
          title: '% MI-Inkonsistentes Verhalten',
          experte: '0%',
          'basis-kompetenz': '<10%',
          value: `${miInkonsistenStat.percentage}`,
          color: '',
          miInkonsistentColor: true
        },
    );

    this.dataSource = new MatTableDataSource<any>(data);
  }
}
