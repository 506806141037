import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../../shared/adapter/date.adapter';
import { ConversationRepositoryService } from '../../../../shared/services/conversation-repository.service';
import { ConversationToConversationItemMapper } from '../../../mappers/conversationToConversationItemMapper';
import { ConversationDetails } from '../../../../shared/models/conversationDetails';
import { CodeDetails } from '../../../../shared/models/codeDetails';
import { CreateEditTrainerervice } from '../../../services/create-edit-trainer.service';
import { CodeManagementFormToCodeDataMapper } from '../../../mappers/codeManagementFormToCodeDataMapper';
import { CodeData } from '../../../../shared/models/codeData';
import { CreateEditCodeService } from '../../../services/create-edit-code.service';
import { CodeToStore } from '../../../../shared/models/codeToStore';
import { ConversationTrainerToStore } from '../../../../shared/models/conversationTrainerToStore';
import { CreateEditConversationTrainerService } from '../../../services/create-edit-conversationTrainer.service';
import { UserCodeDetails } from '../../../../shared/models/userCodeDetails';
import { DateTransformService } from '../../../../shared/services/dateTransform.service';
import { ConversationTrainer } from '../../../../shared/models/conversationTrainer';
import { CodeDetailsToCodeToStoreMapper } from '../../../mappers/codeDetailsToCodeToStoreMapper';

@Component({
  selector: 'app-code-management-form',
  templateUrl: './code-management-form.component.html',
  styleUrls: ['./code-management-form.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
  },
  {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
  }
  ]
})
export class CodeManagementFormComponent implements OnInit {

  @Output() submitted = new EventEmitter<boolean>();
  loading = false;
  codeForm: FormGroup;
  conversationList: Array<ConversationDetails> = new Array();
  codeList: Array<CodeDetails> = new Array();
  codeListInitial: Array<CodeDetails> = new Array();
  showExpirationDateErrorValidation: boolean = false;
  conversationsClicked: Array<ConversationDetails> = new Array();
  @Input() userCodeDetails: UserCodeDetails;
  @Input() editMode: boolean;
  @Input() saveButtonText: string;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private conversationService: ConversationRepositoryService,
    private createEditTrainerervice: CreateEditTrainerervice,
    private createEditCodeService:  CreateEditCodeService,
    private createEditConversationTrainerService: CreateEditConversationTrainerService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadConversations();
    if (this.editMode) {
      this.loadCodesForList();
    }
  }

  loadCodesForList() {
    this.userCodeDetails.codes.forEach(code => {
      let codeDetails: CodeDetails = new CodeDetails();
      codeDetails.activationCode = code.activationCode;
      codeDetails.expirationDate = DateTransformService.timeStampLongFormatToDate(code.expirationDateTimestamp);
      codeDetails.codeId = code.id;
      this.codeList.push(codeDetails);
      this.codeListInitial.push(codeDetails);
    });
  }

  loadConversations(): void {
    this.conversationService.getConversations().subscribe(
        (conversations) => {
            this.conversationList = [];
            conversations.forEach(element => {
              this.conversationList.push(ConversationToConversationItemMapper.map(element, this.userCodeDetails.conversationsTrainer));
            });
            let conversationListClicked: Array<ConversationDetails> = this.conversationList.filter(element => element.checkedOption == true);
            conversationListClicked.forEach(item => {
              this.conversationsClicked.push(item);
            });
        },
        (error) => {
            this.toastr.error(error);
        }
    );
  }

  createForm(): void {
    this.codeForm = this.formBuilder.group({
        name: [this.userCodeDetails.name, Validators.required],
        description: [this.userCodeDetails.description, Validators.required]
    });
  }

  onCancel(): void {
    this.submitted.emit(true);
  }

  submitForm(): void {
    if (this.codeForm.invalid) {
        return;
    }

    if (this.checkAnyInvalidExpirationDate()) {
      return;
    }

    this.loading = true;

    if (this.editMode) {
      this.editTrainer();
    } else {
      this.createTrainer();
    }

  }

  checkAnyInvalidExpirationDate(): boolean {
    if (this.codeList.length > 0) {
      let codeDetailFound: Array<CodeDetails> = this.codeList.filter(element => element.expirationDate == null);
      if (codeDetailFound.length > 0) {
        this.showExpirationDateErrorValidation = true;
        return true;
      }
    }
    this.showExpirationDateErrorValidation = false;
    return false;
  }

  createTrainer() {
    this.createEditTrainerervice.createTrainer(this.codeForm.value)
        .then((value ) => {
          let trainerIdToStore: string = value;
          let resultStructure: CodeData = CodeManagementFormToCodeDataMapper.map(
            trainerIdToStore,
            this.codeList,
            this.conversationsClicked
          );
          resultStructure.codes.forEach(codeToStore => {
            this.createCode(codeToStore);
          });
          resultStructure.conversationsTrainer.forEach(conversationTrainerToStore => {
            this.createConversationsTrainer(conversationTrainerToStore);
          });
          this.submitted.emit(true);
          this.toastr.info('Trainer created');
        })
        .catch(error => this.toastr.error(error))
        .finally(() => this.loading = false);
      error => {
          this.toastr.error(error);
          this.loading = false;
      }
  }

  editTrainer() {
    this.createEditTrainerervice.editTrainer(this.codeForm.value, this.userCodeDetails.id)
      .then((value ) => {
        this.codeListInitial.forEach(code => {
          let findCodeInList: CodeDetails = this.codeList.find(element => element.codeId == code.codeId);
          if (findCodeInList != undefined) {
            this.editCode(CodeDetailsToCodeToStoreMapper.map(findCodeInList, this.userCodeDetails.id), code.codeId);
            this.deleteCodeFromList(findCodeInList);
          } else {
            this.deleteCode(code.codeId);
          }
        });

        let resultStructure: CodeData = CodeManagementFormToCodeDataMapper.map(
          this.userCodeDetails.id,
          this.codeList,
          this.conversationsClicked
        );
        
        resultStructure.codes.forEach(codeToStore => {
          this.createCode(codeToStore);
        });
        this.userCodeDetails.conversationsTrainer.forEach(conversationTrainerToDelete => {
          this.deleteConversationTrainer(conversationTrainerToDelete.id);
        });
        resultStructure.conversationsTrainer.forEach(conversationTrainerToStore => {
          this.createConversationsTrainer(conversationTrainerToStore);
        });
        this.submitted.emit(true);
        this.toastr.info('Trainer edited');
      })
      .catch(error => this.toastr.error(error))
      .finally(() => this.loading = false);
    error => {
        this.toastr.error(error);
        this.loading = false;
    }
  }

  createCode(codeToStore: CodeToStore) {
    this.createEditCodeService.createCode(codeToStore)
      .then(() => {
      })
      .catch(error => this.toastr.error(error))
      .finally(() => this.loading = false);
    error => {
        this.toastr.error(error);
        this.loading = false;
    }
  }

  editCode(codeToStore: CodeToStore, codeId: string) {
    this.createEditCodeService.editCode(codeToStore, codeId)
      .then(() => {
      })
      .catch(error => this.toastr.error(error))
      .finally(() => this.loading = false);
    error => {
        this.toastr.error(error);
        this.loading = false;
    }
  }

  deleteCode(codeId: string) : void {
    this.createEditCodeService.deleteCode(codeId)
    .then(() => {
    })
    .catch(error => this.toastr.error(error))
    .finally(() => this.loading = false);
    error => {
        this.toastr.error(error);
        this.loading = false;
    }
  }

  createConversationsTrainer(conversationTrainerToStore: ConversationTrainerToStore) {
    this.createEditConversationTrainerService.createConversationsTrainer(conversationTrainerToStore)
    .then(() => {
    })
    .catch(error => this.toastr.error(error))
    .finally(() => this.loading = false);
    error => {
        this.toastr.error(error);
        this.loading = false;
    }
  }

  deleteConversationTrainer(converationTrainerId: string) : void {
    this.createEditConversationTrainerService.deleteConversationTrainer(converationTrainerId)
    .then(() => {
    })
    .catch(error => this.toastr.error(error))
    .finally(() => this.loading = false);
    error => {
        this.toastr.error(error);
        this.loading = false;
    }
  }

  addNewCode(): void {
    let encodedStringBtoA = btoa(Date.now().toString());

    let codeDetails: CodeDetails = new CodeDetails();
    codeDetails.activationCode = encodedStringBtoA;
    codeDetails.expirationDate = null;

    this.codeList.unshift(codeDetails);

  }

  updateExpirationDate(event, index: number):  void {
    this.codeList[index].expirationDate = event;
  }

  updateCheckbox(event, item) {
    if (event) {
        this.conversationsClicked.push(item);
    } else {
        this.conversationsClicked.splice(this.conversationsClicked.indexOf(item), 1);
    }
  }

  deleteCodeFromList(item:CodeDetails) : void {
    this.codeList.splice(this.codeList.indexOf(item), 1);
  }
}
