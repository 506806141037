import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-current-answer',
  templateUrl: './chat-current-answer.component.html',
  styleUrls: ['./chat-current-answer.component.scss']
})
export class ChatCurrentAnswerComponent implements OnInit {
  @Input() answerText: string;
  @Input() avatarImage: string;

  constructor() { }

  ngOnInit(): void {
  }

}
