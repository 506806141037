import { AuthService } from './../services/auth.service';
import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {
  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router, private ngZone: NgZone){

  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
        this.authService.getCurrentUser().then(currentUser => {
            const loggedIn = !!currentUser;

            if (loggedIn) {
                // if is logged, then check if is admin
                this.authService.user$.pipe(
                    take(1),
                    map(user => {
                        return user && user.admin ? true : false;
                    }),
                    tap(isAdmin => {
                        if (isAdmin){
                            this.ngZone.run(() => {
                                this.router.navigate(['/admin']);
                            });
                            return resolve(false);
                        } else {
                            return resolve(true);
                        }
                    })
                ).subscribe();
            } else {
                this.router.navigate(['/auth/login']);
                return resolve(false);
            }
        });
    });

  }
}
