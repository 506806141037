<div class="report-container">
    <div class="historicalButtons">
        <button *ngFor="let resultItem of historicalItems | keyvalue" [ngClass]="firstResult === resultItem.key ? 'firstResultSelected': 'historicalItemButton'" id="{{resultItem.key}}" (click)="colorToggle(resultItem.key)" (click)="loadResultPage(resultItem.key)" mat-raised-button class="mr-3" title="historical" aria-label="historical">
            <fa-icon class="pr-2 {{resultItem.value}}" [icon]="pollIcon"></fa-icon>
            {{resultItem.key | date: "dd.MM.yyyy" }}
        </button>
    </div>
    <button mat-mini-fab class="exportButton" (click)="exportPdf()" title="Export to PDF" aria-label="Export to PDF">
        <fa-icon [icon]="exportIcon"></fa-icon>
    </button>
    <app-contact-header></app-contact-header>
    <mat-tab-group [(selectedIndex)]="selectedTab" animationDuration="0ms">
        <mat-tab label="Kommunikationsmethoden MI-Konsistent">
            <app-report-mi-konsistent-tab></app-report-mi-konsistent-tab>
        </mat-tab>
        <mat-tab label="Kommunikationsmethoden MI-Inkonsistent">
            <app-report-mi-inkonsistent-tab></app-report-mi-inkonsistent-tab>
        </mat-tab>
        <mat-tab label="Gesprächsphasen">
            <app-report-gesprachsphasen-tab></app-report-gesprachsphasen-tab>
        </mat-tab>
        <mat-tab label="Vergleichseite">
            <app-report-vergleichseite-tab></app-report-vergleichseite-tab>
        </mat-tab>
        <mat-tab label="Veränderungsbereitschaft">
            <app-report-veranderungsbereitschaft-tab></app-report-veranderungsbereitschaft-tab>
        </mat-tab>
        <mat-tab label="Übersicht Gesamtwertung">
            <ng-template matTabContent>
                <app-report-total-tab></app-report-total-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Export" *ngIf="isExport" >
            <app-report-mi-konsistent-tab></app-report-mi-konsistent-tab>
            <app-report-mi-inkonsistent-tab></app-report-mi-inkonsistent-tab>
            <app-report-gesprachsphasen-tab></app-report-gesprachsphasen-tab>
            <app-report-vergleichseite-tab></app-report-vergleichseite-tab>
            <app-report-veranderungsbereitschaft-tab></app-report-veranderungsbereitschaft-tab>
            <app-report-total-tab [showPopup]="false"></app-report-total-tab>
        </mat-tab>
    </mat-tab-group>
</div>
