import {Component, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-new-conversation-dialog',
  templateUrl: 'new-conversation-dialog.html',
  styleUrls: ['./new-conversation-dialog.scss']

})
export class NewConversationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NewConversationDialogComponent>,
   ) {
    dialogRef.disableClose = true;
  }

  onSubmitted(submitted: boolean) {
      this.dialogRef.close();
  }
}
