import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {Result} from '../models/result';

@Injectable({
    providedIn: 'root',
})
export class ResultAdapter implements Adapter<Result> {
    adapt(item: any, id: string, conversationId: string): Result {
        if (item.data()) {
            return new Result(id, conversationId, item.data());
        } else {
            return null;
        }
    }

}
