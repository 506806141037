import { ConversationDetails } from 'src/app/shared/models/conversationDetails';
import { Conversation } from '../../shared/models/conversation';
import { ConversationTrainer } from '../../shared/models/conversationTrainer';

export class ConversationToConversationItemMapper {

  static map(conversation: Conversation, conversationsTrainer: Array<ConversationTrainer>): ConversationDetails {
      const result :ConversationDetails = new ConversationDetails();
      result.id = conversation.id;
      result.name = conversation.name;

      // to load checkbox on edit
      if(conversationsTrainer.find(element => element.conversationId == conversation.id)){
        result.checkedOption = true;
      }

    return result;
  }
}