import { ConversationTrainerDto } from '../dto/conversationTrainerDto';

export class ConversationTrainer {
    id: string;
    conversationId: string;
    trainerId: string;

    constructor(id: string, data: ConversationTrainerDto) {
        this.id = id;
        this.conversationId = data.conversationId;
        this.trainerId = data.trainerId;
    }

}