import { Gender } from './../../shared/models/gender';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreateEditConversationService} from '../services/create-edit-conversation.service';
import {ToastrService} from 'ngx-toastr';
import {MatInput} from '@angular/material/input';
import {Conversation} from '../../shared/models/conversation';
import {FileValidator} from '../../shared/validators/file-validators';
import {ConversationItem} from '../../shared/models/conversationItem';

@Component({
    selector: 'app-conversation-edit-form',
    templateUrl: './conversation-edit-form.component.html',
    styleUrls: ['./conversation-edit-form.component.scss']
})
export class ConversationEditFormComponent implements OnInit {
    @Input() conversation: Conversation;
    @Output() submitted = new EventEmitter<boolean>();
    @ViewChild('inputName', {static: true}) inputName: MatInput;
    conversationForm: FormGroup;
    loading = false;
    selectNewImages;
    genderValue: string;

    genders: Gender[] = [
        {code: 'woman', value: 'Weibliche Stimme'},
        {code: 'man', value: 'Männliche Stimme'}
    ];

    constructor(
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private createEditConversationService: CreateEditConversationService
    ) {
    }

    ngOnInit(): void {
        this.createForm();

        this.loadData();
    }

    onCancel(): void {
        this.submitted.emit(true);
    }

    createForm(): void {
        const allowedImageExtensions = ['png', 'jpg', 'jpeg'];

        this.conversationForm = this.formBuilder.group({
            name: ['', Validators.required],
            topic: ['', Validators.required],
            image1: [null, [FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image2: [null, [FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image3: [null, [FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image4: [null, [FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            image5: [null, [FileValidator.fileMinSize(1), FileValidator.fileExtensions(allowedImageExtensions)]],
            conversation: [null, [FileValidator.fileMinSize(1), FileValidator.fileExtensions(['html', 'htm'])]],
            gender: ['', Validators.required]
        });
    }

    loadData(): void {
        this.conversationForm.patchValue({
            name: this.conversation.name,
            topic: this.conversation.topic,
        });

        this.selectNewImages = {
            1: !this.conversation.image1,
            2: !this.conversation.image2,
            3: !this.conversation.image3,
            4: !this.conversation.image4,
            5: !this.conversation.image5,
        };
    }

    changeImage(id: number): void {
        this.selectNewImages[id] = true;
    }

    submitForm(): void {
        if (this.conversationForm.invalid) {
            return;
        }

        this.loading = true;

        if (this.conversationForm.value.conversation) {
            this.createEditConversationService.uploadConversationFlow(this.conversationForm.value.conversation.files[0],
                conversationItems => this.saveEditConversation(this.conversation, this.conversationForm.value, conversationItems),
                error => {
                    this.toastr.error(error);
                    this.loading = false;
                }
            );
        } else {
            this.saveEditConversation(this.conversation, this.conversationForm.value)
        }

    }

    private saveEditConversation(conversation: Conversation, data, conversationItems?: ConversationItem[]): void {
      this.createEditConversationService.editConversation(this.conversation, this.conversationForm.value, conversationItems)
          .then(() => {
            this.submitted.emit(true);
            this.toastr.info('Conversation updated');
          })
          .catch(error => this.toastr.error('An error has occurred. Please try again'))
          .finally(() => this.loading = false);
    }

}
