import { Code } from './../models/code';
import { CodeAdapter } from './../adapter/codeAdapter';
import { CodeRepositoryService } from './code-repository.service';
import { CodeDto } from './../dto/codeDto';
import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Config} from '../models/config';
import {map} from 'rxjs/operators';
import { sha256 } from 'js-sha256';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject } from 'rxjs';

const COLLECTION_CONFIG = 'config';
const COLLECTION_DOCUMENT_CONFIG = 'config';
const COLLECTION_CODES = 'codes';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private validCodeForUser = new BehaviorSubject<string>(null);
  private errorCode = new BehaviorSubject<string>(null);

  constructor(
      private firestore: AngularFirestore,
      private codeRepositoryService: CodeRepositoryService,
      private toastr: ToastrService,
      private codeAdapter: CodeAdapter
  ) { }

   /**
     * Subscriber for code changes
     */
  onChangedCode(): Observable<string> {
    return this.validCodeForUser.asObservable();
  }

  onErrorCode(): Observable<string> {
    return this.errorCode.asObservable();
  }

  restoreErrorCode(status: string): void {
    return this.errorCode.next(status);
  }

  async getConfig(): Promise<Config> {
    const config = await this.firestore
        .collection(COLLECTION_CONFIG)
        .doc<Config>(COLLECTION_DOCUMENT_CONFIG)
        .get()
        .pipe(map(data => data.data() as Config))
        .toPromise();

    return config;
  }


  async checkSignupCode(code: string): Promise<boolean> {
    const TODAY = Date.now();
    let codeValid = '';
    let codeInUse = false;
    const collectionIds = await this.firestore.collection(COLLECTION_CODES)
    .get()
    .pipe(map(data => data.docs.map(item => item.id))).toPromise();
    
    for(const collectionId of collectionIds){
      const codeAviable = await this.firestore.collection(COLLECTION_CODES)
      .doc(collectionId)
      .get()
      .pipe(map((data: any) => { 
       if(data.data().activationCode === code && data.data().expirationDate >= TODAY && data.data().userId === ''){
          codeValid = data.data().activationCode;
          this.validCodeForUser.next(collectionId);
       }else if(data.data().activationCode === code && data.data().expirationDate >= TODAY && data.data().userId !== ''){
          codeValid = '';
          codeInUse = true;
          this.restoreErrorCode('code in use');
       }else if(data.data().activationCode === code && data.data().expirationDate < TODAY && !codeInUse){
         codeValid = '';
         this.restoreErrorCode(data.data().expirationDate);
       }
      })).toPromise();
    }
    return codeValid !== '' ? true : false;
  }


  async checkUserSignupCode(userId: string): Promise<string> {
    const TODAY = Date.now();
    let response = '';
    const collectionIds = await this.firestore.collection(COLLECTION_CODES)
    .get()
    .pipe(map(data => data.docs.map(item => item.id))).toPromise();
    
    for(const collectionId of collectionIds){
      const codeAviable = await this.firestore.collection(COLLECTION_CODES)
      .doc(collectionId)
      .get()
      .pipe(map((data: any) => { 
       if(data.data().userId === userId && data.data().expirationDate >= TODAY){
          response = 'user valid';
       }else if(data.data().userId === userId && data.data().expirationDate < TODAY){
          response = 'code expired';
       }
      })).toPromise();
    }
    return response;
  }

  async checkExpirationDateSignupCode(userId: string): Promise<string> {
    let response = '';
    const collectionIds = await this.firestore.collection(COLLECTION_CODES)
    .get()
    .pipe(map(data => data.docs.map(item => item.id))).toPromise();
    
    for(const collectionId of collectionIds){
      const codeAviable = await this.firestore.collection(COLLECTION_CODES)
      .doc(collectionId)
      .get()
      .pipe(map((data: any) => { 
       if(data.data().userId === userId){
          response = data.data().expirationDate;
       }
      })).toPromise();
    }
    return response;
  }


  async getTrainerIdSignupCode(userId: string): Promise<string> {
    let response = '';
    const collectionIds = await this.firestore.collection(COLLECTION_CODES)
    .get()
    .pipe(map(data => data.docs.map(item => item.id))).toPromise();
    
    for(const collectionId of collectionIds){
      const codeAviable = await this.firestore.collection(COLLECTION_CODES)
      .doc(collectionId)
      .get()
      .pipe(map((data: any) => { 
       if(data.data().userId === userId){
          response = data.data().trainerId;
       }
      })).toPromise();
    }
    return response;
  }

}
