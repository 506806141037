<div class="menu-desktop" *ngIf="isLogged">
    <div class="profile-area">
        <button id="profile" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>person</mat-icon></button>
        <mat-menu #menu="matMenu" class="custom-menu">
            <button mat-menu-item class="headerMenuOption" (click)="deleteAccount()">
                <mat-icon>person_remove</mat-icon>
                <span>{{ 'PROFILE.deleteAccount' | translate }}</span>
            </button>
            <button mat-menu-item class="headerMenuOption" (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>{{ 'PROFILE.logout' | translate }}</span>
            </button>
        </mat-menu>
    </div>
</div>
