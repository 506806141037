<div class="avatar-panel">

    <div class="avatar-container" *ngIf="avatar">
        <div class="avatar-content">
            <div class="avatar">
                <div class="img" [style.background-image]="'url(' + avatar + ')'"></div>
                <span>{{ 'CONVERSATION.cameraOff' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="no-conversation" *ngIf="!avatar">
        <img src="/assets/icons/message-icon.png" alt="No conversation"/>
        <p>{{ 'CONVERSATION.notStartedYet' | translate }}</p>
    </div>

    <div class="chart-container" *ngIf="avatar">
        <app-rindex-chart *ngIf="rIndex.length > 1" [data]="rIndex" [inverseColors]="true"></app-rindex-chart>
    </div>

    <div *ngIf="avatar" class="avatar-footer">
        <app-avatar-footer></app-avatar-footer>
    </div>

</div>
