import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {LoginRepositoryService} from '../../auth/login/services/login-repository.service';

const COLLECTION_RESULTS = 'results';
const COLLECTION_CONVERSATIONS = 'conversations';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private firestore: AngularFirestore,
        private loginRepository: LoginRepositoryService
    ) {
    }

    public async deleteUser(userId: string, password: string): Promise<boolean> {

        /* It doesn't delete the conversation results
        const conversationIds = await this.firestore.collection(COLLECTION_RESULTS)
            .doc(userId)
            .collection(COLLECTION_CONVERSATIONS)
            .get()
            .pipe(map(data => data.docs.map(item => item.id))).toPromise();

        for (const conversationId of conversationIds) {
            await this.firestore.collection(COLLECTION_RESULTS)
                .doc(userId)
                .collection(COLLECTION_CONVERSATIONS)
                .doc(conversationId)
                .delete();
        }

        await this.firestore.collection(COLLECTION_RESULTS)
            .doc(userId)
            .delete();
        */

        return await this.loginRepository.deleteCurrentUser(password);
    }
}



