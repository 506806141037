import { Component, OnInit, Input } from '@angular/core';

const CLASS_NAMES = [
  'darkGreen',
  'lightGreen',
  'yellow',
  'orange',
  'red',
];

@Component({
  selector: 'app-percentage-colour',
  templateUrl: './percentage-colour.component.html',
  styleUrls: ['./percentage-colour.component.scss']
})
export class PercentageColourComponent implements OnInit {

  @Input() number: number;
  @Input() inverseColors: boolean = false;
  @Input() showSymbol: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  get classNumber(): string {
    if (this.number >= 81) {
      return this.getClassName(0);
    } else if (this.number >= 61) {
      return this.getClassName(1);
    } else if (this.number >= 41) {
      return this.getClassName(2);
    } else if (this.number >= 21) {
      return this.getClassName(3);
    } else {
      return this.getClassName(4);
    }
  }

  getClassName(level: number): string {
    if (this.inverseColors) {
      return CLASS_NAMES[CLASS_NAMES.length - level - 1];
    } else {
      return CLASS_NAMES[level];
    }
  }
}
