import { Component, OnInit } from '@angular/core';
import {ChatService} from '../../services/chat.service';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../../shared/values';

@Component({
  selector: 'app-avatar-panel',
  templateUrl: './avatar-panel.component.html',
  styleUrls: ['./avatar-panel.component.scss']
})
export class AvatarPanelComponent implements OnInit {
  avatar: string;
  rIndex: number[] = [];

  constructor(
    private chatService: ChatService,
    private translate: TranslateService
  ) {
    translate.addLangs(LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.checkAvatar();
    this.checkRIndex();
  }

  checkAvatar(): void {
    this.chatService.onChangedAvatar().subscribe(avatar => this.avatar = avatar);
  }

  checkRIndex(): void {
    this.chatService.getRIndex().subscribe(rIndex => {
      this.rIndex = [...rIndex];
    });
  }

}
