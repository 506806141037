import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ImpressumComponent} from './components/impressum/impressum.component';
import {DatenschutzComponent} from './components/datenschutz/datenschutz.component';
import {DatenschutzbestimmungenComponent} from './components/datenschutzbestimmungen/datenschutzbestimmungen.component';

const routes: Routes = [
    {path: 'impressum', component: ImpressumComponent},
    {path: 'datenschutz', component: DatenschutzComponent},
    {path: 'datenschutzbestimmungen', component: DatenschutzbestimmungenComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CoreRoutingModule {
}
