import {Injectable} from '@angular/core';
import {ConversationItem} from '../../shared/models/conversationItem';
import {Conversation} from '../../shared/models/conversation';

@Injectable({providedIn: 'root'})
export class AvatarMoodService {
    rating: number = 0;

    /**
     * Reset the rating / avatar mood. This is useful when there is a new conversation
     */
    reset(): void {
        this.rating = 0;
    }

    /**
     * Given a conversation and an answer, it returns the avatar image according its mood rating
     */
    getAvatarByAnswer(conversation: Conversation, answer: ConversationItem): string {
        if (answer.rating === '+') {
            this.rating++;
        } else if (answer.rating === '-') {
            this.rating--;
        }

        if (this.rating > 5) {
            return conversation.image1;
        } else if (this.rating > 1) {
            return conversation.image2;
        } else if (this.rating > -2) {
            return conversation.image3;
        } else if (this.rating > -5) {
            return conversation.image4;
        } else {
            return conversation.image5;
        }
    }
}
