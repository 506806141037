<div class="registration-page">
    <div class="flex-container">
        <div class="flex-item">

            <div class="registration-form-container">
                <img class="logo" src="../../../../../assets/logos/mi-tool.png" alt="MI Tool"/>
                <div class="title mb-3">Neues Nutzendenkonto erstellen</div>
                <form [formGroup]="registrationForm" (ngSubmit)=register()>
                    <div class="mb-2" id="email-container">
                        <input type="email" class="login-input" id="email-id" formControlName="email" placeholder="E-Mail">
                    </div>
                    <div class="mb-2" id="password-container">
                        <input type="password" class="login-input" id="password-id" formControlName="password" placeholder="Passwort">
                    </div>
                    <div class="mb-3" id="confirm-password-container">
                        <input type="password" class="login-input" id="confirm-password-id" formControlName="confirmPassword" placeholder="Passwort wiederholen">
                    </div>
                    <div class="mb-3" id="signup-code-container">
                        <input class="login-input" id="signup-code-id" formControlName="signupCode" placeholder="Registrierungscode">
                    </div>
                    <button [disabled]="registrationForm.controls['email'].errors?.required ||
                                        registrationForm.controls['password'].errors?.required ||
                                        registrationForm.controls['confirmPassword'].errors?.required ||
                                        registrationForm.controls['signupCode'].errors?.required ||
                                        loading"
                            id="submitLogin" type="submit" class="btn mb-4 login-button"><span>Registrieren</span></button>
                    <div class="terms">
                        <input type="checkbox" formControlName="acceptTerms"> <span>Ich habe die <a target="_blank" routerLink="/datenschutzbestimmungen">Datenschutzbestimmungen</a> gelesen und akzeptiert</span>
                    </div>
                </form>
                <div class="auth-links">
                    <a routerLink="/auth/login">Sie haben bereits ein Nutzerkonto?</a>
                </div>
                <app-logos-section></app-logos-section>
                <div class="logos mt-3">
                    <a href="https://kauffeld-lorenzo.de/" target="_blank"><img id="KL-logo" src="../../../../assets/logos/KL-logo.png"></a>
                    <a href="https://www.tu-braunschweig.de/" target="_blank"><img id="Braunschweig-logo" src="../../../../assets/logos/TU_Braunschweig_transparent_logo.png"></a>
                </div>
                <div class="links pt-5">
                    <a id="privacy" target="_blank" routerLink="/impressum">Impressum</a>
                    <a id="data-protection" target="_blank" routerLink="/datenschutz">Datenschutz</a>
                </div>
            </div>


        </div>
    </div>
    <!-- <app-login-footer></app-login-footer> -->
</div>
