import { CodeDto } from '../dto/codeDto';

export class Code {
    id: string;
    activationCode: string;
    expirationDate: number;
    trainerId: string;
    userId: string;

    constructor(id: string, data: CodeDto) {
        this.id = id;
        this.activationCode = data.activationCode;
        this.expirationDate = data.expirationDate;
        this.trainerId = data.trainerId;
        this.userId = data.userId;
    }
}
