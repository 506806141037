import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminLayoutComponent} from '../shared/layout/admin-layout/admin-layout.component';
import {AdminRoutingModule} from './admin-routing.module';
import { ConversationsComponent } from './conversations/conversations.component';
import { ConversationsListTableComponent } from './conversations/conversations-list-table/conversations-list-table.component';
import {SharedModule} from '../shared/shared.module';
import {NewConversationDialogComponent} from './dialog-new-conversation/new-conversation-dialog.component';
import { ConversationNewFormComponent } from './conversation-new-form/conversation-new-form.component';
import {DeleteConversationDialogComponent} from './delete-conversation/delete-conversation-dialog.component';
import {EditConversationDialogComponent} from './dialog-edit-conversation/edit-conversation-dialog.component';
import {ConversationEditFormComponent} from './conversation-edit-form/conversation-edit-form.component';
import { AdminManagementViewComponent } from './admin-management-view/admin-management-view.component';
import { CodeListTableComponent } from './admin-management-view/code-list-table/code-list-table.component';
import { CreateCodeDialogComponent } from './admin-management-view/codes/create-code-dialog/create-code-dialog.component';
import { EditCodeDialogComponent } from './admin-management-view/codes/edit-code-dialog/edit-code-dialog.component';
import { CodeManagementFormComponent } from './admin-management-view/codes/code-management-form/code-management-form.component';
import { CodeListItemComponent } from './admin-management-view/codes/code-management-form/code-list-item/code-list-item.component';
import { ConversationListItemComponent } from './admin-management-view/codes/code-management-form/conversation-list-item/conversation-list-item.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    ConversationsComponent,
    ConversationsListTableComponent,
    NewConversationDialogComponent,
    ConversationNewFormComponent,
    ConversationEditFormComponent,
    DeleteConversationDialogComponent,
    EditConversationDialogComponent,
    AdminManagementViewComponent,
    CodeListTableComponent,
    CreateCodeDialogComponent,
    EditCodeDialogComponent,
    CodeManagementFormComponent,
    CodeListItemComponent,
    ConversationListItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdminRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule { }
