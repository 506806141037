import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {LoadingService} from '../../../shared/services/loading.service';

interface Dimensions {
  height: number;
  width: number;
}

const PDF_PAPER_FORMAT = 'a4';
const PDF_UNIT = 'mm';
const PDF_ORIENTATION = 'p';
const PDF_PAPER_HEIGHT = 297;
const PDF_PAPER_WIDTH = 210;
const PDF_PAPER_MARGIN = 5;

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  pdf: jsPDF;

  constructor(
      private loadingService: LoadingService
  ) { }

  async exportPdf(fileName: string): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
          this.loadingService.showLoading();
          document.body.classList.add('print');
          this.pdf = new jsPDF(PDF_ORIENTATION, PDF_UNIT, PDF_PAPER_FORMAT);

          // Wait sometime to the tab change
          setTimeout(async () => {
              try {
                  await this.exportTab('miKonsistenTab');
                  this.pdf.addPage();
                  await this.exportTab('miInkonsistenTab');
                  this.pdf.addPage();
                  await this.exportTab('gesprachsphasenTab');
                  this.pdf.addPage();
                  await this.exportTab('vergleichseiteTab');
                  this.pdf.addPage();
                  await this.exportTab('veranderungsbereitschaftTab');
                  this.pdf.addPage();
                  await this.exportTab('totalTab');
                  await this.savePdf(fileName);
              } catch (e) {
                  resolve(false);
              } finally {
                  document.body.classList.remove('print');
                  this.loadingService.hideLoading();
              }

              resolve(true);
          }, 1000);
      });
  }

  private exportTab(elementId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const data = document.getElementById(elementId);

      html2canvas(data)
          .then(canvas => {
            const {height, width} = this.getDimensions(canvas);

            const contentDataURL = canvas.toDataURL('image/png');
            // @ts-ignore
            this.pdf.addImage(contentDataURL, 'PNG', PDF_PAPER_MARGIN, PDF_PAPER_MARGIN, width, height, undefined, 'FAST');
            return resolve();
          })
          .catch((e) => {
            return reject(e);
          });
    });
  }

  private getDimensions(canvas): Dimensions {
    let imgWidth = PDF_PAPER_WIDTH - PDF_PAPER_MARGIN * 2;
    let imgHeight = canvas.height * imgWidth / canvas.width;

    if (imgHeight > PDF_PAPER_HEIGHT - PDF_PAPER_MARGIN * 2) {
      imgHeight = PDF_PAPER_HEIGHT - PDF_PAPER_MARGIN * 2;
      imgWidth = canvas.width * imgHeight / canvas.height;
    }

    return {height: imgHeight, width: imgWidth};
  }

  private async savePdf(fileName: string): Promise<void> {
    await this.pdf.save(fileName + '.pdf', { returnPromise: true });
  }
}
