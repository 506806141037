import { ConfigService } from './../../../shared/services/config.service';
import { Component, NgZone, OnInit } from '@angular/core';
import {MessageDialogService} from '../../../shared/services/message-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS, WELCOME_MESSAGE_KEY} from '../../../shared/values';
import {AuthService} from '../../../core/services/auth.service';
import { Router } from '@angular/router';

const CODE_EXPIRED = 'code expired';

@Component({
  selector: 'app-chat-layout',
  templateUrl: './chat-layout.component.html',
  styleUrls: ['./chat-layout.component.scss']
})
export class ChatLayoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private messageDialogService: MessageDialogService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private router: Router,
    private ngZone: NgZone
  ) {
    translateService.addLangs(LANGS);
    translateService.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.welcomeMessage();
  }

  async welcomeMessage(): Promise<void> {
    const user = await this.authService.getCurrentUser();
    const codeStatus = await this.configService.checkUserSignupCode(user.uid);
    if(codeStatus === CODE_EXPIRED || codeStatus === ''){
      this.ngZone.run(() => {
        this.router.navigate(['/user/expiredsession']);
      });
    }else{
      const showWelcomeMessage = JSON.parse(localStorage.getItem(WELCOME_MESSAGE_KEY)) || {};

    if (!(user.uid in showWelcomeMessage)) {
      const message = await this.translateService.get('COMMON.welcomeText').toPromise();
      const closeText = await this.translateService.get('COMMON.closeButton').toPromise();

      this.messageDialogService.open({
        message,
        closeText,
      });
      this.messageDialogService.confirmed().subscribe(value => {
        showWelcomeMessage[user.uid] = 'true';
        localStorage.setItem(WELCOME_MESSAGE_KEY, JSON.stringify(showWelcomeMessage));
      });
    }
    }
  }

}
