import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ActionComponent} from './components/action/action.component';

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegistrationComponent},
    {path: 'forgot-password', component: ForgotPasswordComponent},
    {path: 'action', component: ActionComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule {
}
