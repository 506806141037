import {Injectable} from '@angular/core';
import {ConversationItem} from '../models/conversationItem';
import {ConversationItemAdapter} from './conversationItemAdapter';

@Injectable({
    providedIn: 'root',
})
export class ConversationItemResultsAdapter extends ConversationItemAdapter{
    adapt(item: any): ConversationItem {
        return this.parse(item);
    }
}
