import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import { Code } from '../models/code';

@Injectable({
    providedIn: 'root',
})
export class CodeAdapter implements Adapter<Code> {
    adapt(item: any): Code {
        return new Code(
            item.payload.doc ? item.payload.doc.id : item.payload.id,
            item.payload.doc ? item.payload.doc.data() : item.payload.data()
        );
    }
}