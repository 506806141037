<div class="avatar-info-area">
    <div id="contact-area">
        <div>
            <span id="contactName">{{ conversation.name }}</span>
        </div>
        <div>
            <span id="contactPosition">{{ conversation.topic }}</span>
        </div>
    </div>
    <div class="telephoneArea">
        <button mat-icon-button class="cancelButton" (click)="cancelConversation()"><fa-icon [icon]="faPhoneAlt"></fa-icon></button>
        <button *ngIf="showSpeechMute" mat-fab class="muteButton"  (click)="toggleMute()"><fa-icon [icon]="faVolume"></fa-icon></button>
    </div>
</div>
