import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map, tap} from 'rxjs/operators';
import {ConversationAdapter} from '../adapter/conversationAdapter';
import {Observable, combineLatest} from 'rxjs';
import {Conversation} from '../models/conversation';
import {ConversationDto} from '../dto/conversationDto';

const COLLECTION_CONVERSATIONS = 'conversations';

@Injectable({
    providedIn: 'root'
})
export class ConversationRepositoryService {

    constructor(
        private firestore: AngularFirestore,
        private converstationAdapter: ConversationAdapter
    ) {
    }

    /**
     * Get all conversations ordered by name
     */
    public getConversations(): Observable<Conversation[]> {
        return this.firestore.collection(COLLECTION_CONVERSATIONS, ref => ref.orderBy('name')).snapshotChanges()
            .pipe(
                map((data: any[]) => data.map((item) => this.converstationAdapter.adapt(item)))
            )
            .pipe(
                // Sorting in Firestore is case sensitive. There is no flag to make the sorting ignore the case
                tap(results => {
                    results.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                })
            );
    }

    public getConversationById(conversationId: string): Observable<Conversation> {
        return this.firestore.collection(COLLECTION_CONVERSATIONS).doc(conversationId)
            .snapshotChanges()
            .pipe(
                map((data) => this.converstationAdapter.adapt(data))
            );
    }

    /**
     * Create a new ID if we want to set the id in a document manually
     */
    public getNewId(): string {
        return this.firestore.createId();
        return '';
    }

    /**
     * Create a new conversation.
     * The parameter id is optional and it's used if we can set the id manually
     */
    public createConversation(data: ConversationDto, id?: string): void {
        data.updateDate = new Date().getTime();
        if (id) {
            this.firestore.collection(COLLECTION_CONVERSATIONS).doc(id).set(data);
        } else {
            this.firestore.collection(COLLECTION_CONVERSATIONS).add(data);
        }
    }

    /**
     * Edit a conversation with a given id
     */
    public editConversation(data: ConversationDto, id: string): void {
        data.updateDate = new Date().getTime();
        this.firestore.collection(COLLECTION_CONVERSATIONS).doc(id).set(data);
    }

    /**
     * Delete a conversation with a given id
     */
    public deleteConversation(id: string) {
        return this.firestore.collection(COLLECTION_CONVERSATIONS).doc(id).delete();
    }

}
