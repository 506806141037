import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EChartOption} from 'echarts';
import {min} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG, LANGS} from '../../../../shared/values';

const COLOR = '#104267';
const COLOR_INVERSE = '#d8d8d8';

interface Intervals {
  min: number;
  max: number;
}

@Component({
  selector: 'app-rindex-chart',
  templateUrl: './rindex-chart.component.html',
  styleUrls: ['./rindex-chart.component.scss']
})
export class RindexChartComponent implements OnInit, OnChanges {
  @Input() data: number[];
  @Input() inverseColors: boolean = false;
  chartInstance: any;
  chartOptions: EChartOption;

  constructor(
      private translateService: TranslateService
  ) {
    translateService.addLangs(LANGS);
    translateService.setDefaultLang(DEFAULT_LANG);
  }

  ngOnInit(): void {
    this.loadChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chartInstance) {
      const intervals = this.calculateIntervals();
      this.chartInstance.setOption({
        xAxis: {
          data: this.data.map(d => '')
        },
        yAxis: {
          minInterval: intervals.min,
          maxInterval: intervals.max,
        },
        series: [{
          data: this.data
        }]
      });
    }
  }

  onChartInit(event: any) {
    this.chartInstance = event;
  }

  private calculateIntervals(): Intervals {
    const interval = {min: 0.5, max: 0.5} as Intervals;

    const minValue = Math.min(...this.data);
    const maxValue = Math.max(...this.data);

    interval.max = Math.round(Math.abs(maxValue - minValue) * 10 / 50 );

    if (interval.max < 0.5) {
      interval.max = 0.5;
    }

    return interval;
  }

  async loadChart(): Promise<void> {
    const intervals = this.calculateIntervals();
    this.translateService.get('CHART').subscribe(chartTranslation => {
      this.chartOptions = {
        grid: {
          left: 60,
          top: 10,
          right: 50,
          bottom: 10
        },
        xAxis: {
          name: chartTranslation.xAxisLabel,
          type: 'category',
          data: this.data.map(d => ''),
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: this.inverseColors ? COLOR_INVERSE : COLOR,
              width: 1,
              type: 'dashed'
            }
          }
        },
        yAxis: {
          name: chartTranslation.yAxisLabel,
          nameLocation: 'center',
          nameGap: 30,
          nameTextStyle: {
            fontSize: 11
          },
          type: 'value',
          minInterval: intervals.min,
          maxInterval: intervals.max,
          min: (value) =>  {
            return value.min - intervals.max;
          },
          max: (value) =>  {
            return value.max + intervals.max;
          },
          axisLine: {
            lineStyle: {
              color: this.inverseColors ? COLOR_INVERSE : COLOR,
              width: 2
            }
          },
          axisLabel: {
            fontSize: this.inverseColors ? 12 : 18
          },
          splitLine: {
            show: false
          },
        },
        series: [{
          name: 'R-Index',
          data: this.data,
          type: 'line',
          lineStyle: {
            width: 6,
            color: this.inverseColors ? COLOR_INVERSE : COLOR
          },
          itemStyle: {
            color: this.inverseColors ? COLOR_INVERSE : COLOR
          }
        }]
      };
    });

  }
}
